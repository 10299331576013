'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = CompareVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../../components/Global');

var _StyledIcon = require('../../../components/StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _emissions = require('../../../helpers/emissions');

var _vehicle = require('../../../helpers/vehicle');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _Shared = require('./Shared');

var _ComparedVehicleType = require('./../../../types/ComparedVehicleType');

var _EquipmentMetaDataRow = require('./EquipmentMetaDataRow');

var _EquipmentMetaDataRow2 = _interopRequireDefault(_EquipmentMetaDataRow);

var _MoreInfoRow = require('./MoreInfoRow');

var _MoreInfoRow2 = _interopRequireDefault(_MoreInfoRow);

var _OptionalExtrasRow = require('./OptionalExtrasRow');

var _OptionalExtrasRow2 = _interopRequireDefault(_OptionalExtrasRow);

var _PricingRow = require('./PricingRow');

var _PricingRow2 = _interopRequireDefault(_PricingRow);

var _RetailerPhoneRow = require('./RetailerPhoneRow');

var _RetailerPhoneRow2 = _interopRequireDefault(_RetailerPhoneRow);

var _ShortlistRow = require('./ShortlistRow');

var _ShortlistRow2 = _interopRequireDefault(_ShortlistRow);

var _CompareTypes = require('./CompareTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.table.withConfig({
  displayName: 'CompareVehicles__Container',
  componentId: 'sc-1xblsi3-0'
})(['width:95%;height:auto;margin:0 2.5% 5% 2.5%;border-collapse:separate;border-spacing:10px 0;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__ImageContainer',
  componentId: 'sc-1xblsi3-1'
})(['cursor:pointer;position:relative;']);

var StyledImage = _styledComponents2.default.img.withConfig({
  displayName: 'CompareVehicles__StyledImage',
  componentId: 'sc-1xblsi3-2'
})(['width:100%;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__ImageOverlay',
  componentId: 'sc-1xblsi3-3'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldHeading',
  componentId: 'sc-1xblsi3-4'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldBody',
  componentId: 'sc-1xblsi3-5'
})(['font-size:14px;max-width:165px;']);

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__EmptyContainer',
  componentId: 'sc-1xblsi3-6'
})(['display:flex;flex-direction:column;align-items:center;padding:72px 0 280px 0;justify-content:center;']);

function CompareVehicles(props) {
  var requestInformation = props.requestInformation,
      optionalExtras = props.optionalExtras,
      toggleOptionalExtras = props.toggleOptionalExtras,
      _props$comparedVehicl = props.comparedVehicles,
      comparedVehicles = _props$comparedVehicl === undefined ? [] : _props$comparedVehicl,
      removeVehicle = props.removeVehicle,
      shortlistVehicle = props.shortlistVehicle,
      isShortListed = props.isShortListed,
      equipmentMetaData = props.equipmentMetaData,
      globalStyling = props.globalStyling,
      gridConfiguration = props.gridConfiguration,
      currentLanguage = props.currentLanguage,
      onMoreInfoClick = props.onMoreInfoClick,
      ctaToVdp = props.ctaToVdp,
      _props$config = props.config,
      headerFont = _props$config.headerFont,
      paragraphFont = _props$config.paragraphFont,
      subHeaderFont = _props$config.subHeaderFont,
      buttonSize = _props$config.buttonSize,
      infoFont = _props$config.infoFont,
      phoneIconColour = _props$config.phoneIconColour,
      translations = _props$config.translations,
      optionalIconFont = _props$config.optionalIconFont,
      optionalIconColourFont = _props$config.optionalIconColourFont,
      optionalHeadingThreeFont = _props$config.optionalHeadingThreeFont,
      optionalExtrasBackgroundColour = _props$config.optionalExtrasBackgroundColour,
      compareTableDataBackgroundColour = _props$config.compareTableDataBackgroundColour,
      handleZeroPriceAsText = _props$config.handleZeroPriceAsText,
      showOptionalExtras = _props$config.showOptionalExtras,
      convertedKwToHp = _props$config.convertedKwToHp,
      specialOfferStyling = _props$config.specialOfferStyling,
      showSectionSalesTaxDisclaimer = _props$config.showSectionSalesTaxDisclaimer,
      hideContactButton = _props$config.hideContactButton;


  var buttonStyle = globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle;

  var getVehicleName = function getVehicleName(vehicle) {
    return vehicle.name;
  };

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector.emissionsEnabled;

  var displayEmissionsEfficiency = function displayEmissionsEfficiency(vehicle) {
    if (vehicle && vehicle.emissions && (vehicle.emissions.nedcConsumptionCombined || vehicle.emissions.wltpConsumptionCombined || vehicle.emissions.nedcEmissionsCombined || vehicle.emissions.wltpEmissionsCombined)) return vehicle.energyEfficiencyRating;
    return '-';
  };

  var _useMemo = (0, _react.useMemo)(function () {
    return [comparedVehicles.some(function (v) {
      return v.netPrice;
    })];
  }, [comparedVehicles]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 1),
      hasNetPrice = _useMemo2[0];

  return comparedVehicles.length > 0 ? _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      'tbody',
      null,
      _react2.default.createElement(
        _Shared.TableRow,
        { fontSize: subHeaderFont && subHeaderFont.fontSize },
        _react2.default.createElement('td', null),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              'div',
              { style: { display: 'flex', justifyContent: 'flex-end' } },
              _react2.default.createElement(
                _Shared.IconButton,
                {
                  onClick: function onClick() {
                    return removeVehicle(vehicle);
                  },
                  'aria-label': translations.removeVehicleAriaLabel
                },
                _react2.default.createElement('img', {
                  src: 'https://res.cloudinary.com/motortrak/q_auto/locator/rolls-royce/global/icons/close-dark.svg',
                  alt: translations.close,
                  style: { marginBottom: '10px' }
                })
              )
            )
          );
        })
      ),
      _react2.default.createElement(
        _Shared.TableRow,
        { fontSize: subHeaderFont && subHeaderFont.fontSize },
        _react2.default.createElement(
          'td',
          {
            style: {
              width: '25%',
              background: '' + (compareTableDataBackgroundColour ? '' + compareTableDataBackgroundColour.value : 'transparent')
            }
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n                ' + (0, _Global.fontStyleOverride)(subHeaderFont) + '\n                line-height: 28px;\n                font-family: RivieraNights-Regular;\n                padding-left: 8px;\n                letter-spacing: 0.5px;\n              ';
              }
            },
            translations.compareVehiclesSubHeader
          )
        ),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              ImageContainer,
              {
                onClick: function onClick() {
                  return vehicle.status !== 'sold' && onMoreInfoClick(vehicle);
                }
              },
              vehicle.images.length > 0 ? _react2.default.createElement(StyledImage, {
                src: vehicle.images[0],
                width: '100%',
                alt: vehicle.name
              }) : _react2.default.createElement(StyledImage, {
                src: 'https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png',
                alt: 'placeholderImage'
              }),
              vehicle.status === 'sold' || vehicle.priceSpecial === 'SOLD' && translations.sold && _react2.default.createElement(
                ImageOverlay,
                null,
                _react2.default.createElement(
                  SoldHeading,
                  null,
                  translations.sold
                ),
                _react2.default.createElement(
                  SoldBody,
                  null,
                  translations.similarVehiclesAvailable
                )
              )
            )
          );
        })
      ),
      _react2.default.createElement(_PricingRow2.default, {
        comparedVehicles: comparedVehicles,
        translations: translations,
        infoFont: infoFont,
        currentLanguage: currentLanguage,
        handleZeroPriceAsText: handleZeroPriceAsText,
        hasNetPrice: hasNetPrice,
        showSectionSalesTaxDisclaimer: showSectionSalesTaxDisclaimer,
        specialOfferStyling: specialOfferStyling,
        globalStyling: globalStyling
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesModel,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return getVehicleName(vehicle);
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesExterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['exterior'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesInterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['interior'],
        font: infoFont
      }),
      translations.compareVehiclesVeneer && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesVeneer,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return _react2.default.createElement(
            'div',
            { style: { lineHeight: '20px', margin: '10px 0' } },
            vehicle.veneer
          );
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesOdometer,
        comparedVehicles: comparedVehicles,
        keyValues: ['odometer', 'display'],
        visible: gridConfiguration && gridConfiguration.showOdometer,
        font: infoFont,
        zeroMileageText: translations.zeroMileageText
      }),
      translations.compareVehiclesTransmission && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesTransmission,
        comparedVehicles: comparedVehicles,
        keyValues: ['transmission'],
        font: infoFont
      }),
      translations.compareVehiclesEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEmissions,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'emissions'],
        font: infoFont
      }),
      translations.compareVehiclesFuelConsumption && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesFuelConsumption,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'fuelConsumption'],
        visible: gridConfiguration && gridConfiguration.showFuelConsumption,
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesBodystyle,
        comparedVehicles: comparedVehicles,
        keyValues: ['bodystyle'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareHandDrive,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return vehicle.handDrive === 'L' ? translations.compareLhd : translations.compareRhd;
        },
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEngine,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineCapacity ? (0, _numbers.localiseNumber)(vehicle.engineCapacity, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineCapacity && translations.ccUnit ? translations.ccUnit : '');
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesFuelType,
        comparedVehicles: comparedVehicles,
        keyValues: ['fuel'],
        font: infoFont
      }),
      translations.co2EmissionsCombined && _react2.default.createElement(_Shared.DataRow, {
        label: translations.co2EmissionsCombined,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.co2EmissionsCombined ? (0, _numbers.localiseNumber)(vehicle.engineDetails.co2EmissionsCombined, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.co2EmissionsCombined && translations.co2Unit ? translations.co2Unit : '');
        }
      }),
      translations.combinedConsumption && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.combinedConsumption,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.combinedConsumption ? (0, _numbers.localiseNumber)(vehicle.engineDetails.combinedConsumption, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.combinedConsumption && translations.combinedConsumptionUnit ? translations.combinedConsumptionUnit : '');
        }
      }),
      translations.consumptionUrban && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.consumptionUrban,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.consumptionUrban ? (0, _numbers.localiseNumber)(vehicle.engineDetails.consumptionUrban, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.consumptionUrban && translations.consumptionUrbanUnit ? translations.consumptionUrbanUnit : '');
        }
      }),
      translations.consumptionExtraUrban && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.consumptionExtraUrban,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.consumptionExtraurban ? (0, _numbers.localiseNumber)(vehicle.engineDetails.consumptionExtraurban, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.consumptionExtraurban && translations.consumptionExtraUrbanUnit ? translations.consumptionExtraUrbanUnit : '');
        }
      }),
      translations.powerKw && _react2.default.createElement(_Shared.DataRow, {
        label: translations.powerKw,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return convertedKwToHp && vehicle.engineDetails.powerKw ? (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
            KW: vehicle.powerKwNumeric,
            HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicle, vehicle.locale)
          }, translations) : (vehicle.engineDetails.powerKw ? vehicle.engineDetails.powerKw : '-') + ' ' + (vehicle.engineDetails.powerKw && translations.powerUnit ? translations.powerUnit : '');
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesRegistrationYear,
        comparedVehicles: comparedVehicles,
        keyValues: ['registration'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesModelYear,
        comparedVehicles: comparedVehicles,
        keyValues: ['modelYear'],
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesCombinedCO2Emissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesCombinedCO2Emissions,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return (0, _emissions.formatCombinedValues)(vehicle.emissions.nedcEmissionsCombined, vehicle.emissions.wltpEmissionsCombined) || '-';
        },
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesCombinedConsumption && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesCombinedConsumption,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return (0, _emissions.formatCombinedValues)(vehicle.consumption.nedcConsumptionCombined, vehicle.consumption.wltpConsumptionCombined) || '-';
        },
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesEnergyEfficiency && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEnergyEfficiency,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return displayEmissionsEfficiency(vehicle);
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesLocation,
        comparedVehicles: comparedVehicles,
        keyValues: ['location'],
        font: infoFont
      }),
      showOptionalExtras && _react2.default.createElement(
        _react.Fragment,
        null,
        equipmentMetaData && equipmentMetaData.length && _react2.default.createElement(_OptionalExtrasRow2.default, {
          label: translations.compareVehiclesOptionalExtras,
          comparedVehicles: comparedVehicles,
          optionalExtras: optionalExtras,
          toggleOptionalExtras: toggleOptionalExtras,
          'aria-expanded': optionalExtras,
          font: infoFont,
          optionalIconColourFont: optionalIconColourFont,
          optionalExtrasBackgroundColour: optionalExtrasBackgroundColour,
          equipmentMetaData: equipmentMetaData,
          translations: translations
        }),
        optionalExtras && equipmentMetaData.map(function (equipment, idx) {
          return _react2.default.createElement(_EquipmentMetaDataRow2.default, {
            key: '' + equipment,
            label: equipment,
            comparedVehicles: comparedVehicles,
            keyValues: ['performance', 'fuelConsumption'],
            visible: gridConfiguration && gridConfiguration.showFuelConsumption,
            borderBottom: idx === equipmentMetaData.length - 1,
            font: infoFont,
            globalStyling: globalStyling,
            iconColour: typeof phoneIconColour === 'string' ? phoneIconColour : phoneIconColour.value,
            optionalIconColourFont: optionalIconColourFont
          });
        })
      ),
      _react2.default.createElement(_RetailerPhoneRow2.default, {
        comparedVehicles: comparedVehicles,
        globalStyling: globalStyling,
        phoneIconColour: typeof phoneIconColour === 'string' ? phoneIconColour : phoneIconColour.value,
        font: infoFont,
        optionalIconFont: optionalIconFont,
        optionalIconColourFont: optionalIconColourFont
      }),
      !hideContactButton && _react2.default.createElement(_MoreInfoRow2.default, {
        requestInformation: requestInformation,
        comparedVehicles: comparedVehicles,
        buttonLabel: ctaToVdp ? translations.viewMoreButtonText : translations.compareVehiclesRequestInfo,
        buttonStyle: buttonStyle,
        buttonSize: buttonSize,
        font: infoFont,
        ctaToVdp: ctaToVdp,
        onMoreInfoClick: onMoreInfoClick
      }),
      _react2.default.createElement(_ShortlistRow2.default, {
        comparedVehicles: comparedVehicles,
        shortlistVehicle: shortlistVehicle,
        isShortListed: isShortListed,
        translations: translations,
        font: infoFont,
        optionalIconColourFont: optionalIconColourFont,
        optionalHeadingThreeFont: optionalHeadingThreeFont
      })
    )
  ) : _react2.default.createElement(
    'div',
    { style: { margin: '16px 16px 0' } },
    translations.compareVehiclesHeader && _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: ' + (headerFont ? headerFont.fontSize + 'px' : '26px') + ';\n        text-transform: ' + (headerFont ? '' + headerFont.textTransform : 'uppercase;') + '\n        text-align: ' + (headerFont ? '' + headerFont.align : 'center;') + ';\n        flex: 1 0 0%;\n        letter-spacing: ' + (headerFont ? headerFont.kerning + 'px' : '3px;') + ';\n      ';
        }
      },
      translations.compareVehiclesHeader
    ),
    props.config.emptyIcon && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        EmptyContainer,
        null,
        _react2.default.createElement(_StyledIcon2.default, { link: props.config.emptyIcon, width: 100, height: 100 }),
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                margin-top: 40px;\n                font-size: 18px;\n                text-align: center;\n                ' + (0, _Global.fontStyleOverride)(paragraphFont) + '\n              ';
            }
          },
          translations.compareVehiclesSubHeader
        )
      )
    )
  );
}