'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FinanceForm = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _globalDrawers = require('../../../shared/globalDrawers');

var _lamborghiniFinanceCalc = require('../../../shared/lamborghiniFinanceCalc');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _CurrencyInput = require('./CurrencyInput');

var _CurrencyInput2 = _interopRequireDefault(_CurrencyInput);

var _FinanceDrawer = require('./FinanceDrawer');

var _FinanceDrawerStyles = require('./FinanceDrawerStyles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FinanceForm = exports.FinanceForm = function FinanceForm(_ref) {
  var step = _ref.step,
      translations = _ref.translations,
      dealerCountry = _ref.dealerCountry,
      dispatch = _ref.dispatch,
      selectedProvince = _ref.selectedProvince,
      setSelectedProvince = _ref.setSelectedProvince,
      config = _ref.config,
      setSelectedTerm = _ref.setSelectedTerm,
      selectedTerm = _ref.selectedTerm,
      termValues = _ref.termValues,
      downPayment = _ref.downPayment,
      incrementDownPayment = _ref.incrementDownPayment,
      decrementDownPayment = _ref.decrementDownPayment,
      onDownPaymentChange = _ref.onDownPaymentChange,
      currentVehicle = _ref.currentVehicle,
      financeData = _ref.financeData,
      paymentData = _ref.paymentData,
      ficoScore = _ref.ficoScore,
      setFicoScore = _ref.setFicoScore,
      error = _ref.error,
      summaryRef = _ref.summaryRef;
  return _react2.default.createElement(
    _react2.default.Fragment,
    null,
    _react2.default.createElement(
      _FinanceDrawerStyles.Disclaimer,
      null,
      translations['topDisclaimer' + dealerCountry]
    ),
    _react2.default.createElement(
      _FinanceDrawerStyles.StepOne,
      null,
      _react2.default.createElement(
        _FinanceDrawerStyles.TitleBar,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.Title,
          null,
          translations.retailFinance
        ),
        _react2.default.createElement(_FinanceDrawer.IconButtonStyle, {
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1555597639/locator/lamborghini/content/x.svg',
          onClick: function onClick() {
            dispatch(_globalDrawers.actions.toggleFinanceDrawer());
          }
        })
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.Header,
        null,
        translations.estimatedMonthlyPayment
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.SubHeader,
        { style: { marginBottom: '50px' } },
        translations.estimateDisclaimer
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.SubHeader,
        null,
        translations.stepOne
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.HeaderTwo,
        { style: { marginBottom: '24.5px' } },
        translations['createYourEstimate' + dealerCountry]
      ),
      dealerCountry === 'CA' && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.Label,
          null,
          translations.selectYourRegion
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.Select,
          {
            id: 'provinceSelect',
            value: selectedProvince,
            onChange: function onChange(event) {
              setSelectedProvince(event.target.value);
            }
          },
          config.provinces.map(function (province) {
            return _react2.default.createElement(
              'option',
              { key: province, value: province },
              translations[province]
            );
          })
        )
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.Label,
        null,
        translations.term
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.Select,
        {
          onChange: function onChange(event) {
            return setSelectedTerm(Number(event.target.value));
          },
          value: selectedTerm
        },
        termValues.map(function (term) {
          return _react2.default.createElement(
            'option',
            { key: term, value: term },
            (0, _translateFromTemplate.translateFromTemplate)('months', {
              NUMBER: term
            }, translations)
          );
        })
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.Label,
        null,
        translations.downPayment
      ),
      _react2.default.createElement(
        _FinanceDrawerStyles.PaymentRow,
        null,
        _react2.default.createElement(_FinanceDrawerStyles.PaymentButtons, {
          role: 'button',
          onClick: decrementDownPayment,
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1659103381/locator/aston-martin/icons/accordion-minus.svg'
        }),
        _react2.default.createElement(_CurrencyInput2.default, {
          step: step,
          value: downPayment,
          onChange: onDownPaymentChange,
          currency: currentVehicle.price.currency,
          locale: currentVehicle.locale
        }),
        _react2.default.createElement(_FinanceDrawerStyles.PaymentButtons, {
          role: 'button',
          onClick: incrementDownPayment,
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1707393983/locator/kia/global/icons/New%20svg%20icons%202021/icon_plus_black.svg'
        })
      ),
      dealerCountry === 'US' && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.Label,
          null,
          translations.ficoScore
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.RadioContainer,
          null,
          config.financeScores.map(function (score) {
            return _react2.default.createElement(
              _FinanceDrawerStyles.ButtonContainer,
              {
                key: score,
                active: ficoScore === score,
                onClick: function onClick() {
                  return setFicoScore(score);
                }
              },
              _react2.default.createElement(_FinanceDrawerStyles.RadioInput, {
                type: 'radio',
                id: 'option-' + score,
                name: 'options',
                value: score,
                checked: ficoScore === score,
                onChange: function onChange() {
                  return setFicoScore(score);
                }
              }),
              _react2.default.createElement(
                'label',
                {
                  htmlFor: 'option-' + score,
                  style: { cursor: 'pointer' }
                },
                translations[score.toLowerCase()]
              )
            );
          })
        )
      )
    ),
    _react2.default.createElement(
      _FinanceDrawerStyles.PaymentSummary,
      { ref: summaryRef },
      error && error.origin === _lamborghiniFinanceCalc.constants.LAMBO_GET_MONTHLY_PAYMENT ? _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.SpinnerContainer,
          null,
          _react2.default.createElement(
            'p',
            null,
            translations.financeError
          )
        )
      ) : _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(
          _FinanceDrawerStyles.SubHeader,
          null,
          translations.stepTwo
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.HeaderTwo,
          null,
          translations.summary
        ),
        _react2.default.createElement(
          _FinanceDrawerStyles.SubHeader,
          null,
          translations.summaryEstimate
        ),
        dealerCountry === 'US' && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.effectiveRate
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              paymentData.Rate,
              ' %'
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.term
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _translateFromTemplate.translateFromTemplate)('months', {
                NUMBER: paymentData.Term
              }, translations)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.downPayment
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.DownPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.totalAmountFinanced
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.TotalAmountFinanced, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.listPrice
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(currentVehicle.price.value, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.monthlyPayment
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.BaseMonthlyPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          )
        ),
        dealerCountry === 'CA' && _react2.default.createElement(
          _react2.default.Fragment,
          null,
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.totalRetailPrice
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(currentVehicle.price.value, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.ppsa
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.PPSAFee, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.downPayment
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.DownPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.totalAmountFinanced
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.TotalAmountFinanced, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.dueAtSigning
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.AmountDueAtSigning, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.term
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _translateFromTemplate.translateFromTemplate)('months', {
                NUMBER: paymentData.Term
              }, translations)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.annualPercentageRate
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              financeData.Rate,
              ' %'
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.totalRepaid
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.TotalAmountRepaid, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          ),
          _react2.default.createElement(
            _FinanceDrawerStyles.DataRow,
            null,
            _react2.default.createElement(
              _FinanceDrawerStyles.DataLabel,
              null,
              translations.monthlyPayment
            ),
            _react2.default.createElement(
              _FinanceDrawerStyles.DataValue,
              null,
              (0, _numbers.localiseCurrency)(paymentData.BaseMonthlyPayment, currentVehicle.locale, currentVehicle.price.currency, 0.01)
            )
          )
        ),
        _react2.default.createElement(
          _reactMarkdown2.default,
          null,
          paymentData.Disclaimer
        )
      )
    )
  );
};