'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['display:none;'], ['display:none;']);

exports.default = VehicleInfoBanner;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactAnchorLinkSmoothScrollV = require('react-anchor-link-smooth-scroll-v2');

var _reactAnchorLinkSmoothScrollV2 = _interopRequireDefault(_reactAnchorLinkSmoothScrollV);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _numbers = require('../../shared/localisation/numbers');

var _vehicleDetailsPageTypes = require('../../types/Bentley/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

var _CarfaxLogo = require('./CarfaxLogo');

var _CarfaxLogo2 = _interopRequireDefault(_CarfaxLogo);

var _Form = require('./Form');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Container',
  componentId: 'sc-1rx3itd-0'
})(['background:#ffffff;position:fixed;top:0;left:0;display:flex;height:130px;border-bottom:1px solid #94c11d;width:100%;z-index:95;', ';'], _theme2.default.max.large(_templateObject));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Column',
  componentId: 'sc-1rx3itd-1'
})(['display:flex;flex:1;flex-direction:column;height:100%;overflow:hidden;']);

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Row',
  componentId: 'sc-1rx3itd-2'
})(['display:flex;flex:1;align-items:center;padding:15px;justify-content:space-between;']);

var Image = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__Image',
  componentId: 'sc-1rx3itd-3'
})(['object-fit:contain;height:100%;width:214px;', ';'], _theme2.default.max.medium(_templateObject));

var Title = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Title',
  componentId: 'sc-1rx3itd-4'
})(['font-family:Bentley-Regular;font-size:24px;color:#222222;font-style:normal;font-weight:400;line-height:28px;margin:0;padding-left:2px;text-transform:uppercase;']);

var Description = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Description',
  componentId: 'sc-1rx3itd-5'
})(['font-family:Bentley-Light;font-style:normal;font-weight:300;font-size:16px;line-height:20px;letter-spacing:0.652983px;text-transform:uppercase;color:#222222;margin-bottom:5px;padding-left:3px;']);

var Location = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Location',
  componentId: 'sc-1rx3itd-6'
})(['font-family:Bentley-Regular;font-style:normal;font-weight:300;font-size:14px;line-height:22px;color:#222222;text-transform:uppercase;display:flex;align-items:center;letter-spacing:1.71429px;']);

var Telephone = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Telephone',
  componentId: 'sc-1rx3itd-7'
})(['font-family:Bentley-Light;font-style:normal;font-weight:300;font-size:14px;line-height:22px;color:#222222;text-transform:uppercase;display:flex;align-items:center;']);

var Price = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__Price',
  componentId: 'sc-1rx3itd-8'
})(['display:flex;justify-content:end;font-family:Bentley-SemiBold;font-style:normal;font-weight:400;font-size:24px;line-height:33px;color:#222222;']);

var PriceLessTaxes = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__PriceLessTaxes',
  componentId: 'sc-1rx3itd-9'
})(['display:flex;justify-content:end;font-family:\'Bentley-Regular\';font-style:normal;font-weight:400;color:#222222;font-size:12px;text-transform:none;']);

var CtaContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleInfoBanner__CtaContainer',
  componentId: 'sc-1rx3itd-10'
})(['display:flex;justify-content:end;align-items:center;']);

var ApprovedLogo = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleInfoBanner__ApprovedLogo',
  componentId: 'sc-1rx3itd-11'
})(['justify-content:center;margin-right:10px;height:40px;']);

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'VehicleInfoBanner__PhoneLink',
  componentId: 'sc-1rx3itd-12'
})(['padding-left:3px;display:flex;align-items:center;text-decoration:none;color:#222222;&:hover{text-decoration:none;}&:active{text-decoration:none;}']);

function VehicleInfoBanner(_ref) {
  var vehicleDetails = _ref.vehicleDetails,
      config = _ref.config,
      placeholdingImage = _ref.placeholdingImage,
      makeAnEnquiry = _ref.makeAnEnquiry;
  var modelYear = vehicleDetails.modelYear,
      description = vehicleDetails.description,
      location = vehicleDetails.location,
      specification = vehicleDetails.specification,
      retailerInformation = vehicleDetails.retailerInformation,
      price = vehicleDetails.price,
      locale = vehicleDetails.locale,
      images = vehicleDetails.images,
      priceLessTaxes = vehicleDetails.priceLessTaxes;
  var bodystyle = specification.bodystyle,
      fuelType = specification.fuelType,
      odometer = specification.odometer;
  var _retailerInformation$ = retailerInformation.name,
      name = _retailerInformation$ === undefined ? '' : _retailerInformation$,
      _retailerInformation$2 = retailerInformation.phone,
      phone = _retailerInformation$2 === undefined ? '' : _retailerInformation$2;

  var accessiblePhoneNumber = phone !== null ? phone.replace(/\s/g, '').replace('(', '').replace(')', '').split('').join(' ') : '';
  return _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(Image, {
      src: images.length > 0 ? images[0] : placeholdingImage,
      'data-cy': 'vehicle-banner-image',
      alt: vehicleDetails.name
    }),
    _react2.default.createElement(
      Row,
      null,
      _react2.default.createElement(
        Column,
        null,
        _react2.default.createElement(
          Title,
          null,
          description
        ),
        _react2.default.createElement(
          Description,
          null,
          !config.hideYear ? modelYear + ' | ' + bodystyle + ' | ' + fuelType : bodystyle + ' | ' + fuelType,
          config.showOdometerInformation && ' | ' + (0, _numbers.localiseNumber)(Number(odometer.reading), locale) + ' ' + odometer.units
        ),
        _react2.default.createElement(
          Location,
          null,
          _react2.default.createElement('img', { src: config.iconLocationPin, alt: 'location icon' }),
          _react2.default.createElement(
            'div',
            { style: { paddingLeft: '3px' } },
            name + ', ' + location
          )
        ),
        phone && _react2.default.createElement(
          Telephone,
          null,
          _react2.default.createElement('img', { src: config.iconPhone, alt: 'phone icon' }),
          _react2.default.createElement(
            PhoneLink,
            {
              href: 'tel:' + phone,
              'aria-label': 'Dealer phone number  ' + accessiblePhoneNumber
            },
            phone
          )
        )
      ),
      _react2.default.createElement(
        Column,
        { style: { justifyContent: 'space-between' } },
        !config.hidePrice && _react2.default.createElement(
          Price,
          null,
          (0, _numbers.localiseCurrency)(price.value, locale, price.currency, 0)
        ),
        vehicleDetails.priceLessTaxes !== 0 && !config.hidePrice && _react2.default.createElement(
          PriceLessTaxes,
          null,
          typeof priceLessTaxes === 'number' && (0, _numbers.localiseCurrency)(priceLessTaxes, locale, price.currency, 0),
          ' ',
          config.translations.excludeVAT
        ),
        _react2.default.createElement(
          CtaContainer,
          null,
          _react2.default.createElement(_CarfaxLogo2.default, { carfax: vehicleDetails.carfax }),
          vehicleDetails.approved && _react2.default.createElement(
            _reactAnchorLinkSmoothScrollV2.default,
            { style: { height: '40px' }, href: '#WhyCertified' },
            ' ',
            _react2.default.createElement(ApprovedLogo, {
              src: config.approvedLogo,
              alt: 'Why Certified by Bentley'
            }),
            ' '
          ),
          _react2.default.createElement(
            'div',
            {
              style: { display: 'flex', width: '250px', justifyContent: 'end' }
            },
            _react2.default.createElement(
              _Form.Button,
              {
                variant: 'primary',
                onClick: function onClick(e) {
                  return makeAnEnquiry(e);
                },
                styleOverride: function styleOverride() {
                  return 'width: 100%';
                }
              },
              config.translations.contact
            )
          )
        )
      )
    )
  );
}