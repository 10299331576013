'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Button = require('../../../components/RollsRoyce/Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _ComparedVehicleType = require('./../../../types/ComparedVehicleType');

var _Shared = require('./Shared');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var MoreInfoRow = function MoreInfoRow(_ref) {
  var requestInformation = _ref.requestInformation,
      comparedVehicles = _ref.comparedVehicles,
      buttonLabel = _ref.buttonLabel,
      buttonStyle = _ref.buttonStyle,
      font = _ref.font,
      buttonSize = _ref.buttonSize,
      onMoreInfoClick = _ref.onMoreInfoClick,
      ctaToVdp = _ref.ctaToVdp;
  return _react2.default.createElement(
    _Shared.TableRow,
    { fontSize: font && font.fontSize },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id, hideBottomBorder: true },
        _react2.default.createElement(
          'div',
          {
            style: {
              padding: '0px 20px',
              display: 'flex',
              justifyContent: 'center'
            }
          },
          _react2.default.createElement(_Button2.default, {
            onClick: function onClick() {
              return ctaToVdp ? onMoreInfoClick(vehicle) : requestInformation(vehicle);
            },
            text: buttonLabel,
            applyStyle: 'primary',
            buttonStyle: buttonStyle,
            icon: 'https://res.cloudinary.com/motortrak/v1706726136/locator/rolls-royce/global/button-arrow.svg',
            styleOverride: function styleOverride() {
              return '\n                  width: ' + (buttonSize ? (buttonSize && buttonSize.width) + ';' : '100%') + '\n                  &:hover, &:active{background-color: #502387;}\n              ';
            }
          })
        )
      );
    })
  );
};

exports.default = MoreInfoRow;