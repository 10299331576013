'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableData = _styledComponents2.default.td.withConfig({
  displayName: 'TableData',
  componentId: 'sc-1wqchsw-0'
})(['vertical-align:top;border-bottom:', ';padding-left:10px;color:#151515;', ''], function (_ref) {
  var hideBottomBorder = _ref.hideBottomBorder;
  return hideBottomBorder ? 'none' : '0.5px solid #151515';
}, function (_ref2) {
  var priceRow = _ref2.priceRow;
  return priceRow && 'padding-right: 10px;';
});

exports.default = TableData;