'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']);

exports.default = PageTitle;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Global = require('../../components/Global');

var _ContentWrapper = require('../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _PathnameSubNav = require('../../components/PathnameSubNav');

var _PathnameSubNav2 = _interopRequireDefault(_PathnameSubNav);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'PageHeading__Container',
  componentId: 'sc-gnnrft-0'
})(['width:100%;text-align:', ';padding-bottom:', ';'], function (_ref) {
  var align = _ref.align;
  return '' + (align || 'unset');
}, function (_ref2) {
  var paddingBottom = _ref2.paddingBottom;
  return paddingBottom !== undefined ? paddingBottom + 'px' : '32px';
});

var HeaderFontUnderline = _styledComponents2.default.span.withConfig({
  displayName: 'PageHeading__HeaderFontUnderline',
  componentId: 'sc-gnnrft-1'
})(['&:after{display:', ';border-bottom:', ';content:\' \';height:', ';width:', ';}'], function (_ref3) {
  var display = _ref3.display;
  return display ? '' + display : 'none';
}, function (_ref4) {
  var borderBottomColour = _ref4.borderBottomColour,
      borderSize = _ref4.borderSize;
  return borderBottomColour && borderSize ? borderBottomColour + ' ' + borderSize + 'px solid' : 'none';
}, function (_ref5) {
  var height = _ref5.height;
  return height ? height + 'px' : 0;
}, function (_ref6) {
  var width = _ref6.width,
      fullWidth = _ref6.fullWidth;
  return fullWidth ? '100%' : width ? width + 'px' : 0;
});

var MobileOnly = _styledComponents2.default.div.withConfig({
  displayName: 'PageHeading__MobileOnly',
  componentId: 'sc-gnnrft-2'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'PageHeading__Wrapper',
  componentId: 'sc-gnnrft-3'
})(['display:', ';', ';'], function (_ref7) {
  var displayTitleMobile = _ref7.displayTitleMobile;
  return displayTitleMobile ? 'block' : 'none';
}, _theme2.default.min.large(_templateObject));

function PageTitle(_ref8) {
  var config = _ref8.config,
      globalStyling = _ref8.globalStyling,
      history = _ref8.history;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    !config.hideInMobile && _react2.default.createElement(
      MobileOnly,
      null,
      _react2.default.createElement(_PathnameSubNav2.default, {
        globalStyling: globalStyling,
        onBackButtonClick: history.goBack,
        config: config,
        history: history
      })
    ),
    _react2.default.createElement(
      Wrapper,
      { displayTitleMobile: config.displayTitleMobile },
      _react2.default.createElement(
        _ContentWrapper2.default,
        {
          contentWidth: globalStyling.contentWidth,
          colour: config.backgroundColour
        },
        _react2.default.createElement(
          Container,
          {
            align: config.alignment,
            paddingBottom: config.paddingBottom
          },
          config.translations.pageTitle && _react2.default.createElement(
            _Global.HeadingOne,
            {
              id: 'main-start-here',
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(config.pageTitleFont) + ' ' + ('margin:' + config.pageHeadingOneMargin || 'margin-bottom: 0') + ';';
              }
            },
            config.pageTitleUnderlineShow && _react2.default.createElement(
              HeaderFontUnderline,
              {
                display: config.pageTitleUnderline.display,
                borderBottomColour: config.pageTitleUnderline.colour.value,
                borderSize: config.pageTitleUnderline.borderSize,
                height: config.pageTitleUnderline.height,
                width: config.pageTitleUnderline.width,
                fullWidth: config.pageTitleUnderline.fullWidth
              },
              config.translations.pageTitle
            ),
            !config.pageTitleUnderlineShow && config.translations.pageTitle
          ),
          config.translations.pageSubTitle && _react2.default.createElement(
            _Global.HeadingTwo,
            {
              styleOverride: function styleOverride() {
                return (0, _Global.fontStyleOverride)(config.pageSubTitleFont);
              }
            },
            config.translations.pageSubTitle
          )
        )
      )
    )
  );
}