'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 24px 0;\n  '], ['\n    padding: 24px 0;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: repeat(5, 1fr);\n  '], ['\n    grid-template-columns: repeat(5, 1fr);\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    border-bottom: none;\n  '], ['\n    border-bottom: none;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    display: flex;\n    ', '\n    line-height: 30px;\n  '], ['\n    display: flex;\n    ', '\n    line-height: 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _navigation = require('../../../helpers/navigation');

var _navigation2 = _interopRequireDefault(_navigation);

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _footerTypes = require('../../../types/RollsRoyce/footerTypes');

var _Global = require('../../Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterLinks = _styledComponents2.default.div.withConfig({
  displayName: 'FooterNavigation__FooterLinks',
  componentId: 'sc-17s64kj-0'
})(['border-bottom:1px solid #3b3b3b;display:grid;gap:8px 16px;justify-content:left;margin:0px;padding:16px 0;width:100%;gap:16px;grid-template-columns:repeat(2,1fr);', ';', ';', ';'], _RollsRoyce2.default.min.small(_templateObject), _RollsRoyce2.default.min.medium(_templateObject2), _RollsRoyce2.default.min.extraLarge(_templateObject3));

var FooterLink = _styledComponents2.default.a.withConfig({
  displayName: 'FooterNavigation__FooterLink',
  componentId: 'sc-17s64kj-1'
})(['text-decoration:none;', ';'], _RollsRoyce2.default.max.extraLarge(_templateObject4, function (_ref) {
  var borderColour = _ref.borderColour;
  return borderColour && 'border-bottom: solid 1px ' + borderColour.value;
}));

var FooterNavigation = function FooterNavigation(_ref2) {
  var _ref2$items = _ref2.items,
      items = _ref2$items === undefined ? [] : _ref2$items,
      itemsConfig = _ref2.itemsConfig;

  var countryCode = (0, _ipInformation.getIpCountryCode)() || '';

  var _useNavigation = (0, _navigation2.default)(),
      getTargetUrl = _useNavigation.getTargetUrl,
      navigateInternalUrl = _useNavigation.navigateInternalUrl;
  // Get Country configuration


  var itemsToRender = itemsConfig.filter(function (config) {
    return config.countries.includes(countryCode);
  });

  // If there is not country configuration, get the default
  if (itemsToRender.length === 0 || countryCode === '') {
    itemsToRender = itemsConfig.filter(function (config) {
      return config.countries.length === 0;
    });
  }

  // Gets the links to be rendered.
  var linksToRender = itemsToRender[0].linkIds.map(function (link) {
    return items.filter(function (i) {
      return i.id === link;
    })[0];
  });

  return _react2.default.createElement(
    FooterLinks,
    null,
    linksToRender.map(function (_ref3) {
      var label = _ref3.label,
          link = _ref3.link,
          id = _ref3.id,
          consentRefresh = _ref3.consentRefresh,
          countrySpecific = _ref3.countrySpecific;

      var linkTo = countrySpecific && countryCode && countrySpecific.countries.includes(countryCode) ? countrySpecific.url : link;

      return _react2.default.createElement(
        FooterLink,
        {
          href: linkTo,
          'data-cy': 'footer-' + label,
          target: getTargetUrl(linkTo),
          key: id,
          onClick: function onClick(event) {
            if (navigateInternalUrl(linkTo)) {
              if (!consentRefresh) {
                event.preventDefault();
              }
            }
          }
        },
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                  color: #FFFFFF;\n                  font-family: RivieraNights-Regular, Jost-Regular;\n                  font-size: 12px;\n                  letter-spacing: 2px;\n                  line-height: 20px;\n                  text-transform: uppercase;\n                  ';
            }
          },
          label
        )
      );
    })
  );
};

exports.default = _react2.default.memo(FooterNavigation);