'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../../components/Global');

var _ComparedVehicleType = require('../../../types/ComparedVehicleType');

var _Shared = require('./Shared');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var PhoneLink = _styledComponents2.default.a.withConfig({
  displayName: 'PhoneLink'
}).withConfig({
  displayName: 'RetailerPhoneRow__PhoneLink',
  componentId: 'sc-gdx7nj-0'
})(['text-decoration:none;']);

var RetailerPhoneRow = function RetailerPhoneRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      font = _ref.font;
  return _react2.default.createElement(
    _Shared.TableRow,
    { fontSize: font && font.fontSize },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id, hideBottomBorder: true },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              margin: '4px 0'
            }
          },
          _react2.default.createElement('img', {
            src: 'https://res.cloudinary.com/motortrak/q_auto,f_auto/locator/rolls-royce/global/icons/vdp-phone-icon.svg',
            alt: 'phone icon',
            style: {
              width: '12px',
              height: '12px',
              marginRight: '3.5px'
            }
          }),
          _react2.default.createElement(
            PhoneLink,
            { href: 'tel:' + vehicle.retailerPhone },
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                font-size: 14px;\n                font-family: RivieraNights-Light, Jost-Light, Arial, sans-serif;\n                padding: 0 2px;\n                color: #151515;\n                letter-spacing: 0.5px;\n              ';
                }
              },
              vehicle.retailerPhone || '-'
            )
          )
        )
      );
    })
  );
};

exports.default = RetailerPhoneRow;