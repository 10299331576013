'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../../../components/Global');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var DataRow = (0, _styledComponents2.default)(_Global.Div).withConfig({
  displayName: 'RenderRow__DataRow',
  componentId: 'sc-wv8qp2-0'
})(['display:flex;justify-content:flex-start;gap:6px;font-weight:300;']);

var RenderRow = function RenderRow(_ref) {
  var content = _ref.content,
      font = _ref.font,
      unit = _ref.unit,
      priceStyle = _ref.priceStyle,
      offerPriceStyle = _ref.offerPriceStyle,
      disclaimer = _ref.disclaimer;

  return _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      DataRow,
      {
        styleOverride: function styleOverride() {
          return '\n        line-height: ' + (disclaimer ? '20px' : '40px') + ';\n        text-align: ' + (font ? '' + font.align : 'left') + ';\n        margin: 0;\n        ' + (priceStyle && 'text-decoration: ' + priceStyle.textDecoration) + ';\n        ' + (offerPriceStyle && 'color: ' + offerPriceStyle.colour.value) + ';\n        ' + (offerPriceStyle && 'font-size: ' + offerPriceStyle.compareFontSize + 'px') + ';\n        letter-spacing: 0.5px;\n      ';
        }
      },
      content,
      ' ',
      unit,
      disclaimer && _react2.default.createElement(
        _Global.Div,
        {
          styleOverride: function styleOverride() {
            return '\n        line-height: ' + (disclaimer ? '20px' : '40px') + ';\n        text-align: ' + (font ? '' + font.align : 'left') + ';\n        margin: 0;\n        ' + (priceStyle && 'text-decoration: ' + priceStyle.textDecoration) + ';\n        ' + (offerPriceStyle && 'color: ' + offerPriceStyle.colour.value) + ';\n        ' + (offerPriceStyle && 'font-size: ' + offerPriceStyle.compareFontSize + 'px') + ';\n      ';
          }
        },
        disclaimer
      )
    )
  );
};

exports.default = RenderRow;