'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialState = exports.actions = exports.id = exports.constants = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

exports.reducer = reducer;

var _reducerMacros = require('../../../utilities/reducerMacros');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var modulePrefix = 'MOD_COMPARE_GRID';
var constants = exports.constants = (0, _reducerMacros.moduleConstants)(modulePrefix, ['TOGGLE_OPTIONAL_EXTRAS', 'INIT']);

var id = exports.id = 'CompareGrid';

var actions = exports.actions = {
  toggleOptionalExtras: (0, _reducerMacros.moduleAction)(constants.TOGGLE_OPTIONAL_EXTRAS, id),
  init: (0, _reducerMacros.moduleAction)(constants.INIT, id)
};

var initialState = exports.initialState = {
  comparedVehicles: [],
  optionalExtras: false
};

function reducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments[1];

  switch (action.type) {
    case constants.TOGGLE_OPTIONAL_EXTRAS:
      {
        return (0, _extends3.default)({}, state, {
          optionalExtras: !state.optionalExtras
        });
      }
    default:
      return state;
  }
}