'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    padding: 0 5%;\n  '], ['\n  ', ';\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    ', '\n  '], ['\n    flex-direction: row;\n    ', '\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n  width: 60%;\n'], ['\n  width: 60%;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../actions/router');

var _formTitleOptions = require('../../helpers/formTitleOptions');

var _validation = require('../../helpers/validation');

var _vehicle = require('../../helpers/vehicle');

var _reducer = require('../../modules/Enquiry/reducer');

var _numbers = require('../../shared/localisation/numbers');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../types/AppShared');

var _EnquiryFormTypes = require('../../types/Bentley/EnquiryFormTypes');

var _Global = require('../Global');

var _EnquiryFormConfirmation = require('./EnquiryFormConfirmation');

var _EnquiryFormConfirmation2 = _interopRequireDefault(_EnquiryFormConfirmation);

var _Form = require('./Form');

var _InputField = require('./Form/InputField');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__Container',
  componentId: 'sc-c3zfxw-0'
})(['display:flex;justify-content:center;']);

var Header = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__Header',
  componentId: 'sc-c3zfxw-1'
})(['display:flex;justify-content:center;margin:40px 0px;font-size:30px;font-family:Bentley-light;text-transform:uppercase;line-height:22px;color:#222222;']);

var EnquiryFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__EnquiryFormContainer',
  componentId: 'sc-c3zfxw-2'
})(['', ';width:40%;', ';height:auto;margin:40px 0;'], _theme2.default.max.large(_templateObject, function (_ref) {
  var width = _ref.width;
  return 'width: ' + (width ? width + 'px' : '90%');
}), function (_ref2) {
  var width = _ref2.width;
  return 'width: ' + (width ? width + 'px' : '40%');
});

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__Row',
  componentId: 'sc-c3zfxw-3'
})(['display:flex;flex-direction:column;', ';'], _theme2.default.min.large(_templateObject2, function (_ref3) {
  var doubleColumn = _ref3.doubleColumn;
  return doubleColumn && '& > div {width: 48%}; justify-content: space-between; ';
}));

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__ActionsContainer',
  componentId: 'sc-c3zfxw-4'
})(['display:flex;width:100%;justify-content:space-between;margin-top:50px;']);

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__Error',
  componentId: 'sc-c3zfxw-5'
})(['width:100%;clear:both;color:#9e1b32;font-family:Bentley-light;', ';'], _theme2.default.max.large(_templateObject3));

var MarketingPreferencesContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__MarketingPreferencesContainer',
  componentId: 'sc-c3zfxw-6'
})(['margin-top:40px;display:flex;flex-direction:column;']);

var MarketingPreferencesTitle = (0, _styledComponents2.default)(Header).withConfig({
  displayName: 'EnquiryTemplate__MarketingPreferencesTitle',
  componentId: 'sc-c3zfxw-7'
})(['margin:0;justify-content:flex-start;font-family:Bentley-SemiBold;color:#727376;font-size:16px;text-transform:none;']);

var MarketingCheckboxContainer = _styledComponents2.default.div.withConfig({
  displayName: 'EnquiryTemplate__MarketingCheckboxContainer',
  componentId: 'sc-c3zfxw-8'
})(['display:flex;justify-content:space-between;margin-top:20px;', ';'], _theme2.default.min.medium(_templateObject4));

var StyledLink = _styledComponents2.default.a.withConfig({
  displayName: 'EnquiryTemplate__StyledLink',
  componentId: 'sc-c3zfxw-9'
})(['cursor:pointer;color:inherit;:visited{color:inherit;}']);

var EnquiryForm = function (_Component) {
  (0, _inherits3.default)(EnquiryForm, _Component);

  function EnquiryForm(props) {
    (0, _classCallCheck3.default)(this, EnquiryForm);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EnquiryForm.__proto__ || (0, _getPrototypeOf2.default)(EnquiryForm)).call(this, props));

    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(input) {
        var _this$setState;

        var id = input.id,
            value = input.value,
            label = input.label;
        var errors = _this.state.errors;

        var error = _this.validate(id, { value: value, label: label });
        // @ts-ignore
        _this.setState((_this$setState = {}, (0, _defineProperty3.default)(_this$setState, id, { label: label, value: value }), (0, _defineProperty3.default)(_this$setState, 'errors', (0, _extends4.default)({}, errors, error)), _this$setState));
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function (_value) {
        function value(_x) {
          return _value.apply(this, arguments);
        }

        value.toString = function () {
          return _value.toString();
        };

        return value;
      }(function (_ref4) {
        var _this$setState2;

        var formKey = _ref4.id,
            value = _ref4.value;
        var errors = _this.state.errors;

        var error = _this.validate(formKey, value);
        // @ts-ignore
        _this.setState((_this$setState2 = {}, (0, _defineProperty3.default)(_this$setState2, formKey, value), (0, _defineProperty3.default)(_this$setState2, 'errors', (0, _extends4.default)({}, errors, error)), _this$setState2));
      })
    });
    Object.defineProperty(_this, 'onSubmitMessage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var params = new URLSearchParams(window.location.search);
        var _this$props = _this.props,
            submitMessage = _this$props.submitMessage,
            vehicle = _this$props.vehicle;
        var _this$state = _this.state,
            title = _this$state.title,
            enquiryType = _this$state.enquiryType,
            consent = _this$state.consent;
        var errors = _this.state.errors;

        var newErrors = (0, _entries2.default)(_this.state).map(function (entry) {
          return _this.validate(entry[0], entry[1]);
        }).filter(function (entry) {
          return entry !== undefined;
        }).reduce(function (acc, currVal) {
          return (0, _extends4.default)({}, acc, currVal);
        }, {});

        if (newErrors !== undefined && (0, _values2.default)(newErrors).filter(function (error) {
          return error !== undefined;
        }).length > 0) {
          window.scroll(0, 0);
          _this.setState({ errors: (0, _extends4.default)({}, errors, newErrors) });
          return;
        }
        var _this$state2 = _this.state,
            infotainmentInterest = _this$state2.infotainmentInterest,
            campaignCode = _this$state2.campaignCode,
            otherState = (0, _objectWithoutProperties3.default)(_this$state2, ['infotainmentInterest', 'campaignCode']);

        submitMessage((0, _extends4.default)((0, _extends4.default)({}, vehicle, otherState, {
          emailConsent: consent.email,
          phoneConsent: consent.phone,
          marketingConsent: consent.post,
          smsConsent: consent.sms,
          title: title.value,
          enquiryType: enquiryType.value,
          mileage: params.get('mileage')
        }, campaignCode ? { campaignCode: infotainmentInterest ? campaignCode : null } : {})));
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function (_value2) {
        function value(_x2, _x3) {
          return _value2.apply(this, arguments);
        }

        value.toString = function () {
          return _value2.toString();
        };

        return value;
      }(function (formKey, value) {
        var translations = _this.props.config.translations;


        var nonValidatedKeys = ['enquiryType', 'vehicleOfInterest', 'errors', 'comments', 'consent', 'campaignCode', 'accessories', 'infotainmentInterest'];

        var actualValidationKeys = [].concat(nonValidatedKeys);

        actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys));

        if (actualValidationKeys.includes(formKey)) return undefined;

        var validations = {
          title: _validation.required,
          firstName: _validation.required,
          lastName: _validation.required,
          email: _validation.validEmail,
          phone: _validation.bentleyValidPhoneRegex,
          comments: _validation.required,
          accepted: _validation.valueIsTrue
        };

        if (formKey === 'title') {
          return (0, _defineProperty3.default)({}, formKey, validations[formKey](value.value, translations));
        }
        return (0, _defineProperty3.default)({}, formKey, validations[formKey](value, translations));
      })
    });

    _this.state = {
      title: {
        label: props.config.translations.formTitleSelect,
        value: ''
      },
      enquiryType: {
        label: props.config.translations.enquiryType,
        value: props.config.translations.enquiryType
      },
      vehicleOfInterest: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      accessories: false,
      infotainmentInterest: false,
      comments: '',
      errors: {},
      accepted: false,
      consent: {
        sms: false,
        email: false,
        phone: false,
        post: false
      },
      campaignCode: null
    };
    return _this;
  }

  (0, _createClass3.default)(EnquiryForm, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _this2 = this;

      // get query string
      var routerData = this.props.routerData;

      if (routerData) {
        if (routerData.campaignCode) {
          this.setState({ campaignCode: routerData.campaignCode });
        }

        if (routerData.selectedKeys) {
          routerData.selectedKeys.forEach(function (key) {
            if ((0, _keys2.default)(_this2.state).includes(key)) {
              _this2.setState(function (prevState) {
                return (0, _extends4.default)({}, prevState, (0, _defineProperty3.default)({}, key, true));
              });
            }
          });
        }
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _this3 = this;

      var _state = this.state,
          accepted = _state.accepted,
          errors = _state.errors;

      if (prevState.accepted !== accepted) {
        this.setState(function () {
          return {
            errors: (0, _extends4.default)({}, errors, _this3.validate('accepted', accepted))
          };
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this4 = this;

      var _props = this.props,
          _props$config = _props.config,
          translations = _props$config.translations,
          formWidth = _props$config.formWidth,
          privacyPolicyLink = _props$config.privacyPolicyLink,
          vdpUrlMetaInfo = _props$config.vdpUrlMetaInfo,
          customSeoProps = _props$config.customSeoProps,
          hidePrice = _props$config.hidePrice,
          hideYear = _props$config.hideYear,
          vehicle = _props.vehicle,
          error = _props.error,
          submissionStatus = _props.submissionStatus,
          dispatch = _props.dispatch,
          shared = _props.shared;


      var vehicleOfInterest = vehicle ? (!hideYear ? vehicle.modelYear : '') + ' ' + vehicle.description + ' ' + (!hidePrice && vehicle.price.value !== 0 ? '' + (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency, 0) : '') : '';

      var _state2 = this.state,
          title = _state2.title,
          firstName = _state2.firstName,
          lastName = _state2.lastName,
          email = _state2.email,
          phone = _state2.phone,
          accessories = _state2.accessories,
          infotainmentInterest = _state2.infotainmentInterest,
          comments = _state2.comments,
          errors = _state2.errors,
          accepted = _state2.accepted,
          consent = _state2.consent;


      var hasError = function hasError(field) {
        return errors && errors[field] && errors[field][0];
      };

      var enquiryFormGoBack = function enquiryFormGoBack() {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)({ vdpUrlMetaInfo: vdpUrlMetaInfo }, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };

      if (!vehicle) return null;

      var placeholderStyle = 'font-family: Bentley-light; font-size: 16px; color: #757575;';

      return _react2.default.createElement(
        _react.Fragment,
        null,
        _react2.default.createElement(
          _reactHelmet.Helmet,
          null,
          _react2.default.createElement(
            'title',
            null,
            (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', customSeoProps, vehicle, translations)
          )
        ),
        _react2.default.createElement(
          Container,
          null,
          submissionStatus === 'Successful' ? _react2.default.createElement(_EnquiryFormConfirmation2.default, {
            translations: translations,
            onGoBack: enquiryFormGoBack
          }) : _react2.default.createElement(
            EnquiryFormContainer,
            { width: formWidth },
            _react2.default.createElement(
              Header,
              null,
              translations.enquiryFormHeader
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.InputField, {
                id: 'vehicleOfInterest',
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: this.onInputChange,
                disabled: true,
                translations: translations,
                placeholderStyle: placeholderStyle
              })
            ),
            _react2.default.createElement(
              Row,
              null,
              _react2.default.createElement(_Form.SelectField, {
                id: 'title',
                value: title.value,
                onChange: this.onSelectChange,
                label: translations.titleLabel,
                pleaseSelect: translations.formTitleSelect,
                options: (0, _formTitleOptions.buildTitleOptions)(translations, 'bentley'),
                error: hasError('title'),
                required: true
              })
            ),
            _react2.default.createElement(
              Row,
              { doubleColumn: true },
              _react2.default.createElement(_Form.InputField, {
                id: 'firstName',
                type: 'text',
                label: translations.firstNameLabel,
                value: firstName,
                onChange: this.onInputChange,
                error: hasError('firstName'),
                required: true,
                translations: translations,
                placeholder: translations.firstNamePlaceholder,
                placeholderStyle: placeholderStyle
              }),
              _react2.default.createElement(_Form.InputField, {
                id: 'lastName',
                type: 'text',
                label: translations.lastNameLabel,
                value: lastName,
                onChange: this.onInputChange,
                error: hasError('lastName'),
                required: true,
                translations: translations,
                placeholder: translations.lastNamePlaceholder,
                placeholderStyle: placeholderStyle
              })
            ),
            _react2.default.createElement(
              Row,
              { doubleColumn: true },
              _react2.default.createElement(_Form.InputField, {
                id: 'email',
                type: 'text',
                label: translations.emailLabel,
                value: email,
                onChange: this.onInputChange,
                error: hasError('email'),
                required: true,
                translations: translations,
                placeholder: translations.emailPlaceholder,
                placeholderStyle: placeholderStyle
              }),
              _react2.default.createElement(_Form.PhoneField, {
                id: 'phone',
                label: translations.phoneLabel,
                value: phone,
                onChange: this.onInputChange,
                error: hasError('phone'),
                required: true
              })
            ),
            this.props.config.accessoriesInterest && _react2.default.createElement(
              Row,
              {
                style: {
                  margin: '20px 0',
                  flexDirection: 'row',
                  alignItems: 'center'
                }
              },
              _react2.default.createElement(_Form.Checkbox, {
                onClick: function onClick() {
                  _this4.setState(function () {
                    return {
                      accessories: !accessories
                    };
                  });
                },
                checked: accessories,
                dataDs: 'bentley-accessories-interest'
              }),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return 'margin: 0 0 0 10px; font-family: Bentley-light ';
                  }
                },
                '' + translations.accessoriesInterestLabel
              )
            ),
            this.state.campaignCode && _react2.default.createElement(
              Row,
              {
                style: {
                  margin: '20px 0',
                  flexDirection: 'row',
                  alignItems: 'center'
                }
              },
              _react2.default.createElement(_Form.Checkbox, {
                onClick: function onClick() {
                  _this4.setState(function () {
                    return {
                      infotainmentInterest: !infotainmentInterest
                    };
                  });
                },
                checked: infotainmentInterest,
                dataDs: 'bentley-infotainment-interest'
              }),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return 'margin: 0 0 0 10px; font-family: Bentley-light ';
                  }
                },
                '' + translations.infotainmentInterestLabel
              )
            ),
            _react2.default.createElement(_Form.TextAreaField, {
              id: 'comments',
              type: 'text',
              label: translations.commentsLabel,
              value: comments,
              onChange: this.onInputChange,
              rows: 1,
              translations: translations,
              maxLength: 500
            }),
            _react2.default.createElement(
              Row,
              {
                style: {
                  margin: '20px 0',
                  flexDirection: 'row',
                  alignItems: 'center'
                }
              },
              _react2.default.createElement(_Form.Checkbox, {
                onClick: function onClick() {
                  _this4.setState(function () {
                    return { accepted: !accepted };
                  });
                },
                checked: accepted
              }),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return 'margin: 0 0 0 10px; font-family: Bentley-light ';
                  }
                },
                translations.policyAgreement + ' ',
                _react2.default.createElement(
                  StyledLink,
                  {
                    href: privacyPolicyLink,
                    target: '_blank',
                    rel: 'noopener noreferrer'
                  },
                  translations.privacyPolicy
                )
              )
            ),
            hasError('accepted') && _react2.default.createElement(
              Error,
              null,
              translations.termsConditionsAccept
            ),
            _react2.default.createElement(
              MarketingPreferencesContainer,
              null,
              _react2.default.createElement(
                MarketingPreferencesTitle,
                null,
                translations.marketingPreferencesTitle
              ),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return ' \n                  font-family: Bentley-light; \n                  color: #222222; \n                  margin-bottom: 8px;\n                ';
                  }
                },
                translations.marketingPreferencesParagraph
              ),
              _react2.default.createElement(
                MarketingCheckboxContainer,
                null,
                _react2.default.createElement(
                  Row,
                  {
                    style: {
                      marginBottom: '20px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }
                  },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this4.setState(function () {
                        return {
                          consent: (0, _extends4.default)({}, consent, { email: !consent.email })
                        };
                      });
                    },
                    checked: consent.email
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                      font-family: Bentley-light; \n                      margin: 0 0 0 10px\n                    ';
                      }
                    },
                    translations.consentEmail
                  )
                ),
                _react2.default.createElement(
                  Row,
                  {
                    style: {
                      marginBottom: '20px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }
                  },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this4.setState(function () {
                        return {
                          consent: (0, _extends4.default)({}, consent, { phone: !consent.phone })
                        };
                      });
                    },
                    checked: consent.phone
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                      font-family: Bentley-light; \n                      margin: 0 0 0 10px\n                    ';
                      }
                    },
                    translations.consentPhone
                  )
                ),
                _react2.default.createElement(
                  Row,
                  {
                    style: {
                      marginBottom: '20px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }
                  },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this4.setState(function () {
                        return {
                          consent: (0, _extends4.default)({}, consent, { post: !consent.post })
                        };
                      });
                    },
                    checked: consent.post
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                      font-family: Bentley-light; \n                      margin: 0 0 0 10px\n                    ';
                      }
                    },
                    translations.consentPost
                  )
                ),
                _react2.default.createElement(
                  Row,
                  {
                    style: {
                      marginBottom: '20px',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }
                  },
                  _react2.default.createElement(_Form.Checkbox, {
                    onClick: function onClick() {
                      _this4.setState(function () {
                        return {
                          consent: (0, _extends4.default)({}, consent, { sms: !consent.sms })
                        };
                      });
                    },
                    checked: consent.sms
                  }),
                  _react2.default.createElement(
                    _Global.Paragraph,
                    {
                      styleOverride: function styleOverride() {
                        return '\n                      font-family: Bentley-light; \n                      margin: 0 0 0 10px\n                    ';
                      }
                    },
                    translations.consentSMS
                  )
                )
              )
            ),
            _react2.default.createElement(
              ActionsContainer,
              null,
              _react2.default.createElement(_Form.Button, {
                variant: 'secondary',
                styleOverride: function styleOverride() {
                  return '\n                    border: 1px solid #00321E !important;\n                    font-family: Bentley-Expanded-Regular;\n                    font-size: 14px;\n                    color: #00321E;\n                  ';
                },
                onClick: enquiryFormGoBack,
                text: translations.cancelActionButton
              }),
              _react2.default.createElement(_Form.Button, {
                variant: 'primary',
                onClick: this.onSubmitMessage,
                text: translations.submitMessageActionButton
              })
            ),
            (error || submissionStatus === 'Failed') && _react2.default.createElement(
              Error,
              null,
              translations.somethingWentWrong
            )
          )
        )
      );
    }
  }]);
  return EnquiryForm;
}(_react.Component);

exports.default = EnquiryForm;