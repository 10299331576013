'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _numbers = require('../../../shared/localisation/numbers');

var _FinanceDrawerStyles = require('./FinanceDrawerStyles');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isNumber = function isNumber(value) {
  var regex = new RegExp('^[0-9]*$');
  return regex.test(value);
};
var CurrencyInput = function CurrencyInput(_ref) {
  var value = _ref.value,
      onChange = _ref.onChange,
      step = _ref.step,
      currency = _ref.currency,
      locale = _ref.locale;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      focused = _useState2[0],
      setFocused = _useState2[1];

  var _useState3 = (0, _react.useState)(value),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      localValue = _useState4[0],
      setLocalValue = _useState4[1];

  var displayValue = (0, _numbers.localiseCurrency)(value, locale, currency, 0.01);

  return _react2.default.createElement(_FinanceDrawerStyles.Input, {
    step: step,
    inputMode: 'numeric',
    type: 'text',
    onChange: function onChange(e) {
      if (isNumber(e.target.value)) {
        setLocalValue(Number(e.target.value));
      }
    },
    onBlur: function onBlur() {
      setFocused(false);
      onChange(localValue);
    },
    onFocus: function onFocus() {
      setFocused(true);
      setLocalValue(value);
    },
    value: focused ? localValue : displayValue
  });
};
exports.default = CurrencyInput;