'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformCompareVehicle = undefined;

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _ComparedVehicleType = require('../../../types/ComparedVehicleType');

var _vehicleTypes = require('../../../types/RollsRoyce/vehicleTypes');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var transformCompareVehicle = exports.transformCompareVehicle = function transformCompareVehicle(vehicle) {
  return (0, _extends3.default)({}, vehicle, {
    specification: {
      transmission: vehicle.transmission,
      bodystyle: vehicle.bodystyle,
      engineDetails: {
        cylinders: vehicle.engineDetails.cylinders
      },
      fuelType: vehicle.fuel,
      interior: vehicle.interior,
      exterior: vehicle.exterior,
      odometer: vehicle.odometer,
      capacity: vehicle.engineCapacity,
      secondaryExterior: vehicle.secondaryExterior,
      secondaryInterior: vehicle.secondaryInterior,
      veneer: vehicle.veneer
    },
    retailerInformation: {
      name: vehicle.retailerName,
      id: vehicle.retailerId,
      phone: vehicle.retailerPhone,
      region: vehicle.retailerRegion,
      countryCode: vehicle.retailerCountryCode
    }
  });
};