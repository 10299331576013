'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    min-width: 100%;\n  '], ['\n    min-width: 100%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n    line-height: 22px;\n    color: #000000;\n  '], ['\n    font-size: 14px;\n    line-height: 22px;\n    color: #000000;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    color: #000000;\n  '], ['\n    color: #000000;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RetailerInformation = require('../../modules/RetailerInformation/RetailerInformation');

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _vehicleDetailsPageTypes = require('../../types/Bentley/vehicleDetailsPageTypes');

var _vehicleTypes = require('../../types/Bentley/vehicleTypes');

var _Button = require('./Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _UserLocation = require('./UserLocation');

var _UserLocation2 = _interopRequireDefault(_UserLocation);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__Container',
  componentId: 'sc-1pweagh-0'
})(['display:flex;flex-direction:column;height:100%;min-width:350px;', ''], _theme2.default.max.small(_templateObject));
var LocationLabel = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationLabel',
  componentId: 'sc-1pweagh-1'
})(['display:flex;flex-direction:row;margin-bottom:10px;font-size:18px;font-family:Bentley-Light;color:#222;']);

var AddressLabel = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__AddressLabel',
  componentId: 'sc-1pweagh-2'
})(['font-style:normal;font-size:18px;line-height:25px;font-weight:300;font-family:Bentley-Light;color:#222222;', ''], _theme2.default.max.medium(_templateObject2));

var ContactLink = _styledComponents2.default.a.withConfig({
  displayName: 'RetailerInfo__ContactLink',
  componentId: 'sc-1pweagh-3'
})(['display:flex;text-decoration:none;font-weight:400;font-size:14px;line-height:22px;font-family:Bentley-Regular;color:#222222;align-items:center;', ''], _theme2.default.max.medium(_templateObject3));

var Underline = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__Underline',
  componentId: 'sc-1pweagh-4'
})(['width:100%;height:1px;background-color:#bbbbbb;margin-top:10px;margin-bottom:10px;']);

var LocationContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__LocationContainer',
  componentId: 'sc-1pweagh-5'
})(['flex:1;']);

var ButtonContainer = _styledComponents2.default.div.withConfig({
  displayName: 'RetailerInfo__ButtonContainer',
  componentId: 'sc-1pweagh-6'
})(['display:flex;flex-direction:column;gap:10px;']);

var RetailerInfo = function RetailerInfo(_ref) {
  var config = _ref.config,
      translations = _ref.translations,
      vehicle = _ref.vehicle,
      retailer = _ref.retailer,
      viewRetailerStock = _ref.viewRetailerStock,
      makeAnEnquiry = _ref.makeAnEnquiry;

  var _useState = (0, _react.useState)({
    latitude: '',
    longitude: ''
  }),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      location = _useState2[0],
      setLocation = _useState2[1];

  var originCoordinates = location.latitude + ',' + location.longitude;
  var dealerCoordinates = vehicle.retailerInformation.location.coordinates[1] + ',' + vehicle.retailerInformation.location.coordinates[0];

  var directionsUrl = 'https://www.google.com/maps/dir/?api=1&origin=' + originCoordinates + '&destination=' + dealerCoordinates;

  var _vehicle$retailerInfo = vehicle.retailerInformation,
      _vehicle$retailerInfo2 = _vehicle$retailerInfo.name,
      dealerName = _vehicle$retailerInfo2 === undefined ? '' : _vehicle$retailerInfo2,
      _vehicle$retailerInfo3 = _vehicle$retailerInfo.phone,
      dealerTelephone = _vehicle$retailerInfo3 === undefined ? '' : _vehicle$retailerInfo3;


  return _react2.default.createElement(
    Container,
    { OpeningHours: true },
    _react2.default.createElement(
      LocationLabel,
      { mobile: true },
      dealerName
    ),
    vehicle.retailerInformation.address.map(function (address) {
      return _react2.default.createElement(
        AddressLabel,
        null,
        address
      );
    }),
    _react2.default.createElement(Underline, null),
    dealerTelephone && dealerTelephone.length > 0 && _react2.default.createElement(
      _react2.default.Fragment,
      null,
      _react2.default.createElement(
        ContactLink,
        { href: 'tel:' + dealerTelephone },
        translations.phoneLabel,
        vehicle.retailerInformation.phone
      ),
      _react2.default.createElement(Underline, null)
    ),
    _react2.default.createElement(
      LocationContainer,
      null,
      _react2.default.createElement(_UserLocation2.default, {
        iconUrl: config.locateUserIconUrl,
        placeholderText: translations.locationPlaceholder,
        browserLocationCallback: function browserLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        inputLocationCallback: function inputLocationCallback(lat, long) {
          setLocation({
            latitude: lat,
            longitude: long
          });
        },
        translations: translations,
        onButtonClick: function onButtonClick() {
          window.open(directionsUrl, '_blank');
        }
      })
    ),
    _react2.default.createElement(
      ButtonContainer,
      null,
      _react2.default.createElement(
        _Button2.default,
        {
          variant: 'primary',
          onClick: function onClick() {
            return viewRetailerStock(retailer);
          },
          styleOverride: function styleOverride() {
            return 'width: 100%;';
          }
        },
        translations.viewDealerStock
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          variant: 'primary',
          onClick: function onClick(e) {
            return makeAnEnquiry(e);
          },
          styleOverride: function styleOverride() {
            return 'width: 100%;';
          }
        },
        translations.contact
      )
    )
  );
};

exports.default = RetailerInfo;