'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

var _isEmpty = require('ramda/src/isEmpty');

var _isEmpty2 = _interopRequireDefault(_isEmpty);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _SearchResults = require('../../../components/Bentley/SearchResults');

var _SearchResults2 = _interopRequireDefault(_SearchResults);

var _filters = require('../../../helpers/filters');

var _filters2 = _interopRequireDefault(_filters);

var _vehicle = require('../../../helpers/vehicle');

var _filters3 = require('../../../shared/filters');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _shortlist = require('../../../shared/shortlist');

var _settings = require('../../../shared/sortOptions/settings');

var _theme = require('../../../theme');

var _MarketInfo = require('../../../types/MarketInfo');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FIRST_PAGE = 1;

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'BentleyVehicleSearchResults__Wrapper',
  componentId: 'sc-6hci2f-0'
})(['min-width:1px;margin:0 auto;width:100%;height:100%;display:flex;justify-content:center;']);

var BentleyVehicleSearchResults = function (_Component) {
  (0, _inherits3.default)(BentleyVehicleSearchResults, _Component);

  function BentleyVehicleSearchResults(props) {
    (0, _classCallCheck3.default)(this, BentleyVehicleSearchResults);

    var _this = (0, _possibleConstructorReturn3.default)(this, (BentleyVehicleSearchResults.__proto__ || (0, _getPrototypeOf2.default)(BentleyVehicleSearchResults)).call(this, props));

    Object.defineProperty(_this, 'onViewportChange', {
      enumerable: true,
      writable: true,
      value: function value() {
        var viewBrakepoint = _this.state.viewBrakepoint ? _theme.screenSizeOptions.extraLarge : _theme.screenSizeOptions.xxl;
        if (window.innerWidth < viewBrakepoint) {
          _this.setState(function () {
            return { selectedView: 'GRID' };
          });
        }
        if (window.innerWidth > viewBrakepoint) {
          _this.setState(function () {
            return { selectedView: 'LIST' };
          });
        }
      }
    });
    Object.defineProperty(_this, 'onFilterMenuClick', {
      enumerable: true,
      writable: true,
      value: function value() {
        return _this.setState(function (prevState) {
          return {
            filtersExpanded: !prevState.filtersExpanded
          };
        });
      }
    });
    Object.defineProperty(_this, 'onShowAllResultsClick', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            actions = _this$props.actions,
            marketInfo = _this$props.marketInfo,
            history = _this$props.history,
            state = _this$props.state,
            dispatch = _this$props.dispatch,
            hashRouting = _this$props.hashRouting,
            _this$props$config$en = _this$props.config.endpoints,
            endpoints = _this$props$config$en === undefined ? {} : _this$props$config$en;

        var filters = (0, _filters.queryFromHistory)(history, hashRouting);

        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', true));

        actions.showAll((0, _extends3.default)({}, marketInfo, {
          filters: filters,
          pageNumber: state.currentPage,
          urlConfig: endpoints.vehicles,
          sortField: _this.props.shared.sessionPreferences.searchSort
        }));
      }
    });
    Object.defineProperty(_this, 'onPageNumberChange', {
      enumerable: true,
      writable: true,
      value: function value(_value) {
        _this.updateInventory(_value, _this.props.shared.sessionPreferences.searchSort);

        window.scroll(0, 0);
      }
    });
    Object.defineProperty(_this, 'onSortChange', {
      enumerable: true,
      writable: true,
      value: function value(payload, sortField) {
        var endpoints = _this.props.config.endpoints;

        _this.props.dispatch(_filters3.actions.updateFilters(payload, endpoints && endpoints.filters || ''));
        _this.props.dispatch(_sessionPreferences.actions.updateSessionPreferences('searchSort', _settings.sortOptions[sortField]));
      }
    });
    Object.defineProperty(_this, 'updateInventory', {
      enumerable: true,
      writable: true,
      value: function value(pageNumber, sortField) {
        var _this$props2 = _this.props,
            actions = _this$props2.actions,
            marketInfo = _this$props2.marketInfo,
            history = _this$props2.history,
            _this$props2$config$e = _this$props2.config.endpoints,
            endpoints = _this$props2$config$e === undefined ? {} : _this$props2$config$e,
            hashRouting = _this$props2.hashRouting;

        var filters = (0, _filters.queryFromHistory)(history, hashRouting);

        actions.getInventory((0, _extends3.default)({}, marketInfo, {
          filters: filters,
          pageNumber: pageNumber,
          sortField: sortField,
          urlConfig: endpoints.vehicles
        }));
      }
    });


    var selectedView = window.innerWidth < _theme.screenSizeOptions.xxl ? 'GRID' : 'LIST';

    _this.state = {
      filtersExpanded: false,
      selectedView: selectedView,
      viewBrakepoint: props.config.viewBrakepoint
    };
    return _this;
  }

  (0, _createClass3.default)(BentleyVehicleSearchResults, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      // We call init to force a first run of the reducer
      // - there used to be a more generic solution,
      //   but it broke the builder
      var _props = this.props,
          actions = _props.actions,
          dispatch = _props.dispatch,
          history = _props.history,
          shared = _props.shared,
          router = _props.router,
          state = _props.state,
          _props$config = _props.config,
          _props$config$endpoin = _props$config.endpoints,
          endpoints = _props$config$endpoin === undefined ? {} : _props$config$endpoin,
          filtersInstanceId = _props$config.filtersInstanceId,
          linkedModules = _props.linkedModules,
          hashRouting = _props.hashRouting;

      var query = (0, _filters.queryFromHistory)(history, hashRouting);
      var parsedQuery = _querystring2.default.parse(query);
      var filtersConfig = filtersInstanceId ? linkedModules[filtersInstanceId] : (0, _values2.default)(linkedModules).find(function (lm) {
        return lm.name === 'Filters';
      });
      var defaultFilters = filtersConfig && filtersConfig.config && filtersConfig.config.defaultSelectedFilters;
      parsedQuery = (0, _extends3.default)({}, defaultFilters, parsedQuery);

      var newQuery = _querystring2.default.stringify((0, _filters2.default)(parsedQuery));
      if ((query || newQuery) && query !== newQuery) {
        dispatch(_router.actions.navigateWithFilters('?' + newQuery, true));
      }
      this.setState({ query: newQuery });
      actions.init();
      this.updateInventory(state.currentPage, shared.sessionPreferences.searchSort);
      dispatch(_filters3.actions.initialiseFilters(newQuery, endpoints.filters));
      dispatch(_shortlist.actions.getVehicles());
      var retailerId = router.pathProps.retailerId;

      if (retailerId) {
        actions.getDealer(retailerId);
      }

      if (shared.sessionPreferences.currency) {
        dispatch(actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates
        }));
      }

      window.addEventListener('resize', this.onViewportChange);

      var vdpMatcher = /.*\/vdp\/.*/i;
      var previousRoute = router.navigationHistory[1];
      var comeFromVdp = vdpMatcher.test(previousRoute);
      var forceUpdate = !comeFromVdp || comeFromVdp && parsedQuery.retailerId;

      if (shared.sessionPreferences.infiniteScroll) {
        this.onShowAllResultsClick();
      } else {
        actions.cancelLoading(forceUpdate);
        window.scroll(0, 0);
      }

      if (forceUpdate) {
        actions.clearLoaded();
        this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
      }
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var _props2 = this.props,
          shared = _props2.shared,
          state = _props2.state,
          actions = _props2.actions,
          history = _props2.history,
          dispatch = _props2.dispatch,
          marketInfo = _props2.marketInfo,
          linkedModules = _props2.linkedModules,
          router = _props2.router,
          hashRouting = _props2.hashRouting,
          _props2$config = _props2.config,
          _props2$config$endpoi = _props2$config.endpoints,
          endpoints = _props2$config$endpoi === undefined ? {} : _props2$config$endpoi,
          filtersInstanceId = _props2$config.filtersInstanceId;

      var query = (0, _filters.queryFromHistory)(history, hashRouting);

      if (router !== prevProps.router && !router.queryParams) {
        // if no query params then may have navigated using search link
        // if default filters they need to be reapplied
        var parsedQuery = _querystring2.default.parse(query);
        var filtersConfig = filtersInstanceId ? linkedModules[filtersInstanceId] : (0, _values2.default)(linkedModules).find(function (lm) {
          return lm.name === 'Filters';
        });
        var defaultFilters = filtersConfig && filtersConfig.config && filtersConfig.config.defaultSelectedFilters;
        parsedQuery = (0, _extends3.default)({}, defaultFilters, parsedQuery);

        var newQuery = _querystring2.default.stringify((0, _filters2.default)(parsedQuery));
        if ((query || newQuery) && query !== newQuery) {
          dispatch(_router.actions.navigateWithFilters('?' + newQuery, true));
        }
        dispatch(_filters3.actions.initialiseFilters(newQuery, endpoints.filters));
        query = newQuery;
      }
      if ((state.searchResults !== undefined && state.searchResults[FIRST_PAGE] || state.filters) && (this.state.query !== query || state.filters !== query || prevProps.shared.sessionPreferences.market !== shared.sessionPreferences.market || !state.loading && state.searchResults !== undefined && !state.searchResults[FIRST_PAGE]) || prevProps.shared.sessionPreferences.location !== shared.sessionPreferences.location) {
        this.setState({ query: query });
        actions.clearLoaded();

        this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
        var retailerId = shared.filters.selectedFilters && shared.filters.selectedFilters.retailerId;

        if (retailerId && !(state.dealer && state.dealer.id === retailerId)) {
          actions.getDealer(retailerId);
        }
      } else if (state.searchResults === undefined) {
        this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
      }
      if (prevProps.shared.currencyConversion.exchangeRates !== shared.currencyConversion.exchangeRates) {
        dispatch(actions.updateVehiclePrices({
          currency: shared.sessionPreferences.currency,
          exchangeRates: shared.currencyConversion.exchangeRates
        }));
      }
      // if exchange rates have been deleted, reset the prices
      if (!(0, _isEmpty2.default)(prevProps.shared.currencyConversion.exchangeRates) && (0, _isEmpty2.default)(shared.currencyConversion.exchangeRates)) {
        this.updateInventory(state.currentPage, shared.sessionPreferences.searchSort);
      }

      if (prevProps.marketInfo.market !== marketInfo.market) {
        this.updateInventory(FIRST_PAGE, shared.sessionPreferences.searchSort);
      }

      if (prevProps.shared.sessionPreferences.language !== shared.sessionPreferences.language) {
        this.updateInventory(state.currentPage, shared.sessionPreferences.searchSort);
      }
    }
  }, {
    key: 'componentWillUnmount',
    value: function componentWillUnmount() {
      var _props3 = this.props,
          actions = _props3.actions,
          dispatch = _props3.dispatch,
          history = _props3.history;

      var vdpMatcher = /.*\/vdp\/.*/i;
      var goingToVdp = vdpMatcher.test(history.location.pathname);

      if (!goingToVdp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }

      actions.cancelLoading(!goingToVdp);
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props4 = this.props,
          state = _props4.state,
          dispatch = _props4.dispatch,
          config = _props4.config,
          shared = _props4.shared,
          history = _props4.history,
          marketInfo = _props4.marketInfo,
          linkedModules = _props4.linkedModules,
          actions = _props4.actions,
          resetFilters = _props4.resetFilters,
          onFilterMenuClick = _props4.onFilterMenuClick,
          filtersInstanceId = _props4.config.filtersInstanceId;
      var _state = this.state,
          filtersExpanded = _state.filtersExpanded,
          selectedView = _state.selectedView;


      var filtersConfig = filtersInstanceId ? linkedModules[filtersInstanceId] : (0, _values2.default)(linkedModules).find(function (lm) {
        return lm.name === 'Filters';
      });

      var onMoreInfoClick = function onMoreInfoClick(_, vehicle) {
        var vehicleMetaInfo = (0, _vehicle.vanityUrlFormatter)(config, vehicle, shared.sessionPreferences.language);
        dispatch(_router.actions.navigate('/vdp/' + vehicle.id + '-' + vehicleMetaInfo));
      };

      var showPromotion = function showPromotion(vehicle) {
        if (!config.promotion) return false;

        var _config$promotion = config.promotion,
            countryFilter = _config$promotion.countryFilter,
            modelGroupFilter = _config$promotion.modelGroupFilter;


        return countryFilter.includes(vehicle.retailerCountryCode) && modelGroupFilter.includes(vehicle.modelGroupName);
      };

      var onEnquireClick = function onEnquireClick(_, vehicle) {
        var data = null;

        if (showPromotion(vehicle)) {
          data = {
            campaignCode: config.promotion.campaignCode
          };
        }

        dispatch(_router.actions.navigateWithPayload('/enquiry/' + vehicle.id, data ? data : null));
      };

      var retailer = this.retailerId && (0, _extends3.default)({
        id: this.retailerId
      }, state.dealer);

      return _react2.default.createElement(
        Wrapper,
        null,
        _react2.default.createElement(_SearchResults2.default, {
          config: config,
          shared: shared,
          marketInfo: marketInfo,
          translations: config.translations,
          filtersExpanded: filtersExpanded,
          onFilterMenuClick: onFilterMenuClick,
          results: state.searchResults,
          onMoreInfoClick: onMoreInfoClick,
          onEnquireClick: onEnquireClick,
          selectedView: selectedView,
          resetFilters: resetFilters,
          retailer: retailer,
          history: history,
          filtersConfig: filtersConfig,
          loading: state.loading,
          currentPage: state.currentPage,
          pageSize: state.pageSize,
          totalResults: state.totalResults,
          filtersLoaded: shared.filters && (shared.filters.selectedFilters || shared.filters.availableFilterValues),
          onPageNumberChange: function onPageNumberChange(page) {
            return _this2.onPageNumberChange(page);
          },
          getSimilarCars: actions.getSimilarCars
        })
      );
    }
  }]);
  return BentleyVehicleSearchResults;
}(_react.Component);

exports.default = BentleyVehicleSearchResults;