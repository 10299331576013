'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

exports.default = CompareVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Global = require('../../components/Global');

var _Remove = require('../../components/JaguarIcons/Global/Remove');

var _Remove2 = _interopRequireDefault(_Remove);

var _StyledIcon = require('../../components/StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _emissions = require('../../helpers/emissions');

var _vehicle = require('../../helpers/vehicle');

var _numbers = require('../../shared/localisation/numbers');

var _translateFromTemplate = require('../../shared/localisation/translateFromTemplate');

var _Shared = require('./Shared');

var _components = require('../../components');

var _Tooltip = require('../../components/Tooltip');

var _Tooltip2 = _interopRequireDefault(_Tooltip);

var _helpers = require('../../shared/currencyConversion/helpers');

var _ComparedVehicleType = require('./../../types/ComparedVehicleType');

var _KiaConsumptionEmissionsComponent = require('./CountryCompareGridComponents/KiaConsumptionEmissionsComponent');

var _KiaConsumptionEmissionsComponent2 = _interopRequireDefault(_KiaConsumptionEmissionsComponent);

var _EquipmentMetaDataRow = require('./EquipmentMetaDataRow');

var _EquipmentMetaDataRow2 = _interopRequireDefault(_EquipmentMetaDataRow);

var _MoreInfoRow = require('./MoreInfoRow');

var _MoreInfoRow2 = _interopRequireDefault(_MoreInfoRow);

var _OptionalExtrasRow = require('./OptionalExtrasRow');

var _OptionalExtrasRow2 = _interopRequireDefault(_OptionalExtrasRow);

var _RetailerPhoneRow = require('./RetailerPhoneRow');

var _RetailerPhoneRow2 = _interopRequireDefault(_RetailerPhoneRow);

var _ShortlistRow = require('./ShortlistRow');

var _ShortlistRow2 = _interopRequireDefault(_ShortlistRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var GERMANY_COUNTRY_CODE = 'DE';
var GERMANY_COUNNTRY_NAME = 'germany';
var KIA_BRAND_NAME = 'kia';

var Container = _styledComponents2.default.table.withConfig({
  displayName: 'CompareVehiclesContainer'
}).withConfig({
  displayName: 'CompareVehicles__Container',
  componentId: 'sc-wiajcq-0'
})(['width:95%;height:auto;margin:0 2.5% 5% 2.5%;border-collapse:separate;border-spacing:10px 0;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__ImageContainer',
  componentId: 'sc-wiajcq-1'
})(['cursor:pointer;position:relative;']);

var StyledImage = _styledComponents2.default.img.withConfig({
  displayName: 'CompareVehicles__StyledImage',
  componentId: 'sc-wiajcq-2'
})(['width:100%;']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'ImageOverlay'
}).withConfig({
  displayName: 'CompareVehicles__ImageOverlay',
  componentId: 'sc-wiajcq-3'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldHeading',
  componentId: 'sc-wiajcq-4'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldBody',
  componentId: 'sc-wiajcq-5'
})(['font-size:14px;max-width:165px;']);

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__EmptyContainer',
  componentId: 'sc-wiajcq-6'
})(['display:flex;flex-direction:column;align-items:center;padding:72px 0 280px 0;justify-content:center;']);

var PriceWithTooltip = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__PriceWithTooltip',
  componentId: 'sc-wiajcq-7'
})(['display:flex;gap:10px;align-items:center;']);

function CompareVehicles(props) {
  var requestInformation = props.requestInformation,
      optionalExtras = props.optionalExtras,
      toggleOptionalExtras = props.toggleOptionalExtras,
      _props$comparedVehicl = props.comparedVehicles,
      comparedVehicles = _props$comparedVehicl === undefined ? [] : _props$comparedVehicl,
      removeVehicle = props.removeVehicle,
      shortlistVehicle = props.shortlistVehicle,
      getShortListedIconColour = props.getShortListedIconColour,
      equipmentMetaData = props.equipmentMetaData,
      globalStyling = props.globalStyling,
      gridConfiguration = props.gridConfiguration,
      currentLanguage = props.currentLanguage,
      onMoreInfoClick = props.onMoreInfoClick,
      ctaToVdp = props.ctaToVdp,
      _props$config = props.config,
      headerFont = _props$config.headerFont,
      paragraphFont = _props$config.paragraphFont,
      subHeaderFont = _props$config.subHeaderFont,
      buttonSize = _props$config.buttonSize,
      closeIconColour = _props$config.closeIconColour,
      infoFont = _props$config.infoFont,
      phoneIconColour = _props$config.phoneIconColour,
      translations = _props$config.translations,
      optionalIconFont = _props$config.optionalIconFont,
      optionalIconColourFont = _props$config.optionalIconColourFont,
      optionalHeadingThreeFont = _props$config.optionalHeadingThreeFont,
      optionalExtrasBackgroundColour = _props$config.optionalExtrasBackgroundColour,
      compareTableDataBackgroundColour = _props$config.compareTableDataBackgroundColour,
      optionalCloseIcon = _props$config.optionalCloseIcon,
      displayModelAndVariant = _props$config.displayModelAndVariant,
      handleZeroPriceAsText = _props$config.handleZeroPriceAsText,
      hideBottomBorder = _props$config.hideBottomBorder,
      showOptionalExtras = _props$config.showOptionalExtras,
      hideYear = _props$config.hideYear,
      convertedKwToHp = _props$config.convertedKwToHp,
      specialOfferStyling = _props$config.specialOfferStyling,
      showSectionSalesTaxDisclaimer = _props$config.showSectionSalesTaxDisclaimer,
      showBasePriceAndExpensesForJapanDealers = _props$config.showBasePriceAndExpensesForJapanDealers,
      hideContactButton = _props$config.hideContactButton;


  var buttonStyle = globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle;

  var getVehicleName = function getVehicleName(vehicle) {
    if (displayModelAndVariant && vehicle.modelVariant) return vehicle.name + ' ' + vehicle.modelVariant;
    if (hideYear) return vehicle.description;
    return vehicle.name;
  };

  var getCarBasePrice = function getCarBasePrice(vehicle) {
    return (0, _helpers.handleVehiclePrice)(vehicle.basePrice, {
      currencyType: vehicle.price.currency,
      locale: currentLanguage.replace('_', '-'),
      handleZeroPriceAsText: handleZeroPriceAsText,
      priceSpecial: vehicle.priceSpecial,
      translationsSold: translations.sold,
      vehiclePrice: vehicle.price.value,
      zeroPriceText: props.config.translations.zeroPriceText,
      translations: translations,
      retailerCountryCode: vehicle.retailerCountryCode
    });
  };

  var getOtherAmount = function getOtherAmount(vehicle) {
    return (0, _helpers.handleVehiclePrice)(vehicle.expenses, {
      currencyType: vehicle.price.currency,
      locale: currentLanguage.replace('_', '-'),
      handleZeroPriceAsText: handleZeroPriceAsText,
      priceSpecial: vehicle.priceSpecial,
      translationsSold: translations.sold,
      vehiclePrice: vehicle.price.value,
      zeroPriceText: props.config.translations.zeroPriceText,
      translations: translations,
      retailerCountryCode: vehicle.retailerCountryCode
    });
  };

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return {
      emissionsEnabled: state.config.config.global.featureFlags && state.config.config.global.featureFlags.emissionsEnabled
    };
  }),
      emissionsEnabled = _useSelector.emissionsEnabled;

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    return state.config.config.global.inventory;
  }),
      make = _useSelector2.make,
      brandCountry = _useSelector2.country;

  var displayEmissionsEfficiency = function displayEmissionsEfficiency(vehicle) {
    if (vehicle && vehicle.emissions && (vehicle.emissions.nedcConsumptionCombined || vehicle.emissions.wltpConsumptionCombined || vehicle.emissions.nedcEmissionsCombined || vehicle.emissions.wltpEmissionsCombined)) return vehicle.energyEfficiencyRating;
    return '-';
  };

  var displayDisclaimer = function displayDisclaimer(vehicle) {
    if (vehicle.retailerCountryCode === GERMANY_COUNTRY_CODE) {
      return vehicle.sectionSalesTaxDisclaimer === 1 && !vehicle.netPrice ? _react2.default.createElement(
        'div',
        null,
        _react2.default.createElement(
          _Tooltip2.default,
          { text: translations.salesTaxDisclaimer },
          _react2.default.createElement(
            _components.IconWrapper,
            null,
            _react2.default.createElement(_StyledIcon2.default, { height: 20, width: 20, link: props.config.infoIcon })
          )
        )
      ) : null;
    }
  };

  var _useMemo = (0, _react.useMemo)(function () {
    return [comparedVehicles.some(function (v) {
      return v.netPrice;
    })];
  }, [comparedVehicles]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 1),
      hasNetPrice = _useMemo2[0];

  var _useMemo3 = (0, _react.useMemo)(function () {
    return [comparedVehicles.some(function (v) {
      return v.retailerCountryCode === 'JP' && v.basePrice && v.expenses;
    })];
  }, [comparedVehicles]),
      _useMemo4 = (0, _slicedToArray3.default)(_useMemo3, 1),
      isJapaneseDealer = _useMemo4[0];

  var isKiaGermany = function isKiaGermany(make, brandCountry) {
    return make === KIA_BRAND_NAME && brandCountry === GERMANY_COUNNTRY_NAME;
  };

  return comparedVehicles.length > 0 ? _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      'tbody',
      null,
      _react2.default.createElement(
        _Shared.TableRow,
        { fontSize: subHeaderFont && subHeaderFont.fontSize },
        _react2.default.createElement('td', null),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              'div',
              { style: { display: 'flex', justifyContent: 'flex-end' } },
              _react2.default.createElement(
                _Shared.IconButton,
                {
                  onClick: function onClick() {
                    return removeVehicle(vehicle);
                  },
                  'aria-label': translations.removeVehicleAriaLabel
                },
                optionalCloseIcon ? _react2.default.createElement('img', { src: optionalCloseIcon, alt: translations.close }) : _react2.default.createElement(_Remove2.default, {
                  'aria-label': translations.removeVehicleAriaLabel,
                  width: '2em',
                  height: '2em',
                  colour: closeIconColour && closeIconColour.value
                })
              )
            )
          );
        })
      ),
      _react2.default.createElement(
        _Shared.TableRow,
        { fontSize: subHeaderFont && subHeaderFont.fontSize },
        _react2.default.createElement(
          'td',
          {
            style: {
              width: '25%',
              background: '' + (compareTableDataBackgroundColour ? '' + compareTableDataBackgroundColour.value : 'transparent')
            }
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n              ' + (0, _Global.fontStyleOverride)(subHeaderFont);
              }
            },
            translations.compareVehiclesSubHeader
          )
        ),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              ImageContainer,
              {
                onClick: function onClick() {
                  return vehicle.status !== 'sold' && onMoreInfoClick('VDP', vehicle);
                }
              },
              vehicle.images.length > 0 ? _react2.default.createElement(StyledImage, {
                src: vehicle.images[0],
                width: '100%',
                alt: vehicle.name
              }) : _react2.default.createElement(StyledImage, {
                src: 'https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png',
                alt: 'placeholderImage'
              }),
              vehicle.status === 'sold' || vehicle.priceSpecial === 'SOLD' && translations.sold && _react2.default.createElement(
                ImageOverlay,
                null,
                _react2.default.createElement(
                  SoldHeading,
                  null,
                  translations.sold
                ),
                _react2.default.createElement(
                  SoldBody,
                  null,
                  translations.similarVehiclesAvailable
                )
              )
            )
          );
        })
      ),
      _react2.default.createElement(_Shared.DataRow, {
        label: hasNetPrice ? translations.grossPrice : translations.comparePrice,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (0, _helpers.handleVehiclePrice)(vehicle.price.value, {
            currencyType: vehicle.price.currency,
            locale: currentLanguage.replace('_', '-'),
            handleZeroPriceAsText: handleZeroPriceAsText,
            priceSpecial: vehicle.priceSpecial,
            translationsSold: translations.sold,
            vehiclePrice: vehicle.price.value,
            zeroPriceText: props.config.translations.zeroPriceText,
            translations: translations,
            retailerCountryCode: vehicle.retailerCountryCode
          });
        },
        specialOfferStyling: specialOfferStyling,
        currentLanguage: currentLanguage,
        translations: translations,
        disclaimerFunc: function disclaimerFunc(vehicle) {
          return displayDisclaimer(vehicle);
        }
      }),
      hasNetPrice && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_Shared.DataRow, {
          label: translations.netPrice,
          comparedVehicles: comparedVehicles,
          font: infoFont,
          displayFunc: function displayFunc(vehicle) {
            return _react2.default.createElement(
              PriceWithTooltip,
              null,
              (0, _helpers.handleVehiclePrice)(vehicle.netPrice, {
                currencyType: vehicle.price.currency,
                locale: currentLanguage.replace('_', '-'),
                handleZeroPriceAsText: handleZeroPriceAsText,
                priceSpecial: vehicle.priceSpecial,
                translationsSold: translations.sold,
                vehiclePrice: vehicle.price.value,
                zeroPriceText: props.config.translations.zeroPriceText
              })
            );
          },
          specialOfferStyling: specialOfferStyling,
          currentLanguage: currentLanguage,
          translations: translations
        }),
        _react2.default.createElement(_Shared.DataRow, {
          label: translations.tax,
          comparedVehicles: comparedVehicles,
          font: infoFont,
          displayFunc: function displayFunc(vehicle) {
            return vehicle.netPrice && !vehicle.sectionSalesTaxDisclaimer && showSectionSalesTaxDisclaimer ? translations.taxValue : '-';
          }
        })
      ),
      isJapaneseDealer && showBasePriceAndExpensesForJapanDealers && _react2.default.createElement(
        _react2.default.Fragment,
        null,
        _react2.default.createElement(_Shared.DataRow, {
          label: translations.carBasePrice,
          comparedVehicles: comparedVehicles,
          font: infoFont,
          displayFunc: function displayFunc(vehicle) {
            return getCarBasePrice(vehicle);
          }
        }),
        _react2.default.createElement(_Shared.DataRow, {
          label: translations.otherAmount,
          comparedVehicles: comparedVehicles,
          font: infoFont,
          displayFunc: function displayFunc(vehicle) {
            return getOtherAmount(vehicle);
          }
        })
      ),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesModelYear,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return getVehicleName(vehicle);
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesExterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['exterior'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesInterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['interior'],
        font: infoFont
      }),
      translations.compareVehiclesVeneer && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesVeneer,
        comparedVehicles: comparedVehicles,
        keyValues: ['veneer'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesOdometer,
        comparedVehicles: comparedVehicles,
        keyValues: ['odometer', 'display'],
        visible: gridConfiguration && gridConfiguration.showOdometer,
        font: infoFont,
        zeroMileageText: translations.zeroMileageText
      }),
      translations.compareVehiclesTransmission && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesTransmission,
        comparedVehicles: comparedVehicles,
        keyValues: ['transmission'],
        font: infoFont
      }),
      translations.compareVehiclesEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEmissions,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'emissions'],
        font: infoFont
      }),
      translations.compareVehiclesFuelConsumption && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesFuelConsumption,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'fuelConsumption'],
        visible: gridConfiguration && gridConfiguration.showFuelConsumption,
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesBodystyle,
        comparedVehicles: comparedVehicles,
        keyValues: ['bodystyle'],
        font: infoFont
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEngine,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineCapacity ? (0, _numbers.localiseNumber)(vehicle.engineCapacity, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineCapacity && translations.ccUnit ? translations.ccUnit : '');
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesFuelType,
        comparedVehicles: comparedVehicles,
        keyValues: ['fuel'],
        font: infoFont
      }),
      translations.co2EmissionsCombined && _react2.default.createElement(_Shared.DataRow, {
        label: translations.co2EmissionsCombined,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.co2EmissionsCombined ? (0, _numbers.localiseNumber)(vehicle.engineDetails.co2EmissionsCombined, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.co2EmissionsCombined && translations.co2Unit ? translations.co2Unit : '');
        }
      }),
      translations.combinedConsumption && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.combinedConsumption,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.combinedConsumption ? (0, _numbers.localiseNumber)(vehicle.engineDetails.combinedConsumption, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.combinedConsumption && translations.combinedConsumptionUnit ? translations.combinedConsumptionUnit : '');
        }
      }),
      translations.consumptionUrban && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.consumptionUrban,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.consumptionUrban ? (0, _numbers.localiseNumber)(vehicle.engineDetails.consumptionUrban, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.consumptionUrban && translations.consumptionUrbanUnit ? translations.consumptionUrbanUnit : '');
        }
      }),
      translations.consumptionExtraUrban && !props.config.hideEmissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.consumptionExtraUrban,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineDetails.consumptionExtraurban ? (0, _numbers.localiseNumber)(vehicle.engineDetails.consumptionExtraurban, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineDetails.consumptionExtraurban && translations.consumptionExtraUrbanUnit ? translations.consumptionExtraUrbanUnit : '');
        }
      }),
      isKiaGermany(make, brandCountry) && _react2.default.createElement(_KiaConsumptionEmissionsComponent2.default, {
        comparedVehicles: comparedVehicles,
        infoFont: infoFont,
        translations: translations
      }),
      translations.powerKw && _react2.default.createElement(_Shared.DataRow, {
        label: translations.powerKw,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return convertedKwToHp && vehicle.engineDetails.powerKw ? (0, _translateFromTemplate.translateFromTemplate)('powerReading', {
            KW: vehicle.powerKwNumeric,
            HP: (0, _vehicle.vehiclePropFormatter)(convertedKwToHp, vehicle, vehicle.locale)
          }, translations) : (vehicle.engineDetails.powerKw ? vehicle.engineDetails.powerKw : '-') + ' ' + (vehicle.engineDetails.powerKw && translations.powerUnit ? translations.powerUnit : '');
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesRegistration,
        comparedVehicles: comparedVehicles,
        keyValues: ['registration'],
        visible: gridConfiguration && gridConfiguration.showRegistrationYear,
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesCombinedCO2Emissions && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesCombinedCO2Emissions,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return (0, _emissions.formatCombinedValues)(vehicle.emissions.nedcEmissionsCombined, vehicle.emissions.wltpEmissionsCombined) || '-';
        },
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesCombinedConsumption && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesCombinedConsumption,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return (0, _emissions.formatCombinedValues)(vehicle.consumption.nedcConsumptionCombined, vehicle.consumption.wltpConsumptionCombined) || '-';
        },
        font: infoFont
      }),
      emissionsEnabled && translations.compareVehiclesEnergyEfficiency && _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesEnergyEfficiency,
        comparedVehicles: comparedVehicles,
        font: infoFont,
        displayFunc: function displayFunc(vehicle) {
          return displayEmissionsEfficiency(vehicle);
        }
      }),
      _react2.default.createElement(_Shared.DataRow, {
        label: translations.compareVehiclesLocation,
        comparedVehicles: comparedVehicles,
        keyValues: ['location'],
        font: infoFont
      }),
      showOptionalExtras && _react2.default.createElement(
        _react.Fragment,
        null,
        equipmentMetaData && equipmentMetaData.length && _react2.default.createElement(_OptionalExtrasRow2.default, {
          label: translations.compareVehiclesOptionalExtras,
          comparedVehicles: comparedVehicles,
          optionalExtras: optionalExtras,
          toggleOptionalExtras: toggleOptionalExtras,
          'aria-expanded': optionalExtras,
          font: infoFont,
          optionalIconColourFont: optionalIconColourFont,
          optionalExtrasBackgroundColour: optionalExtrasBackgroundColour,
          equipmentMetaData: equipmentMetaData,
          translations: translations
        }),
        optionalExtras && equipmentMetaData.map(function (equipment, idx) {
          return _react2.default.createElement(_EquipmentMetaDataRow2.default, {
            key: '' + equipment,
            label: equipment,
            comparedVehicles: comparedVehicles,
            keyValues: ['performance', 'fuelConsumption'],
            visible: gridConfiguration && gridConfiguration.showFuelConsumption,
            borderBottom: idx === equipmentMetaData.length - 1,
            font: infoFont,
            globalStyling: globalStyling,
            iconColour: typeof phoneIconColour === 'string' ? phoneIconColour : phoneIconColour.value,
            optionalIconColourFont: optionalIconColourFont
          });
        })
      ),
      _react2.default.createElement(_RetailerPhoneRow2.default, {
        comparedVehicles: comparedVehicles,
        globalStyling: globalStyling,
        phoneIconColour: typeof phoneIconColour === 'string' ? phoneIconColour : phoneIconColour.value,
        font: infoFont,
        optionalIconFont: optionalIconFont,
        optionalIconColourFont: optionalIconColourFont,
        optionalHeadingThreeFont: optionalHeadingThreeFont,
        hideBottomBorder: hideBottomBorder
      }),
      !hideContactButton && _react2.default.createElement(_MoreInfoRow2.default, {
        requestInformation: requestInformation,
        comparedVehicles: comparedVehicles,
        buttonLabel: translations.compareVehiclesRequestInfo,
        buttonStyle: buttonStyle,
        buttonSize: buttonSize,
        font: infoFont,
        onMoreInfoClick: onMoreInfoClick,
        ctaToVdp: ctaToVdp,
        hideBottomBorder: hideBottomBorder
      }),
      _react2.default.createElement(_ShortlistRow2.default, {
        comparedVehicles: comparedVehicles,
        shortlistVehicle: shortlistVehicle,
        getShortListedIconColour: getShortListedIconColour,
        translations: translations,
        globalStyling: globalStyling,
        font: infoFont,
        optionalIconFont: optionalIconFont,
        optionalIconColourFont: optionalIconColourFont,
        optionalHeadingThreeFont: optionalHeadingThreeFont,
        hideBottomBorder: hideBottomBorder
      })
    )
  ) : _react2.default.createElement(
    'div',
    { style: { margin: '16px 16px 0' } },
    translations.compareVehiclesHeader && _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: ' + (headerFont ? headerFont.fontSize + 'px' : '26px') + ';\n        text-transform: ' + (headerFont ? '' + headerFont.textTransform : 'uppercase;') + '\n        text-align: ' + (headerFont ? '' + headerFont.align : 'center;') + ';\n        flex: 1 0 0%;\n        letter-spacing: ' + (headerFont ? headerFont.kerning + 'px' : '3px;') + ';\n      ';
        }
      },
      translations.compareVehiclesHeader
    ),
    props.config.emptyIcon && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        EmptyContainer,
        null,
        _react2.default.createElement(_StyledIcon2.default, { link: props.config.emptyIcon, width: 100, height: 100 }),
        _react2.default.createElement(
          _Global.HeadingTwo,
          {
            styleOverride: function styleOverride() {
              return '\n                margin-top: 40px;\n                font-size: 18px;\n                text-align: center;\n                ' + (0, _Global.fontStyleOverride)(paragraphFont) + '\n              ';
            }
          },
          translations.compareVehiclesSubHeader
        )
      )
    )
  );
}