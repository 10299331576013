'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    width: 54px;\n    bottom: ', ';\n    margin: 0 10px;\n    margin-left: auto;\n    gap: 10px;\n  '], ['\n    width: 54px;\n    bottom: ', ';\n    margin: 0 10px;\n    margin-left: auto;\n    gap: 10px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    width: 54px;\n    height: 54px;\n\n    :first-child {\n      margin-top: 100px;\n    }\n  '], ['\n    width: 54px;\n    height: 54px;\n\n    :first-child {\n      margin-top: 100px;\n    }\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _vehicleDetailsPageTypes = require('../../../types/Genesis/vehicleDetailsPageTypes');

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var StickyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleStickyFooter__StickyContainer',
  componentId: 'sc-adedvl-0'
})(['position:fixed;bottom:', ';right:0;background-color:transparent;display:flex;flex-direction:column;gap:20px;margin:0 20px;margin-left:auto;width:60px;height:0;', ';'], function (_ref) {
  var singleButtonVisible = _ref.singleButtonVisible;
  return singleButtonVisible ? '130px' : '210px';
}, _RollsRoyce2.default.max.large(_templateObject, function (_ref2) {
  var singleButtonVisible = _ref2.singleButtonVisible;
  return singleButtonVisible ? '166px' : '230px';
}));

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'VehicleStickyFooter__IconButton',
  componentId: 'sc-adedvl-1'
})(['background-color:transparent;border:none;cursor:pointer;padding:0;margin:0;width:60px;height:60px;display:flex;justify-content:center;align-items:center;:first-child{margin-top:50px;}', ';'], _RollsRoyce2.default.max.large(_templateObject2));

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'VehicleStickyFooter__Icon',
  componentId: 'sc-adedvl-2'
})(['width:100%;height:100%;object-fit:contain;']);

var VehicleStickyFooter = function VehicleStickyFooter(_ref3) {
  var config = _ref3.config,
      onEnquireClick = _ref3.onEnquireClick,
      onPhoneClick = _ref3.onPhoneClick;

  var singleButtonVisible = config.hideEnquire || config.hidePhone;
  return _react2.default.createElement(
    StickyContainer,
    { singleButtonVisible: singleButtonVisible },
    !config.hideEnquire && _react2.default.createElement(
      IconButton,
      { onClick: onEnquireClick },
      _react2.default.createElement(Icon, {
        alt: config.translations.stickyEnquireAlt,
        src: config.stickyEnquireIcon,
        'data-ds': 'sticky-enquiry-icon'
      })
    ),
    !config.hidePhone && _react2.default.createElement(
      IconButton,
      { onClick: onPhoneClick },
      _react2.default.createElement(Icon, {
        alt: config.translations.stickyPhoneAlt,
        src: config.stickyPhoneIcon,
        'data-ds': 'sticky-enquiry-phone'
      })
    )
  );
};

exports.default = VehicleStickyFooter;