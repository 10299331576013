'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = CompareVehicles;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _Global = require('../../Global');

var _numbers = require('../../../shared/localisation/numbers');

var _StyledIcon = require('../../StyledIcon');

var _StyledIcon2 = _interopRequireDefault(_StyledIcon);

var _comparePageTypes = require('../../../types/AstonMartin/comparePageTypes');

var _TableElements = require('./TableElements');

var _RetailerPhoneRow = require('./RetailerPhoneRow');

var _RetailerPhoneRow2 = _interopRequireDefault(_RetailerPhoneRow);

var _BookTestDriveRow = require('./BookTestDriveRow');

var _BookTestDriveRow2 = _interopRequireDefault(_BookTestDriveRow);

var _ShortlistRow = require('./ShortlistRow');

var _ShortlistRow2 = _interopRequireDefault(_ShortlistRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.table.withConfig({
  displayName: 'CompareVehiclesContainer'
}).withConfig({
  displayName: 'CompareVehicles__Container',
  componentId: 'sc-1ap0mnq-0'
})(['width:95%;height:auto;margin:0 2.5% 5% 2.5%;border-collapse:separate;border-spacing:20px 0;padding-top:30px;']);

var ImageContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__ImageContainer',
  componentId: 'sc-1ap0mnq-1'
})(['cursor:pointer;position:relative;']);

var StyledImage = _styledComponents2.default.img.withConfig({
  displayName: 'CompareVehicles__StyledImage',
  componentId: 'sc-1ap0mnq-2'
})(['width:100%;']);

var IconButton = _styledComponents2.default.button.withConfig({
  displayName: 'IconButton'
}).withConfig({
  displayName: 'CompareVehicles__IconButton',
  componentId: 'sc-1ap0mnq-3'
})(['background:none;border:none;cursor:pointer;padding:0;margin-bottom:10px;&:focus{outline:none;}']);

var ImageOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'ImageOverlay'
}).withConfig({
  displayName: 'CompareVehicles__ImageOverlay',
  componentId: 'sc-1ap0mnq-4'
})(['justify-content:center;align-items:center;display:flex;flex-direction:column;position:absolute;top:0;left:0;right:0;bottom:0;color:#ffffff;background-color:rgb(0,0,0,0.5);cursor:default;']);

var SoldHeading = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldHeading',
  componentId: 'sc-1ap0mnq-5'
})(['font-size:20px;font-weight:600;']);

var SoldBody = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__SoldBody',
  componentId: 'sc-1ap0mnq-6'
})(['font-size:14px;max-width:165px;']);

var EmptyContainer = _styledComponents2.default.div.withConfig({
  displayName: 'CompareVehicles__EmptyContainer',
  componentId: 'sc-1ap0mnq-7'
})(['display:flex;flex-direction:column;align-items:center;padding:72px 0 280px 0;justify-content:center;']);

var hasDataToDisplayInRow = function hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc) {
  return [0, 1, 2].some(function (n) {
    return keyValues ? (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]);
  });
};

var DataRow = function DataRow(_ref) {
  var label = _ref.label,
      comparedVehicles = _ref.comparedVehicles,
      keyValues = _ref.keyValues,
      displayFunc = _ref.displayFunc,
      disclaimerFunc = _ref.disclaimerFunc;

  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc)) {
    return _react2.default.createElement('tr', { style: { border: 'none' } });
  }

  return _react2.default.createElement(
    _TableElements.TableRow,
    null,
    _react2.default.createElement(
      _TableElements.TableDataFirstColumn,
      null,
      _react2.default.createElement(_TableElements.RenderRow, { content: label })
    ),
    [0, 1, 2].map(function (n) {
      return comparedVehicles[n] ? _react2.default.createElement(
        _TableElements.TableData,
        {
          key: '' + comparedVehicles[n].id,
          priceRow: comparedVehicles[n].price.previousValue
        },
        _react2.default.createElement(_TableElements.RenderRow, {
          content: keyValues ? keyValues && (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]) || '-',
          disclaimer: disclaimerFunc && disclaimerFunc(comparedVehicles[n])
        })
      ) : _react2.default.createElement('td', { key: (0, _v2.default)() });
    })
  );
};

DataRow.defaultProps = {
  borderBottom: false
};

function CompareVehicles(props) {
  var requestInformation = props.requestInformation,
      _props$comparedVehicl = props.comparedVehicles,
      comparedVehicles = _props$comparedVehicl === undefined ? [] : _props$comparedVehicl,
      removeVehicle = props.removeVehicle,
      gridConfiguration = props.gridConfiguration,
      currentLanguage = props.currentLanguage,
      onMoreInfoClick = props.onMoreInfoClick,
      translations = props.translations,
      ctaToVdp = props.ctaToVdp,
      config = props.config;


  var localisePrice = function localisePrice(vehicle) {
    var price = vehicle.price.originalValue || vehicle.price.value;

    if (price === 0) {
      return translations.zeroPriceText;
    }

    return (0, _numbers.localiseCurrency)(price, currentLanguage, vehicle.price.originalCurrency || vehicle.price.currency, 0);
  };

  var handlePrice = function handlePrice(vehicle) {
    return localisePrice(vehicle);
  };

  return comparedVehicles.length > 0 ? _react2.default.createElement(
    Container,
    null,
    _react2.default.createElement(
      'tbody',
      null,
      _react2.default.createElement(
        _TableElements.TableRow,
        null,
        _react2.default.createElement('td', null),
        _react2.default.createElement(
          'td',
          null,
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n                font-family: AstonMartinFlare-Regular;\n                font-size: 14px;\n                line-height: 22px;\n                text-transform: uppercase;\n              ';
              }
            },
            translations.sectionTitle
          )
        ),
        _react2.default.createElement('td', null),
        _react2.default.createElement('td', null)
      ),
      _react2.default.createElement(
        _TableElements.TableRow,
        null,
        _react2.default.createElement('td', null),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              'div',
              { style: { display: 'flex', justifyContent: 'flex-end' } },
              _react2.default.createElement(
                IconButton,
                { onClick: function onClick() {
                    return removeVehicle(vehicle);
                  } },
                _react2.default.createElement('img', {
                  src: config.closeIcon,
                  width: '12px',
                  height: '12px',
                  alt: ''
                })
              )
            )
          );
        })
      ),
      _react2.default.createElement(
        _TableElements.TableRow,
        null,
        _react2.default.createElement(
          'td',
          {
            style: {
              width: '25%',
              background: 'transparent'
            }
          },
          _react2.default.createElement(
            _Global.Paragraph,
            {
              styleOverride: function styleOverride() {
                return '\n                font-family: AstonMartinFlare-Regular;\n                font-size: 18px;\n                line-height: 25px;\n                text-transform: uppercase;\n                text-align: center;\n              ';
              }
            },
            translations.subHeader
          )
        ),
        comparedVehicles.map(function (vehicle) {
          return _react2.default.createElement(
            'td',
            { key: '' + vehicle.id, style: { width: '25%' } },
            _react2.default.createElement(
              ImageContainer,
              {
                onClick: function onClick() {
                  return vehicle.status !== 'sold' && onMoreInfoClick(vehicle);
                }
              },
              vehicle.images.length > 0 ? _react2.default.createElement(StyledImage, {
                src: vehicle.images[0],
                width: '100%',
                alt: vehicle.name
              }) : _react2.default.createElement(StyledImage, {
                src: 'https://res.cloudinary.com/motortrak/image/upload/v1536583771/Placeholder%20Images/PlaceholderJAG.png',
                alt: 'placeholderImage'
              }),
              vehicle.status === 'sold' || vehicle.priceSpecial === 'SOLD' && translations.sold && _react2.default.createElement(
                ImageOverlay,
                null,
                _react2.default.createElement(
                  SoldHeading,
                  null,
                  translations.sold
                ),
                _react2.default.createElement(
                  SoldBody,
                  null,
                  translations.similarVehiclesAvailable
                )
              )
            )
          );
        })
      ),
      _react2.default.createElement(DataRow, {
        label: translations.comparePrice,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return '' + handlePrice(vehicle);
        },
        currentLanguage: currentLanguage,
        translations: translations,
        disclaimerFunc: function disclaimerFunc(vehicle) {
          return vehicle.sectionSalesTaxDisclaimer === 1 ? translations.differentialTaxation : null;
        }
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesModelYear,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return vehicle.name;
        }
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesExterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['exterior']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesInterior,
        comparedVehicles: comparedVehicles,
        keyValues: ['interior']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesVeneer,
        comparedVehicles: comparedVehicles,
        keyValues: ['veneer']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesOdometer,
        comparedVehicles: comparedVehicles,
        keyValues: ['odometer', 'display'],
        visible: gridConfiguration && gridConfiguration.showOdometer,
        zeroMileageText: translations.zeroMileageText
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesTransmission,
        comparedVehicles: comparedVehicles,
        keyValues: ['transmission']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesEmissions,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'emissions']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesFuelConsumption,
        comparedVehicles: comparedVehicles,
        keyValues: ['performance', 'fuelConsumption'],
        visible: gridConfiguration && gridConfiguration.showFuelConsumption
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesBodystyle,
        comparedVehicles: comparedVehicles,
        keyValues: ['bodystyle']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesCapacity,
        comparedVehicles: comparedVehicles,
        displayFunc: function displayFunc(vehicle) {
          return (vehicle.engineCapacity ? (0, _numbers.localiseNumber)(vehicle.engineCapacity, currentLanguage.replace('_', '-')) : '-') + ' ' + (vehicle.engineCapacity && translations.ccUnit ? translations.ccUnit : '');
        }
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesFuelType,
        comparedVehicles: comparedVehicles,
        keyValues: ['fuel']
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesRegistration,
        comparedVehicles: comparedVehicles,
        keyValues: ['modelYear'],
        visible: gridConfiguration && gridConfiguration.showRegistrationYear
      }),
      _react2.default.createElement(DataRow, {
        label: translations.compareVehiclesLocation,
        comparedVehicles: comparedVehicles,
        keyValues: ['location']
      }),
      _react2.default.createElement(_RetailerPhoneRow2.default, { comparedVehicles: comparedVehicles, config: config }),
      _react2.default.createElement(_BookTestDriveRow2.default, {
        requestInformation: requestInformation,
        comparedVehicles: comparedVehicles,
        buttonLabel: translations.bookTestDrive,
        onMoreInfoClick: onMoreInfoClick,
        ctaToVdp: ctaToVdp
      }),
      _react2.default.createElement(_ShortlistRow2.default, {
        comparedVehicles: comparedVehicles,
        iconOn: config.iconShortlistOn,
        iconOff: config.iconShortlistOff
      })
    )
  ) : _react2.default.createElement(
    'div',
    { style: { margin: '16px 16px 0' } },
    translations.compareVehiclesHeader && _react2.default.createElement(
      _Global.HeadingOne,
      {
        styleOverride: function styleOverride() {
          return '\n        font-size: 26px;\n        text-transform: uppercase;\n        text-align: center;\n        flex: 1 0 0%;\n        letter-spacing: 3px;\n      ';
        }
      },
      translations.compareVehiclesHeader
    ),
    _react2.default.createElement(
      EmptyContainer,
      null,
      _react2.default.createElement(_StyledIcon2.default, { link: props.config.emptyIcon, width: 100, height: 100 }),
      _react2.default.createElement(
        _Global.HeadingTwo,
        {
          styleOverride: function styleOverride() {
            return '\n                margin-top: 40px;\n                font-size: 18px;\n                text-align: center;\n              ';
          }
        },
        translations.subHeader
      )
    )
  );
}