'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _v = require('uuid/v1');

var _v2 = _interopRequireDefault(_v);

var _ComparedVehicleType = require('../../../../types/ComparedVehicleType');

var _RenderRow = require('./RenderRow');

var _RenderRow2 = _interopRequireDefault(_RenderRow);

var _TableData = require('./TableData');

var _TableData2 = _interopRequireDefault(_TableData);

var _TableRow = require('./TableRow');

var _TableRow2 = _interopRequireDefault(_TableRow);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var hasDataToDisplayInRow = function hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc) {
  return [0, 1, 2].some(function (n) {
    return keyValues ? (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]);
  });
};

var DataRow = function DataRow(_ref) {
  var label = _ref.label,
      comparedVehicles = _ref.comparedVehicles,
      disclaimerFunc = _ref.disclaimerFunc,
      keyValues = _ref.keyValues,
      font = _ref.font,
      _ref$visible = _ref.visible,
      visible = _ref$visible === undefined ? true : _ref$visible,
      displayFunc = _ref.displayFunc,
      unit = _ref.unit;

  if (!hasDataToDisplayInRow(comparedVehicles, keyValues, displayFunc)) {
    return _react2.default.createElement('tr', { style: { border: 'none' } });
  }

  return visible ? _react2.default.createElement(
    _TableRow2.default,
    { fontSize: font && font.fontSize },
    _react2.default.createElement(
      _TableData2.default,
      null,
      _react2.default.createElement(_RenderRow2.default, { content: label, font: font })
    ),
    [0, 1, 2].map(function (n) {
      return comparedVehicles[n] ? _react2.default.createElement(
        _TableData2.default,
        {
          key: '' + comparedVehicles[n].id,
          priceRow: comparedVehicles[n].price.previousValue
        },
        _react2.default.createElement(_RenderRow2.default, {
          content: keyValues ? keyValues && (0, _path2.default)(keyValues, comparedVehicles[n]) : displayFunc(comparedVehicles[n]) || '-',
          font: font,
          unit: unit,
          disclaimer: disclaimerFunc && disclaimerFunc(comparedVehicles[n])
        })
      ) : _react2.default.createElement('td', { key: (0, _v2.default)() });
    })
  ) : null;
};

DataRow.defaultProps = {
  borderBottom: false
};

exports.default = DataRow;