'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    gap: 24px;\n    padding-bottom: 40px;\n  '], ['\n    gap: 24px;\n    padding-bottom: 40px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 16px;\n    align-items: center;\n    border-bottom: 1px solid #3b3b3b;\n    flex-direction: row;\n    margin-bottom: 48px;\n    padding-bottom: 0;\n  '], ['\n    gap: 16px;\n    align-items: center;\n    border-bottom: 1px solid #3b3b3b;\n    flex-direction: row;\n    margin-bottom: 48px;\n    padding-bottom: 0;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 40px 0 32px;\n  '], ['\n    padding: 40px 0 32px;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 48px 0 32px;\n  '], ['\n    padding: 48px 0 32px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 1328px;\n  '], ['\n    max-width: 1328px;\n  ']);

exports.default = Footer;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _FooterNavigation = require('./FooterNavigation');

var _FooterNavigation2 = _interopRequireDefault(_FooterNavigation);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _footerTypes = require('../../../types/RollsRoyce/footerTypes');

var _IconRow = require('../IconRow');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var FooterContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__FooterContainer',
  componentId: 'sc-pl505s-0'
})(['align-items:flex-start;border-top:1px solid #3b3b3b;display:flex;flex-direction:column;gap:16px;height:auto;justify-content:space-between;padding-bottom:24px;', ';', ';'], _RollsRoyce2.default.min.medium(_templateObject), _RollsRoyce2.default.min.extraLarge(_templateObject2));

var FooterWrapper = _styledComponents2.default.footer.withConfig({
  displayName: 'Footer__FooterWrapper',
  componentId: 'sc-pl505s-1'
})(['background-color:#151515;border-top:1px solid #151515;box-sizing:border-box;display:flex;max-height:100%;padding:0 16px;width:100%;@media print{display:none;}']);

var CenterLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__CenterLogoContainer',
  componentId: 'sc-pl505s-2'
})(['align-items:center;display:flex;']);

var BrandLogo = _styledComponents2.default.img.withConfig({
  displayName: 'Footer__BrandLogo',
  componentId: 'sc-pl505s-3'
})(['', ';height:30.31px;width:120px;'], function (_ref) {
  var src = _ref.src;
  return src && 'cursor: pointer;';
});

var SectionWithMidLogoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__SectionWithMidLogoContainer',
  componentId: 'sc-pl505s-4'
})(['align-items:center;display:flex;flex-direction:column;padding:24px 0;', ';', ';'], _RollsRoyce2.default.min.medium(_templateObject3), _RollsRoyce2.default.min.extraLarge(_templateObject4));

var Wrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Footer__Wrapper',
  componentId: 'sc-pl505s-5'
})(['min-width:1px;margin:0px auto;width:100%;', ';'], _RollsRoyce2.default.min.extraLarge(_templateObject5));

function Footer(_ref2) {
  var config = _ref2.config;
  var translations = config.translations;

  var defaultSocialIconHeight = 18;
  var defaultSocialIconWidth = 18;

  var socialIcons = [{
    ariaLabel: translations.youtubeAriaLabel,
    height: 21,
    href: config.urlYouTube,
    src: config.youtubeIconLink,
    testId: 'sml-youtube',
    width: 23
  }, {
    ariaLabel: translations.facebookAriaLabel,
    height: defaultSocialIconHeight,
    href: config.urlFacebook,
    src: config.facebookIconLink,
    testId: 'sml-facebook',
    width: defaultSocialIconWidth
  }, {
    ariaLabel: translations.instagramAriaLabel,
    height: defaultSocialIconHeight,
    href: config.urlInstagram,
    src: config.instagramIconLink,
    testId: 'sml-instagram',
    width: defaultSocialIconWidth
  }, {
    ariaLabel: translations.linkedinAriaLabel,
    height: defaultSocialIconHeight,
    href: config.urlLinkedIn,
    src: config.linkedinIconLink,
    testId: 'sml-linkedin',
    width: defaultSocialIconWidth
  }, {
    ariaLabel: translations.twitterAriaLabel,
    height: 22,
    href: config.urlTwitter,
    src: config.twitterIconLink,
    testId: 'sml-twitter',
    width: 22
  }];

  var filteredSocialIcons = socialIcons.filter(function (_ref3) {
    var href = _ref3.href;
    return !!href;
  });
  var hasSocialIcons = filteredSocialIcons.length > 0;

  return _react2.default.createElement(
    FooterWrapper,
    { 'data-nosnippet': true },
    _react2.default.createElement(
      Wrapper,
      null,
      _react2.default.createElement(
        SectionWithMidLogoContainer,
        null,
        _react2.default.createElement(
          CenterLogoContainer,
          null,
          _react2.default.createElement(BrandLogo, {
            'data-cy': 'footer-logo',
            src: config.brandLogoUrl || '',
            alt: 'logo'
          })
        )
      ),
      _react2.default.createElement(
        FooterContainer,
        null,
        _react2.default.createElement(_FooterNavigation2.default, {
          items: config.items.map(function (value) {
            return (0, _extends3.default)({}, value, {
              label: config.translations[value.id]
            });
          }).filter(function (item) {
            return item.link;
          }),
          itemsConfig: config.itemsConfig
        }),
        hasSocialIcons && _react2.default.createElement(_IconRow.IconRow, { icons: filteredSocialIcons })
      )
    )
  );
}