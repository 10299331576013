'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 16px repeat(4, 1fr 16px) 1fr 16px;\n  '], ['\n    grid-template-columns: 16px repeat(4, 1fr 16px) 1fr 16px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    grid-column: 2/span 9;\n  '], ['\n    grid-column: 2/span 9;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: space-between;\n  '], ['\n    justify-content: space-between;\n  ']);

var _history = require('history');

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _RollsRoyce = require('../../../customThemes/RollsRoyce');

var _RollsRoyce2 = _interopRequireDefault(_RollsRoyce);

var _actions = require('../../../actions');

var _router = require('../../../actions/router');

var _compare = require('../../../shared/compare');

var _printing = require('../../../shared/printing');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _shortlist = require('../../../shared/shortlist');

var _converters = require('../../../helpers/converters');

var _converters2 = _interopRequireDefault(_converters);

var _rollsRoyceScrollTransition = require('../../../helpers/rollsRoyceScrollTransition');

var _rollsRoyceScrollTransition2 = _interopRequireDefault(_rollsRoyceScrollTransition);

var _LanguagePicklist = require('../../../components/RollsRoyce/LanguagePicklist');

var _LanguagePicklist2 = _interopRequireDefault(_LanguagePicklist);

var _VDPActions = require('./VDPActions');

var _VDPActions2 = _interopRequireDefault(_VDPActions);

var _AppState = require('../../../types/AppState');

var _types = require('./types');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var SubNavBarContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavBarContainer',
  componentId: 'sc-1jr5gcp-0'
})(['display:grid;position:fixed;z-index:10;width:100%;top:', 'px;align-items:center;transition:top 0.3s;background-color:#f2f2f2;height:60px;justify-content:center;margin:0 auto;grid-template-columns:16px 2fr 16px repeat(12,96px 16px) 2fr 16px;grid-template-rows:60px auto auto;', ' ', ';@media only screen and (min-width:1024px){border-bottom:none;height:60px;}@media print{display:none;}'], function (props) {
  return (0, _rollsRoyceScrollTransition2.default)(props.scrollTop, 120, 60);
}, _RollsRoyce2.default.max.large(_templateObject), function (_ref) {
  var isVdpBanner = _ref.isVdpBanner;
  return isVdpBanner && '&& {\n      width: 100%;\n      display: flex;\n      position: relative;\n      top: 0;\n    }\n    ';
});

var Content = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Content',
  componentId: 'sc-1jr5gcp-1'
})(['box-sizing:border-box;display:flex;font-family:\'RivieraNights-Medium\';font-size:12px;text-transform:uppercase;letter-spacing:2px;width:100%;margin-top:6px;grid-column:4 / span 23;', ' ', ';', ''], _RollsRoyce2.default.max.large(_templateObject2), _RollsRoyce2.default.max.medium(_templateObject3), function (_ref2) {
  var isVdpBanner = _ref2.isVdpBanner;
  return isVdpBanner && '&&& {padding: 0px; max-width: 100%;}';
});

var SubNavLink = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__SubNavLink',
  componentId: 'sc-1jr5gcp-2'
})(['align-items:center;color:#222;cursor:pointer;display:flex;padding-left:32px;transition:color 0.2s linear;font-size:12px;line-height:24px;&:hover{opacity:0.7;}']);

var LinksContainer = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__LinksContainer',
  componentId: 'sc-1jr5gcp-3'
})(['display:flex;']);

var Button = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__Button',
  componentId: 'sc-1jr5gcp-4'
})(['align-items:center;color:#222;cursor:pointer;display:flex;transition:color 0.2s linear;&:hover,&:active{opacity:0.7;}img{padding-right:10px;}']);

var DesktopLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__DesktopLinks',
  componentId: 'sc-1jr5gcp-5'
})(['display:none;overflow:hidden;width:100%;@media only screen and (min-width:1024px){display:flex;}']);

var MobileLinks = _styledComponents2.default.div.withConfig({
  displayName: 'SubNavBar__MobileLinks',
  componentId: 'sc-1jr5gcp-6'
})(['display:flex;overflow:hidden;@media only screen and (min-width:1024px){display:none;}']);

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'SubNavBar__Icon',
  componentId: 'sc-1jr5gcp-7'
})(['margin-left:12px;margin-right:12px;height:16px;']);

var CompareCounter = _styledComponents2.default.span.withConfig({
  displayName: 'SubNavBar__CompareCounter',
  componentId: 'sc-1jr5gcp-8'
})(['margin-left:3px;min-width:12px;text-align:right;']);

var AvailableLinks = function AvailableLinks(_ref3) {
  var links = _ref3.links;

  var dispatch = (0, _reactRedux.useDispatch)();
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var vehicleCounters = {
    compare: _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(
        CompareCounter,
        null,
        shared.compare.vehicles.length
      ),
      '/3'
    ),
    shortlist: shared.shortlist.vehicles.length
  };

  return _react2.default.createElement(
    LinksContainer,
    null,
    links && links.map(function (link, index) {
      return _react2.default.createElement(
        SubNavLink,
        {
          key: index.toString(),
          onClick: function onClick() {
            return dispatch(_router.actions.navigate(link.path));
          }
        },
        link.translations.linkText,
        link.key === 'shortlist' ? _react2.default.createElement(Icon, {
          alt: 'View shortlist',
          src: 'https://res.cloudinary.com/motortrak/image/upload/v1721314637/locator/rolls-royce/global/icons/shortlist.svg'
        }) : ' ',
        vehicleCounters[link.key] && vehicleCounters[link.key]
      );
    })
  );
};

var SubNavBar = function SubNavBar(_ref4) {
  var config = _ref4.config,
      history = _ref4.history,
      isVdpBanner = _ref4.isVdpBanner,
      navigateToEnquiryForm = _ref4.navigateToEnquiryForm;

  var dispatch = (0, _reactRedux.useDispatch)();
  var shared = (0, _reactRedux.useSelector)(function (state) {
    return state.shared;
  });
  var currentLanguage = shared.sessionPreferences.language;
  var links = config.items;
  var mobileLinks = links && links.filter(function (f) {
    return f.path !== '/compare';
  });

  var _useState = (0, _react.useState)(0),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      scrollTop = _useState2[0],
      setScrollTop = _useState2[1];

  var onScroll = function onScroll() {
    return setScrollTop(document.documentElement.scrollTop);
  };

  var _useSelector = (0, _reactRedux.useSelector)(function (state) {
    return state;
  }),
      _useSelector$shared = _useSelector.shared,
      shortlist = _useSelector$shared.shortlist,
      compare = _useSelector$shared.compare,
      printing = _useSelector$shared.printing,
      socialShare = _useSelector.config.config.global.socialShare;

  var changeLanguage = function changeLanguage(option) {
    if (option) {
      dispatch(_actions.language.actions.changeLanguage(option.value));
      dispatch(_sessionPreferences.actions.updateSessionPreferences('language', option.value));
    }
  };

  var languageOptions = config.languageOptions && config.languageOptions.find(function (op) {
    return op.key === 'Language';
  }).options;

  var selectedLanguage = Array.isArray(languageOptions) && languageOptions.length > 0 ? languageOptions.find(function (lo) {
    return lo.value === currentLanguage;
  }) || '' : '';

  var prevPath = (0, _reactRedux.useSelector)(function (state) {
    return state.router.history && state.router.navigationHistory[1];
  });

  var _useSelector2 = (0, _reactRedux.useSelector)(function (state) {
    var vdp = state.pages['/vdp'];
    var vdpKeys = vdp && (0, _keys2.default)(vdp);
    if (!vdpKeys || !vdp) {
      return {};
    } else {
      var key = vdpKeys.find(function (key) {
        return vdp[key].vdpConfig !== undefined;
      });

      return vdp[key] || {};
    }
  }),
      vdpConfig = _useSelector2.vdpConfig;

  var path = !config.goBackPathname ? prevPath ? undefined : config.defaultGoBackPathname : config.goBackPathname === '/' ? '/' : '/' + _actions.language + config.goBackPathname;

  var addToShortlist = function addToShortlist() {
    dispatch(_shortlist.actions.toggleVehicle((0, _converters2.default)(vdpConfig)));
  };
  var addToCompare = function addToCompare() {
    dispatch(_compare.actions.toggleVehicle((0, _converters2.default)(vdpConfig)));
  };

  var print = function print() {
    dispatch(_printing.actions.printVehicleInfo(vdpConfig, config.translations, config));
  };

  // add scroll event listener
  (0, _react.useEffect)(function () {
    window.addEventListener('scroll', onScroll);

    return function () {
      return window.removeEventListener('scroll', onScroll);
    };
  }, []);
  return _react2.default.createElement(
    SubNavBarContainer,
    { scrollTop: scrollTop, isVdpBanner: isVdpBanner },
    _react2.default.createElement(
      Content,
      { isVdpBanner: isVdpBanner },
      !config.hideBackButton && _react2.default.createElement(
        Button,
        {
          onClick: function onClick() {
            path ? history.push(path) : history.goBack();
          }
        },
        _react2.default.createElement('img', {
          src: config.goBackIconUrl,
          alt: config.translations.backButtonText,
          style: { position: 'relative', top: '-1px' }
        }),
        prevPath ? config.translations.backButtonText : config.translations.defaultBackButtonText
      ),
      languageOptions && languageOptions.length > 0 && _react2.default.createElement(_LanguagePicklist2.default, {
        font: '',
        dataCy: 'subnav-language-selector',
        value: selectedLanguage && selectedLanguage.value,
        onChange: function onChange(event) {
          return changeLanguage(languageOptions.find(function (l) {
            return l.value === event.target.value;
          }));
        },
        options: languageOptions
      }),
      _react2.default.createElement(
        DesktopLinks,
        null,
        _react2.default.createElement(AvailableLinks, { links: links }),
        config.enableVDPActions && vdpConfig && _react2.default.createElement(_VDPActions2.default, {
          isVehicleShortlisted: shortlist.vehicles.some(function (v) {
            return v.id === vdpConfig.id;
          }),
          isVehicleCompared: compare.vehicles.some(function (v) {
            return v.id === vdpConfig.id;
          }),
          config: config,
          onAddToCompare: addToCompare,
          onAddToShortlist: addToShortlist,
          onPrint: print,
          socialShare: socialShare,
          printing: printing.isPrinting,
          enableEnquire: isVdpBanner && !config.hideEnquire,
          onEnquire: navigateToEnquiryForm
        })
      ),
      _react2.default.createElement(
        MobileLinks,
        null,
        _react2.default.createElement(AvailableLinks, { links: mobileLinks })
      )
    )
  );
};

exports.default = SubNavBar;