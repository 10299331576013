'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VDP = undefined;

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 20px 16px;\n  '], ['\n    padding: 20px 16px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n  grid-template-columns: 1fr 1fr;\n  '], ['\n  grid-template-columns: 1fr 1fr;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  '], ['\n    grid-template-columns: 1fr;\n    width: 100%;\n    margin: 0;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n  '], ['\n    ', '\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    display:none;\n  '], ['\n    display:none;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    display:block;\n  '], ['\n    display:block;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactHelmet = require('react-helmet');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _router = require('../../../actions/router');

var _ContentWrapper = require('../../../components/ContentWrapper');

var _ContentWrapper2 = _interopRequireDefault(_ContentWrapper);

var _VdpEmissions = require('../../../components/Lamborghini/Vdp/VdpEmissions');

var _VdpEmissions2 = _interopRequireDefault(_VdpEmissions);

var _VehicleEfficiencyClass = require('../../../components/Lamborghini/Vdp/VehicleEfficiencyClass');

var _VehicleEfficiencyClass2 = _interopRequireDefault(_VehicleEfficiencyClass);

var _VdpAccessories = require('../../../components/VdpAccessories');

var _VdpAccessories2 = _interopRequireDefault(_VdpAccessories);

var _VdpAccordion = require('../../../components/VdpAccordion');

var _VdpAccordion2 = _interopRequireDefault(_VdpAccordion);

var _VdpAdditionalInfo = require('../../../components/VdpAdditionalInfo');

var _VdpAdditionalInfo2 = _interopRequireDefault(_VdpAdditionalInfo);

var _VdpGalleryAndMyDeal = require('../../../components/VdpGalleryAndMyDeal');

var _VdpGalleryAndMyDeal2 = _interopRequireDefault(_VdpGalleryAndMyDeal);

var _VdpRetailerMap = require('../../../components/VdpRetailerMap');

var _VdpRetailerMap2 = _interopRequireDefault(_VdpRetailerMap);

var _converters = require('../../../helpers/converters');

var _converters2 = _interopRequireDefault(_converters);

var _vehicle = require('../../../helpers/vehicle');

var _useCheckIsMobileScreen = require('../../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _pathBuilders = require('../../../path-builders');

var _compare = require('../../../shared/compare');

var _filters = require('../../../shared/filters');

var _lamborghiniFinanceCalc = require('../../../shared/lamborghiniFinanceCalc');

var _ipInformation = require('../../../shared/localisation/ipInformation');

var _searchFilter = require('../../../shared/searchFilter');

var _vehicleStore = require('../../../shared/selectors/vehicleStore');

var _sessionPreferences = require('../../../shared/sessionPreferences');

var _shortlist = require('../../../shared/shortlist');

var _vehicleValuations = require('../../../shared/vehicleValuations');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _AppShared = require('../../../types/AppShared');

var _EmissionType = require('../../../types/EmissionType');

var _FeatureFlagsType = require('../../../types/FeatureFlagsType');

var _vehicleDetailsTypes = require('../../../types/Lamborghini/vehicleDetailsTypes');

var _MarketInfo = require('../../../types/MarketInfo');

var _RouterType = require('../../../types/RouterType');

var _globalStyling = require('../../../types/globalStyling');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Section = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Section',
  componentId: 'sc-i6mn4n-0'
})(['display:flex;flex-direction:column;max-width:', ';background:', ';padding:40px 110px;margin:0 auto;', ''], function (_ref) {
  var contentWidth = _ref.contentWidth;
  return contentWidth ? contentWidth + 'px;' : '100%';
}, function (_ref2) {
  var backGround = _ref2.backGround;
  return backGround + ';';
}, _theme2.default.max.large(_templateObject));

var SectionTitle = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__SectionTitle',
  componentId: 'sc-i6mn4n-1'
})(['color:', ';font-family:\'RobotoBold\';font-size:28px;line-height:29px;text-align:center;width:100%;text-transform:uppercase;margin-bottom:32px;'], function (_ref3) {
  var color = _ref3.color;
  return color + ';';
});

var Grid = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__Grid',
  componentId: 'sc-i6mn4n-2'
})(['display:grid;grid-template-columns:1fr 1fr 1fr;grid-gap:0 30px;', ' ', ''], _theme2.default.max.large(_templateObject2), _theme2.default.max.medium(_templateObject3));

var GridItem = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__GridItem',
  componentId: 'sc-i6mn4n-3'
})(['display:flex;flex-direction:row;justify-content:space-between;align-items:center;border-bottom:1px solid #dddddd;width:100%;padding:10px 0;color:#1f1f1f;font-family:\'Roboto\';font-style:normal;font-size:14px;line-height:16px;', ''], _theme2.default.max.small(_templateObject4, function (_ref4) {
  var center = _ref4.center;
  return center && 'justify-content: center;';
}));

var GridItemLabel = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__GridItemLabel',
  componentId: 'sc-i6mn4n-4'
})(['font-weight:700;padding-right:20px;']);

var GridItemValue = (0, _styledComponents2.default)(GridItemLabel).withConfig({
  displayName: 'VehicleDetails__GridItemValue',
  componentId: 'sc-i6mn4n-5'
})(['font-weight:400;', ''], function (_ref5) {
  var alignRight = _ref5.alignRight;
  return alignRight && 'text-align: right;';
});

var DesktopContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__DesktopContainer',
  componentId: 'sc-i6mn4n-6'
})(['display:block;', ';'], _theme2.default.max.large(_templateObject5));
var MobileContainer = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__MobileContainer',
  componentId: 'sc-i6mn4n-7'
})(['display:none;', ';'], _theme2.default.max.large(_templateObject6));
var GalleryWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'VehicleDetails__GalleryWrapper',
  componentId: 'sc-i6mn4n-8'
})(['margin-bottom:18px;padding:0 20px;']);

var AccordionTitle = _styledComponents2.default.span.withConfig({
  displayName: 'VehicleDetails__AccordionTitle',
  componentId: 'sc-i6mn4n-9'
})(['font-style:normal;font-weight:500;font-size:16px;line-height:19px;text-transform:uppercase;color:#1f1f1f;']);

var VDP = exports.VDP = function VDP(props) {
  var config = props.config,
      actions = props.actions,
      state = props.state,
      history = props.history,
      shared = props.shared,
      dispatch = props.dispatch,
      pathByModule = props.pathByModule,
      featureFlags = props.featureFlags,
      globalStyling = props.globalStyling,
      finance = props.finance,
      socialShare = props.socialShare,
      marketInfo = props.marketInfo,
      hashRouting = props.hashRouting;
  var _history$location = history.location,
      pathname = _history$location.pathname,
      hash = _history$location.hash;
  var vdpConfig = state.vdpConfig,
      placeholdingImage = state.placeholdingImage,
      accessories = state.accessories,
      accessoriesInView = state.accessoriesInView,
      accessoriesPage = state.accessoriesPage,
      accessoriesPageTotal = state.accessoriesPageTotal,
      selectedAccessoryMobile = state.selectedAccessoryMobile,
      accessoriesPageMobile = state.accessoriesPageMobile,
      accessoriesPageTotalMobile = state.accessoriesPageTotalMobile,
      context = state.context,
      printing = state.printing;
  var accessoriesNextMobile = actions.accessoriesNextMobile,
      accessoriesPrevMobile = actions.accessoriesPrevMobile,
      updateMyDealProduct = actions.updateMyDealProduct,
      updateMyDealWithPartExchange = actions.updateMyDealWithPartExchange,
      saveMyDeal = actions.saveMyDeal;
  var googleAvailable = shared.google.googleAvailable;

  var sharedShortListedVehicles = (0, _vehicleStore.getShortList)(featureFlags)(shared);
  var sharedCompareVehicles = (0, _vehicleStore.getCompareList)(featureFlags)(shared);
  var vehicleDetails = vdpConfig;
  var translations = config.translations;

  var language = marketInfo.locale;
  var iconColour = config.iconColour && config.iconColour.value;
  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');

  var isListed = function isListed(id, vehicles) {
    return vehicles.find(function (vehicle) {
      return vehicle.id === (id || {});
    });
  };

  var shortListedIconColour = isListed((vdpConfig || {}).id, sharedShortListedVehicles) ? globalStyling.colours.primaryBrandColour.value : '#ACACAC';

  var compareIconColour = isListed((vdpConfig || {}).id, sharedCompareVehicles) ? globalStyling.colours.primaryBrandColour.value : '#ACACAC';

  var goToModule = function goToModule(name, retailer) {
    var path = (0, _pathBuilders.pathWithDealerID)(pathByModule(name), retailer.name, config.specialDealerRedirection ? retailer.oemId : retailer.id, language, config.specialDealerRedirection ? featureFlags.dealerUrl : undefined);

    if (config.specialDealerRedirection) {
      window.open(window.location.origin + '/' + path, config.newTab);
    } else {
      dispatch(_router.actions.navigate(path));
    }
  };

  var goToModuleWithGroup = function goToModuleWithGroup(name, retailer) {
    return dispatch(_router.actions.navigate((0, _pathBuilders.pathWithDealerGroup)(pathByModule(name), retailer)));
  };

  var _bookTestDrive = function _bookTestDrive(name, id) {
    return dispatch(_router.actions.navigate(pathByModule(name) + '/' + id));
  };

  var resetFilters = function resetFilters() {
    dispatch(_sessionPreferences.actions.updateSessionPreferences('resetSearchResultsPageNumber', true));
    dispatch(_searchFilter.actions.resetFilters());
    dispatch(_filters.actions.resetFilters());
  };

  var getVehicleTitle = function getVehicleTitle() {
    if (translations.customSeoTitle) {
      return (0, _vehicle.translateTemplateWithVehicleData)('customSeoTitle', config.customSeoProps, vehicleDetails, translations);
    }
    return vehicleDetails.registration + ' ' + vehicleDetails.description;
  };

  var getVehicleDescription = function getVehicleDescription() {
    if (translations.customSeoDescription) {
      return (0, _vehicle.translateTemplateWithVehicleData)('customSeoDescription', config.customSeoProps, vehicleDetails, translations);
    }
    return '';
  };

  var _useMemo = (0, _react.useMemo)(function () {
    if (!vehicleDetails) {
      return [{
        exterior: '-',
        interior: '-',
        display: '-',
        registration: '-',
        location: '-'
      }, [], {
        bodystyle: '-',
        engine: '-',
        transmission: '-',
        fuelType: '-'
      }];
    }
    var _vehicleDetails$speci = vehicleDetails.specification,
        exterior = _vehicleDetails$speci.exterior,
        interior = _vehicleDetails$speci.interior,
        secondaryInterior = _vehicleDetails$speci.secondaryInterior,
        odometer = _vehicleDetails$speci.odometer,
        location = _vehicleDetails$speci.location,
        bodystyle = _vehicleDetails$speci.bodystyle,
        capacity = _vehicleDetails$speci.capacity,
        transmission = _vehicleDetails$speci.transmission,
        fuelType = _vehicleDetails$speci.fuelType;


    var generalInformation = {
      exterior: (0, _vehicle.dashIfNull)(exterior),
      interior: (0, _vehicle.dashIfNull)(interior),
      secondaryInterior: (0, _vehicle.dashIfNull)(secondaryInterior),
      display: '' + (!!odometer ? odometer.reading + '  ' + odometer.units : '-'),
      registration: (0, _vehicle.dashIfNull)(vehicleDetails.registration),
      location: (0, _vehicle.dashIfNull)(location)
    };

    var _ref6 = vehicleDetails.otherEquipment && vehicleDetails.otherEquipment.find(function (e) {
      return e.category === 'default';
    }) || {},
        equipment = _ref6.equipment;

    var otherSpecs = {
      bodystyle: (0, _vehicle.dashIfNull)(bodystyle),
      engine: String((0, _vehicle.dashIfNull)(capacity)),
      transmission: (0, _vehicle.dashIfNull)(transmission),
      fuelType: (0, _vehicle.dashIfNull)(fuelType)
    };

    return [generalInformation, equipment || [], otherSpecs];
  }, [vehicleDetails]),
      _useMemo2 = (0, _slicedToArray3.default)(_useMemo, 3),
      generalInformation = _useMemo2[0],
      otherEquipments = _useMemo2[1],
      otherSpecs = _useMemo2[2];

  var getVehicleInfo = (0, _react.useCallback)(function () {
    var match = (hashRouting ? hash : pathname).match(/(\/)([A-Za-z0-9]*)(-)/);
    if (!match) {
      return;
    }
    var vehicleId = match[2];
    var financeProductToUse = context || finance && finance.defaultProduct;
    var payloadFinance = null;
    actions.getVehicleInfo({
      vehicleId: vehicleId,
      payloadFinance: payloadFinance,
      financeProductToUse: financeProductToUse
    });
  }, [hashRouting, hash, pathname, finance]);

  (0, _react.useEffect)(function () {
    getVehicleInfo();
    dispatch(_shortlist.actions.getVehicles());
    dispatch(_lamborghiniFinanceCalc.actions.clear());

    return function () {
      var dispatch = props.dispatch,
          history = props.history;

      var searchResultsMatcher = /.*\/searchresults\/.*/i;
      var goingToSrp = searchResultsMatcher.test(history.location.pathname);
      if (!goingToSrp) {
        dispatch(_sessionPreferences.actions.updateSessionPreferences('infiniteScroll', false));
      }
    };
  }, []);
  // update vehicle details when currency changes
  (0, _react.useEffect)(function () {
    if (vehicleDetails && vehicleDetails.price.currency !== shared.currencyConversion.currency) {
      getVehicleInfo();
    }
  }, [shared.currencyConversion.currency]);

  (0, _react.useEffect)(function () {
    if (!actions.updateVehiclePrices || !state.vdpConfig || !state.similarVehicles) {
      return;
    }
    dispatch(actions.updateVehiclePrices({
      vehicle: state.vdpConfig,
      similarVehicles: state.similarVehicles
    }));
  }, [state.vdpConfig, state.similarVehicles]);

  var MobileVehicleSpecAccordion = function MobileVehicleSpecAccordion() {
    return _react2.default.createElement(
      _VdpAccordion2.default,
      {
        title: _react2.default.createElement(
          AccordionTitle,
          null,
          translations.vdpSpecificationHeader
        ),
        removeBorders: true,
        disableGutters: true
      },
      _react2.default.createElement(
        Grid,
        { style: { marginTop: 14 } },
        (0, _entries2.default)(otherSpecs).map(function (_ref7) {
          var _ref8 = (0, _slicedToArray3.default)(_ref7, 2),
              k = _ref8[0],
              v = _ref8[1];

          return _react2.default.createElement(
            GridItem,
            { key: k },
            _react2.default.createElement(
              GridItemLabel,
              null,
              translations[k]
            ),
            _react2.default.createElement(
              GridItemValue,
              null,
              v
            )
          );
        })
      )
    );
  };

  return vehicleDetails ? _react2.default.createElement(
    _react.Fragment,
    null,
    _react2.default.createElement(
      _reactHelmet.Helmet,
      null,
      _react2.default.createElement(
        'title',
        null,
        getVehicleTitle()
      ),
      translations.customSeoDescription && _react2.default.createElement('meta', { name: 'description', content: getVehicleDescription() })
    ),
    _react2.default.createElement(
      GalleryWrapper,
      null,
      _react2.default.createElement(
        _ContentWrapper2.default,
        { contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(_VdpGalleryAndMyDeal2.default, {
          imageCarousel: config.imageCarousel,
          globalStyling: globalStyling,
          featureFlags: featureFlags,
          shortListedIconColour: shortListedIconColour,
          compareIconColour: compareIconColour,
          vehicleImages: {
            slides: vehicleDetails.images.map(function (i) {
              return {
                id: i,
                image: i
              };
            })
          },
          rotateGallery: config.rotateGallery,
          placeholdingImage: placeholdingImage,
          vehicleInfo: vehicleDetails,
          translations: config.translations,
          showShareButton: config.showShareButton,
          compareVehicle: function compareVehicle(vehicle) {
            return dispatch(_compare.actions.toggleVehicle((0, _converters2.default)(vehicle)));
          },
          shortlistVehicle: function shortlistVehicle(vehicle) {
            return dispatch(_shortlist.actions.toggleVehicle((0, _converters2.default)(vehicle)));
          },
          bookTestDrive: function bookTestDrive() {
            return _bookTestDrive('TestDrive', vehicleDetails.id);
          },
          reserveThisVehicle: function reserveThisVehicle() {
            return _bookTestDrive('TestDrive', vehicleDetails.id);
          },
          makeAnEnquiry: function makeAnEnquiry() {
            return dispatch(_router.actions.navigate('/enquiry/' + vehicleDetails.id));
          },
          finance: false,
          updateMyDeal: updateMyDealProduct,
          updateMyDealWithPartExchange: updateMyDealWithPartExchange,
          saveMyDeal: function (_saveMyDeal) {
            function saveMyDeal() {
              return _saveMyDeal.apply(this, arguments);
            }

            saveMyDeal.toString = function () {
              return _saveMyDeal.toString();
            };

            return saveMyDeal;
          }(function () {
            return saveMyDeal({
              vehicleDetails: vehicleDetails,
              vdpConfig: config
            });
          }),
          iconColour: iconColour,
          getValuationMakes: function getValuationMakes() {
            return dispatch(_vehicleValuations.actions.loadVehicleMakes());
          },
          valuationMakes: [],
          getValuationModels: function getValuationModels(makeId) {
            return dispatch(_vehicleValuations.actions.loadVehicleModels(makeId));
          },
          valuationModels: [],
          getValuationVersions: function getValuationVersions(makeId, modelId) {
            return dispatch(_vehicleValuations.actions.loadVehicleVersions(makeId, modelId));
          },
          valuationVersions: [],
          getValuationYears: function getValuationYears(makeId, modelId, versionId) {
            dispatch(_vehicleValuations.actions.loadVehicleRegistrationYears(makeId, modelId, versionId));
          },
          valuationYears: [],
          getValuationMonths: function getValuationMonths(makeId, modelId, year) {
            dispatch(_vehicleValuations.actions.loadVehicleRegistrationMonths(makeId, modelId, year));
          },
          valuationMonths: [],
          getVehicleValuation: function getVehicleValuation(make, model, versionId, year, month, odometer) {
            dispatch(_vehicleValuations.actions.getVehicleValuation(make, model, versionId, year, month, odometer, vehicleDetails));
          },
          vehicleValuation: {},
          vehicleValuationRequestLimit: false,
          editVehicleValuation: function editVehicleValuation(vehicleId) {
            return dispatch(_vehicleValuations.actions.editVehicleValuation(vehicleId));
          },
          cancelEditVehicleValuation: function cancelEditVehicleValuation() {
            return dispatch(_vehicleValuations.actions.cancelEditVehicleValuation());
          },
          valuationError: false,
          showDeal: config.showDeal,
          showContactbuttons: false,
          onDemandBtn: {},
          showOnDemand: config.showOnDemand,
          makeATestDriveEnquiry: function makeATestDriveEnquiry() {
            return dispatch(_router.actions.navigate('enquiry/' + vehicleDetails.id));
          },
          subHeaderType: config.subHeaderType,
          heartMobileNoPadding: false,
          locale: marketInfo.locale,
          context: context || finance && finance.defaultProduct,
          financeTranslations: finance && finance.translations,
          financeHeadingDisplay: finance && finance.financeHeadingDisplay,
          printing: printing,
          showFinanceDescriptions: finance && finance.showProductDescriptions,
          financePricingTable: {},
          featuredSpec: {},
          hideActionButtons: false,
          approvedImage: config.pricingSectionImage,
          galleryWidth: config.galleryWidth,
          socialShare: socialShare,
          config: config,
          hideYear: false
        })
      )
    ),
    _react2.default.createElement(
      DesktopContainer,
      null,
      _react2.default.createElement(
        Section,
        { backGround: '#FFF', contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          SectionTitle,
          { color: globalStyling.colours.primaryFontColour.value },
          translations.generalInformation
        ),
        _react2.default.createElement(
          Grid,
          null,
          (0, _entries2.default)(generalInformation).map(function (_ref9) {
            var _ref10 = (0, _slicedToArray3.default)(_ref9, 2),
                k = _ref10[0],
                v = _ref10[1];

            return _react2.default.createElement(
              GridItem,
              { key: k },
              _react2.default.createElement(
                GridItemLabel,
                null,
                translations[k]
              ),
              _react2.default.createElement(
                GridItemValue,
                { alignRight: true },
                v
              )
            );
          })
        )
      ),
      otherEquipments.length > 0 && _react2.default.createElement(
        Section,
        {
          backGround: isMobile ? '#FFF' : globalStyling.colours.pageBackgroundColour.value,
          contentWidth: globalStyling.contentWidth
        },
        _react2.default.createElement(
          SectionTitle,
          { color: globalStyling.colours.primaryFontColour.value },
          translations.vdpOtherEquipmentHeader
        ),
        _react2.default.createElement(
          Grid,
          null,
          otherEquipments.map(function (feature, index) {
            return _react2.default.createElement(
              GridItem,
              { key: index },
              _react2.default.createElement(
                GridItemValue,
                null,
                feature
              )
            );
          })
        )
      )
    ),
    _react2.default.createElement(
      MobileContainer,
      null,
      _react2.default.createElement(
        Section,
        {
          backGround: globalStyling.colours.pageBackgroundColour.value,
          contentWidth: globalStyling.contentWidth,
          style: { gap: 16 }
        },
        _react2.default.createElement(
          _VdpAccordion2.default,
          {
            title: _react2.default.createElement(
              AccordionTitle,
              null,
              translations.generalInformation
            ),
            removeBorders: true,
            disableGutters: true
          },
          _react2.default.createElement(
            Grid,
            { style: { marginTop: 14 } },
            (0, _entries2.default)(generalInformation).map(function (_ref11) {
              var _ref12 = (0, _slicedToArray3.default)(_ref11, 2),
                  k = _ref12[0],
                  v = _ref12[1];

              return _react2.default.createElement(
                GridItem,
                { key: k },
                _react2.default.createElement(
                  GridItemLabel,
                  null,
                  translations[k]
                ),
                _react2.default.createElement(
                  GridItemValue,
                  null,
                  v
                )
              );
            })
          )
        ),
        otherEquipments.length > 0 && _react2.default.createElement(
          _VdpAccordion2.default,
          {
            title: _react2.default.createElement(
              AccordionTitle,
              null,
              translations.vdpOtherEquipmentHeader
            ),
            removeBorders: true,
            disableGutters: true
          },
          _react2.default.createElement(
            Grid,
            { style: { marginTop: 14 } },
            otherEquipments.map(function (feature, index) {
              return _react2.default.createElement(
                GridItem,
                { key: index },
                _react2.default.createElement(
                  GridItemValue,
                  null,
                  feature
                )
              );
            })
          )
        ),
        accessories.length === 0 && _react2.default.createElement(MobileVehicleSpecAccordion, null)
      )
    ),
    accessories.length > 0 && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_VdpAccessories2.default, {
        accessories: accessories,
        accessoriesInView: accessoriesInView,
        totalPages: accessoriesPageTotal,
        currentPage: accessoriesPage,
        mobileSelected: selectedAccessoryMobile,
        mobileCurrentPage: accessoriesPageMobile,
        mobileTotalPages: accessoriesPageTotalMobile,
        nextMobile: accessoriesNextMobile,
        previousMobile: accessoriesPrevMobile,
        config: config,
        globalStyling: globalStyling
      }),
      _react2.default.createElement(
        MobileContainer,
        null,
        _react2.default.createElement(
          Section,
          {
            backGround: globalStyling.colours.pageBackgroundColour.value,
            contentWidth: globalStyling.contentWidth
          },
          _react2.default.createElement(MobileVehicleSpecAccordion, null)
        )
      )
    ),
    _react2.default.createElement(
      DesktopContainer,
      null,
      _react2.default.createElement(
        Section,
        { backGround: '#FFF', contentWidth: globalStyling.contentWidth },
        _react2.default.createElement(
          SectionTitle,
          { color: globalStyling.colours.primaryFontColour.value },
          translations.vdpSpecificationHeader
        ),
        _react2.default.createElement(
          Grid,
          null,
          (0, _entries2.default)(otherSpecs).map(function (_ref13) {
            var _ref14 = (0, _slicedToArray3.default)(_ref13, 2),
                k = _ref14[0],
                v = _ref14[1];

            return _react2.default.createElement(
              GridItem,
              { key: k },
              _react2.default.createElement(
                GridItemLabel,
                null,
                translations[k]
              ),
              _react2.default.createElement(
                GridItemValue,
                null,
                v
              )
            );
          })
        )
      )
    ),
    (vehicleDetails.consumption.nedcConsumptionCombined || vehicleDetails.consumption.wltpConsumptionCombined || vehicleDetails.emissions.nedcEmissionsCombined || vehicleDetails.emissions.wltpEmissionsCombined) && featureFlags.emissionsEnabled && _react2.default.createElement(
      _react.Fragment,
      null,
      _react2.default.createElement(_VdpEmissions2.default, {
        translations: translations,
        vehicleInfo: vehicleDetails,
        headerFont: config.emissionsHeaderFont,
        ipCountryCode: (0, _ipInformation.getIpCountryCode)(),
        vehicleModelYear: vehicleDetails.registration
      })
    ),
    vehicleDetails.energyEfficiencyRating && _react2.default.createElement(_VehicleEfficiencyClass2.default, {
      config: config,
      energyEfficiencyRating: vehicleDetails.energyEfficiencyRating,
      energyEfficiencyRatingMedia: config.energyEfficiencyRatingMedia,
      translations: translations,
      vehicleModelYear: vehicleDetails.registration,
      vehicleName: vehicleDetails.description
    }),
    config.showAdditionalInfo && vehicleDetails.extraInformation && vehicleDetails.extraInformation.length > 0 && _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: globalStyling.contentWidth },
      _react2.default.createElement(_VdpAdditionalInfo2.default, {
        text: vehicleDetails.extraInformation,
        font: config.wellHeaderFont,
        translations: translations
      })
    ),
    _react2.default.createElement(
      _ContentWrapper2.default,
      { contentWidth: globalStyling.contentWidth },
      _react2.default.createElement(_VdpRetailerMap2.default, {
        retailer: vehicleDetails.retailerInformation,
        translations: config.translations,
        viewRetailerStock: function viewRetailerStock(retailer) {
          resetFilters();
          goToModule('VehicleSearchResults', retailer);
        },
        viewGroupStock: function viewGroupStock(retailer) {
          resetFilters();
          goToModuleWithGroup('VehicleSearchResults', retailer);
        },
        makeAnEnquiry: function makeAnEnquiry() {
          return dispatch(_router.actions.navigate('/enquiry/' + vehicleDetails.id));
        },
        globalStyling: globalStyling,
        config: config.retailerMapDetails,
        locale: marketInfo.locale,
        googleAvailable: googleAvailable,
        hideContactButton: config.hideContactButton,
        vehicleInfo: vehicleDetails,
        platformMode: props.router.platformMode
      })
    )
  ) : _react2.default.createElement(_react.Fragment, null);
};

exports.default = VDP;