'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _values = require('babel-runtime/core-js/object/values');

var _values2 = _interopRequireDefault(_values);

var _assign = require('babel-runtime/core-js/object/assign');

var _assign2 = _interopRequireDefault(_assign);

var _toConsumableArray2 = require('babel-runtime/helpers/toConsumableArray');

var _toConsumableArray3 = _interopRequireDefault(_toConsumableArray2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _keys = require('babel-runtime/core-js/object/keys');

var _keys2 = _interopRequireDefault(_keys);

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _entries = require('babel-runtime/core-js/object/entries');

var _entries2 = _interopRequireDefault(_entries);

var _extends3 = require('babel-runtime/helpers/extends');

var _extends4 = _interopRequireDefault(_extends3);

var _defineProperty2 = require('babel-runtime/helpers/defineProperty');

var _defineProperty3 = _interopRequireDefault(_defineProperty2);

var _getPrototypeOf = require('babel-runtime/core-js/object/get-prototype-of');

var _getPrototypeOf2 = _interopRequireDefault(_getPrototypeOf);

var _classCallCheck2 = require('babel-runtime/helpers/classCallCheck');

var _classCallCheck3 = _interopRequireDefault(_classCallCheck2);

var _createClass2 = require('babel-runtime/helpers/createClass');

var _createClass3 = _interopRequireDefault(_createClass2);

var _possibleConstructorReturn2 = require('babel-runtime/helpers/possibleConstructorReturn');

var _possibleConstructorReturn3 = _interopRequireDefault(_possibleConstructorReturn2);

var _inherits2 = require('babel-runtime/helpers/inherits');

var _inherits3 = _interopRequireDefault(_inherits2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n  ', ';\n    padding: 0 5%;\n  '], ['\n  ', ';\n    padding: 0 5%;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n      max-width: 49%;\n  '], ['\n      max-width: 49%;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    display: block;\n  '], ['\n    display: block;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 10px 0;\n  '], ['\n    padding: 10px 0;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n    justify-content: center;\n  '], ['\n    justify-content: center;\n  ']);
/* eslint-env browser */


var _path = require('ramda/src/path');

var _path2 = _interopRequireDefault(_path);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactGoogleRecaptcha = require('react-google-recaptcha');

var _reactGoogleRecaptcha2 = _interopRequireDefault(_reactGoogleRecaptcha);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

require('react-phone-input-2/lib/style.css');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _CollapsableMarkdown = require('../../../components/CollapsableMarkdown');

var _CollapsableMarkdown2 = _interopRequireDefault(_CollapsableMarkdown);

var _ConfirmationWindow = require('../../../components/ConfirmationWindow');

var _ConfirmationWindow2 = _interopRequireDefault(_ConfirmationWindow);

var _FormFields = require('../../../components/FormFields');

var _Global = require('../../../components/Global');

var _LabelledCheckBox = require('../../../components/LabelledCheckBox');

var _LabelledCheckBox2 = _interopRequireDefault(_LabelledCheckBox);

var _validation = require('../../../helpers/validation');

require('../../../modules/Enquiry/reducer');

var _FinanceDrawerStyles = require('../../../modules/Lamborghini/FinanceDrawer/FinanceDrawerStyles');

var _numbers = require('../../../shared/localisation/numbers');

var _translateFromTemplate = require('../../../shared/localisation/translateFromTemplate');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _helpers = require('./helpers/helpers');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Container = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Container',
  componentId: 'sc-10yni9-0'
})(['display:flex;justify-content:center;']);

var EnquiryFormContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__EnquiryFormContainer',
  componentId: 'sc-10yni9-1'
})(['', ';width:40%;', ';height:auto;margin:0 0 20px;', ';'], _theme2.default.max.large(_templateObject, function (_ref) {
  var width = _ref.width;
  return 'width: ' + (width ? width + 'px' : '90%');
}), function (_ref2) {
  var width = _ref2.width;
  return 'width: ' + (width ? width + 'px' : '40%');
}, function (_ref3) {
  var consentDisabled = _ref3.consentDisabled;
  return !consentDisabled && '\n      padding-bottom: 160px;\n    ';
});

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Row',
  componentId: 'sc-10yni9-2'
})(['display:flex;flex-direction:column;justify-content:space-between;', ';'], function (props) {
  return props.flexRow && (0, _styledComponents.css)(['flex-direction:row;']);
});

var NameRow = (0, _styledComponents2.default)(Row).withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__NameRow',
  componentId: 'sc-10yni9-3'
})(['align-items:flex-start;']);

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Column',
  componentId: 'sc-10yni9-4'
})(['display:flex;flex-direction:column;flex-basis:100%;flex:1;', ';', ';'], function (props) {
  return props.halfColumn && _theme2.default.min.medium(_templateObject2);
}, function (props) {
  return props.doubleColumn && (0, _styledComponents.css)(['flex:3;']);
});

var ActionsContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__ActionsContainer',
  componentId: 'sc-10yni9-5'
})(['', ';display:flex;width:100%;justify-content:space-between;'], _theme2.default.max.large(_templateObject3));

var ActionButtonWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__ActionButtonWrapper',
  componentId: 'sc-10yni9-6'
})(['', ';'], _theme2.default.max.large(_templateObject4));

var Error = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Error',
  componentId: 'sc-10yni9-7'
})(['width:100%;display:flex;justify-content:flex-end;color:#9e1b32;margin-top:10px;', ';'], _theme2.default.max.large(_templateObject5));

var AddressContainer = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__AddressContainer',
  componentId: 'sc-10yni9-8'
})(['position:relative;']);

var Suggestions = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Suggestions',
  componentId: 'sc-10yni9-9'
})(['position:absolute;left:0;top:74px;width:100%;z-index:100;background:#ffffff;border:1px solid #999999;box-sizing:border-box;']);

var Suggestion = (0, _styledComponents2.default)(_Global.Paragraph).withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__Suggestion',
  componentId: 'sc-10yni9-10'
})(['', ' padding:5px 10px;margin-block-start:0;margin-block-end:0;&:hover{background:#d3d3d3;}'], function (_ref4) {
  var selectable = _ref4.selectable;
  return selectable ? 'cursor: pointer;' : '';
});

var ReCAPTCHACheckbox = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__ReCAPTCHACheckbox',
  componentId: 'sc-10yni9-11'
})(['margin-top:5px;margin-bottom:50px;']);

var MobileCodeLabel = _styledComponents2.default.label.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__MobileCodeLabel',
  componentId: 'sc-10yni9-12'
})(['margin-bottom:10px;', ';'], function (_ref5) {
  var direction = _ref5.direction;
  return direction === 'rtl' ? 'text-align: right;' : 'text-align: left;';
});

var FormWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'LamborghiniThirdPartyTemplate__FormWrapper',
  componentId: 'sc-10yni9-13'
})(['', ';'], function (_ref6) {
  var consentDisabled = _ref6.consentDisabled;
  return consentDisabled && '\n      display: none;\n    ';
});

var checkIfCountryIsUs = function checkIfCountryIsUs(country) {
  return ['us', 'usa', 'united states'].includes(country.toLowerCase());
};

var EnquiryForm = function (_Component) {
  (0, _inherits3.default)(EnquiryForm, _Component);

  function EnquiryForm(props) {
    (0, _classCallCheck3.default)(this, EnquiryForm);

    var _this = (0, _possibleConstructorReturn3.default)(this, (EnquiryForm.__proto__ || (0, _getPrototypeOf2.default)(EnquiryForm)).call(this, props));

    Object.defineProperty(_this, 'componentDidMount', {
      enumerable: true,
      writable: true,
      value: function value() {
        var actions = _this.props.actions;

        actions.getLanguageOptions();
        if (_this.props.params && _this.props.params.address) _this.addressInput.current.value = _this.props.params.address;
      }
    });
    Object.defineProperty(_this, 'handleRadioChange', {
      enumerable: true,
      writable: true,
      value: function value(type) {
        return function (e) {
          var value = e.target.value;

          _this.setState((0, _defineProperty3.default)({}, type, value));
        };
      }
    });
    Object.defineProperty(_this, 'onSelectChange', {
      enumerable: true,
      writable: true,
      value: function value(_ref7) {
        var _this$setState2;

        var keyValue = _ref7.keyValue,
            _value = _ref7.value,
            label = _ref7.label;
        var errors = _this.state.errors;

        var error = _this.validate(keyValue, { value: _value, label: label });
        _this.setState((_this$setState2 = {}, (0, _defineProperty3.default)(_this$setState2, keyValue, { label: label, value: _value }), (0, _defineProperty3.default)(_this$setState2, 'errors', (0, _extends4.default)({}, errors, error)), _this$setState2));
      }
    });
    Object.defineProperty(_this, 'checkInputPattern', {
      enumerable: true,
      writable: true,
      value: function value(e, pattern) {
        if (!pattern.test(e.key)) {
          e.preventDefault();
        }
      }
    });
    Object.defineProperty(_this, 'onPhoneInputChange', {
      enumerable: true,
      writable: true,
      value: function value(_value2, countryCode) {
        var errors = _this.getErrors();

        var error = (0, _validation.validPhoneByCode)(_value2, countryCode, _this.props.config.translations);

        _this.setState({
          phone: _value2,
          errors: (0, _extends4.default)({}, errors, {
            phone: error ? [error] : undefined
          })
        });
      }
    });
    Object.defineProperty(_this, 'onInputChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value3) {
        var _this$setState3;

        var errors = _this.getErrors();

        var error = _this.validate(formKey, _value3);

        _this.setState((_this$setState3 = {}, (0, _defineProperty3.default)(_this$setState3, formKey, _value3), (0, _defineProperty3.default)(_this$setState3, 'errors', (0, _extends4.default)({}, errors, error)), _this$setState3));
      }
    });
    Object.defineProperty(_this, 'onStateChange', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value4) {
        var selectedAddress = _this.state.selectedAddress;

        _this.setState({
          state: _value4,
          selectedAddress: (0, _extends4.default)({}, selectedAddress, { state: _value4 })
        });
      }
    });
    Object.defineProperty(_this, 'onReCaptchaChange', {
      enumerable: true,
      writable: true,
      value: function value(event) {
        _this.setState({ reCaptchaValue: event });
      }
    });
    Object.defineProperty(_this, 'onSuggestionSelected', {
      enumerable: true,
      writable: true,
      value: function value(selectedAddress) {
        _this.setState({ state: selectedAddress.state, selectedAddress: selectedAddress });
        _this.addressInput.current.value = _this.shortFormatAddress(selectedAddress);
        _this.props.actions.addressSelected();

        if (selectedAddress && selectedAddress.state && (selectedAddress.state.toLowerCase() === 'california' || selectedAddress.state.toLowerCase() === 'ca')) {
          _this.setState({ californiaConsentIsVisible: true });
        } else {
          _this.setState({ californiaConsentIsVisible: false });
        }
      }
    });
    Object.defineProperty(_this, 'getErrors', {
      enumerable: true,
      writable: true,
      value: function value() {
        var errors = _this.state.errors;

        return (0, _entries2.default)(errors).filter(function (_ref8) {
          var _ref9 = (0, _slicedToArray3.default)(_ref8, 1),
              key = _ref9[0];

          return (0, _keys2.default)(_this.getValidations()).includes(key);
        }).reduce(function (accErrors, _ref10) {
          var _ref11 = (0, _slicedToArray3.default)(_ref10, 2),
              currentKey = _ref11[0],
              currentValue = _ref11[1];

          return (0, _extends4.default)({}, accErrors, (0, _defineProperty3.default)({}, currentKey, currentValue));
        }, {});
      }
    });
    Object.defineProperty(_this, 'submissionFieldFiltering', {
      enumerable: true,
      writable: true,
      value: function value(submissionObject) {
        if (_this.state.currentOwner === 'false') {
          var _customerVin = submissionObject.customerVin,
              _customerFile = submissionObject.customerFile,
              _customerFileName = submissionObject.customerFileName,
              newSubmissionObject = (0, _objectWithoutProperties3.default)(submissionObject, ['customerVin', 'customerFile', 'customerFileName']);


          return newSubmissionObject;
        }

        return submissionObject;
      }
    });
    Object.defineProperty(_this, 'onSubmitMessage', {
      enumerable: true,
      writable: true,
      value: function value() {
        var _this$props = _this.props,
            submitMessage = _this$props.submitMessage,
            vehicle = _this$props.vehicle;
        var enquiryType = _this.state.enquiryType;

        var errors = _this.getErrors();
        var newErrors = (0, _entries2.default)(_this.state).map(function (entry) {
          return _this.validate(entry[0], entry[1]);
        }).filter(function (entry) {
          return entry !== undefined;
        }).reduce(function (acc, currVal) {
          return (0, _extends4.default)({}, acc, currVal);
        }, {});

        _this.setState({ errors: _assign2.default.apply(Object, [{}].concat((0, _toConsumableArray3.default)(newErrors))) });

        if ((0, _values2.default)(newErrors).filter(function (error) {
          return error !== undefined;
        }).length > 0) {
          window.scroll(0, 0);
          _this.setState({ errors: (0, _extends4.default)({}, errors, newErrors) });
          return;
        }
        var submitObj = _this.submissionFieldFiltering((0, _extends4.default)((0, _extends4.default)({}, vehicle, _this.state, {
          enquiryType: enquiryType.value
        })));

        submitMessage((0, _extends4.default)({}, submitObj, {
          customerFinanceExample: _this.props.shared.lamborghiniFinanceCalc.payments || null
        }));
      }
    });
    Object.defineProperty(_this, 'getValidations', {
      enumerable: true,
      writable: true,
      value: function value() {
        return (0, _extends4.default)({
          firstName: _validation.required,
          lastName: _validation.required,
          email: _validation.validEmail,
          comments: _validation.required,
          language: function (_language) {
            function language(_x) {
              return _language.apply(this, arguments);
            }

            language.toString = function () {
              return _language.toString();
            };

            return language;
          }(function (language) {
            return (0, _validation.required)(language.value, _this.props.config.translations);
          }),
          selectedAddress: _validation.validSelectedAddress,
          title: _validation.required,
          currentOwner: _validation.required,
          accepted: _validation.valueIsTrue,
          contactedAccepted: _validation.valueIsTrue,
          preferred: _validation.required
        }, !_this.props.params.appPlatform && { reCaptchaValue: _validation.required }, _this.props.params.appPlatform && _this.state.isAddressInAmerica && { state: _validation.required }, _this.state.californiaConsentIsVisible && {
          californiaConsent: _validation.valueIsTrue
        }, {
          phone: _validation.required
        });
      }
    });
    Object.defineProperty(_this, 'validate', {
      enumerable: true,
      writable: true,
      value: function value(formKey, _value5) {
        var translations = _this.props.config.translations;
        var _this$state = _this.state,
            tradeInVisible = _this$state.tradeInVisible,
            isAddressInAmerica = _this$state.isAddressInAmerica;


        var excludeKeys = ['californiaConsentIsVisible'].concat((0, _toConsumableArray3.default)(!_this.state.californiaConsentIsVisible && ['californiaConsent']), (0, _toConsumableArray3.default)(_this.props.params.appPlatform ? ['reCaptchaValue'] : []), (0, _toConsumableArray3.default)(_this.state.customerVin === '' && ['validVin', 'customerVin']), (0, _toConsumableArray3.default)((!_this.props.params.appPlatform || !isAddressInAmerica) && ['state']));
        var nonValidatedKeys = ['enquiryType', 'vehicleOfInterest', 'phoneConsent', 'smsConsent', 'emailConsent', 'errors', 'tradeInVisible', 'vehicleVersion', 'customisedServicesConsent', 'marketingConsent', 'customerFileName', 'consent', 'comments', 'customerFile', 'interestedInAccessories', 'interestedInFinancialServices', 'preferredPhone' && ['phone'], 'isFromUnica', 'unicaUserId', 'isAddressInAmerica'];
        var actualValidationKeys = [].concat(nonValidatedKeys);

        if (!tradeInVisible) {
          actualValidationKeys = [].concat((0, _toConsumableArray3.default)(actualValidationKeys), (0, _toConsumableArray3.default)(excludeKeys));
        }

        if (actualValidationKeys.includes(formKey)) return undefined;

        var validations = _this.getValidations();

        if (formKey === 'country') {
          return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value5.value, translations));
        }

        return (0, _defineProperty3.default)({}, formKey, validations[formKey](_value5, translations));
      }
    });
    Object.defineProperty(_this, 'formatAddress', {
      enumerable: true,
      writable: true,
      value: function value(_ref14) {
        var number = _ref14.number,
            street = _ref14.street,
            city = _ref14.city,
            state = _ref14.state,
            country = _ref14.country;
        return '' + (number ? number + ' ' : '') + [street, city, state, country].filter(Boolean).join(',');
      }
    });
    Object.defineProperty(_this, 'shortFormatAddress', {
      enumerable: true,
      writable: true,
      value: function value(_ref15) {
        var number = _ref15.number,
            street = _ref15.street;
        return '' + (number ? number + ' ' : '') + [street].filter(Boolean).join(',');
      }
    });
    Object.defineProperty(_this, 'onAddressChange', {
      enumerable: true,
      writable: true,
      value: function value(field) {
        var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
        var addressSuggestionsRequested = _this.props.actions.addressSuggestionsRequested;


        if (data.length > 3) {
          addressSuggestionsRequested(data);
          _this.onInputChange('selectedAddress', data);
        }
      }
    });


    _this.state = (0, _extends4.default)({
      enquiryType: {
        label: 'General Enquiry',
        value: 'general_enquiry'
      },
      firstName: _this.props.params.firstName || '',
      lastName: _this.props.params.lastName || '',
      email: _this.props.params.emailAddress || '',
      phone: _this.props.params.phoneNumber || '',
      title: _this.props.params.title || 'ZY01',
      comments: '',
      marketingConsent: null,
      customisedServicesConsent: null,
      errors: {},
      tradeInVisible: false,
      reCaptchaValue: '',
      currentOwner: _this.props.params.numberOfActiveOwnerships > 0 ? 'true' : 'false',
      accepted: !!_this.props.params.appPlatform,
      contactedAccepted: !!_this.props.params.appPlatform,
      interestedInAccessories: false,
      interestedInFinancialServices: false,
      customerVin: '',
      customerFileName: '',
      selectedAddress: {},
      language: {
        label: props.config.translations.formLanguageSelect,
        value: ''
      },
      vehicleOfInterest: '',
      californiaConsentIsVisible: false,
      californiaConsent: '',
      validVin: null,
      isFromUnica: !!_this.props.params.appPlatform,
      unicaUserId: _this.props.params.accountId
    }, _this.props.params.favoriteContactMethod === 'phone' ? { preferred: 'preferredPhone' } : {}, {

      state: _this.props.params.state,
      isAddressInAmerica: _this.props.params && _this.props.params.country && checkIfCountryIsUs(_this.props.params.country)
    });

    _this.addressInput = _react2.default.createRef();
    _this.fileRef = _react2.default.createRef();
    return _this;
  }

  (0, _createClass3.default)(EnquiryForm, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _this2 = this;

      var _state = this.state,
          accepted = _state.accepted,
          contactedAccepted = _state.contactedAccepted,
          californiaConsent = _state.californiaConsent,
          errors = _state.errors,
          currentOwner = _state.currentOwner,
          language = _state.language,
          selectedAddress = _state.selectedAddress;
      var _props = this.props,
          actions = _props.actions,
          submissionStatus = _props.submissionStatus,
          vinValidity = _props.vinValidity,
          config = _props.config,
          params = _props.params,
          _props$state$language = _props.state.languageOptions,
          languageOptions = _props$state$language === undefined ? null : _props$state$language;

      if (prevState.selectedAddress !== selectedAddress) {
        this.setState(function () {
          return {
            errors: (0, _extends4.default)({}, errors, _this2.validate('selectedAddress', selectedAddress))
          };
        });
      }

      if (prevState.accepted !== accepted) {
        this.setState(function () {
          return {
            errors: (0, _extends4.default)({}, errors, _this2.validate('accepted', accepted))
          };
        });
      }

      if (prevState.selectedAddress.country !== selectedAddress.country) {
        this.setState({
          isAddressInAmerica: selectedAddress && selectedAddress.country && checkIfCountryIsUs(selectedAddress.country)
        });
      }

      if (prevState.contactedAccepted !== contactedAccepted) {
        this.setState(function () {
          return {
            errors: (0, _extends4.default)({}, errors, _this2.validate('contactedAccepted', contactedAccepted))
          };
        });
      }

      if (prevState.californiaConsent !== californiaConsent) {
        this.setState(function () {
          return {
            errors: (0, _extends4.default)({}, errors, _this2.validate('californiaConsent', californiaConsent))
          };
        });
      }

      if (languageOptions && params.language && language.value === '' && language.label === config.translations.formLanguageSelect) {
        var selectedLanguage = languageOptions.find(function (lang) {
          return lang.value.toLowerCase() === params.language.toLowerCase();
        });

        if (selectedLanguage) {
          var _error = this.validate('language', selectedLanguage);
          this.setState({
            language: selectedLanguage,
            errors: (0, _extends4.default)({}, errors, _error)
          });
        }
      }

      if (this.state.customerVin.length <= 25 && prevState.customerVin !== this.state.customerVin) {
        actions.getVinValidity(this.state.customerVin);
      }

      if (prevProps.submissionStatus !== submissionStatus && submissionStatus === 'Failed') {
        this.captcha.reset();
      }

      if (prevProps.submissionStatus !== submissionStatus && submissionStatus === 'Successful') {
        window.history.replaceState({}, '', window.location.pathname.replace('enquiry', 'enquiry-confirm'));
      }

      if (prevProps.vinValidity !== vinValidity && currentOwner) {
        this.setState({ validVin: vinValidity.vinExists === 'exists' });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this3 = this;

      var _props2 = this.props,
          _props2$config = _props2.config,
          translations = _props2$config.translations,
          formWidth = _props2$config.formWidth,
          largeHeaderFont = _props2$config.largeHeaderFont,
          recaptchaKey = _props2$config.recaptchaKey,
          radioOptions = _props2$config.radioOptions,
          personalDataComplianceStatement = _props2$config.personalDataComplianceStatement,
          optionalRequiredFields = _props2$config.optionalRequiredFields,
          labelFontSize = _props2$config.labelFontSize,
          phoneInputPreferredCountries = _props2$config.phoneInputPreferredCountries,
          vehicle = _props2.vehicle,
          history = _props2.history,
          submissionStatus = _props2.submissionStatus,
          globalStyling = _props2.globalStyling,
          params = _props2.params,
          _props2$state = _props2.state,
          suggestionRequestStatus = _props2$state.suggestionRequestStatus,
          _props2$state$address = _props2$state.addressSuggestions,
          addressSuggestions = _props2$state$address === undefined ? [] : _props2$state$address,
          _props2$state$languag = _props2$state.languageOptions,
          languageOptions = _props2$state$languag === undefined ? [] : _props2$state$languag;


      var financeData = this.props.shared.lamborghiniFinanceCalc.payments;
      var vehicleOfInterest = vehicle ? (vehicle.name && vehicle.name + ' |') + ' ' + (vehicle.specification.bodystyle && vehicle.specification.bodystyle + ' |') + ' ' + (vehicle.specification.exterior && vehicle.specification.exterior + ' |') + ' ' + (vehicle.price.value !== 0 ? '' + (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency, 2) : '') : '';

      var _state2 = this.state,
          firstName = _state2.firstName,
          lastName = _state2.lastName,
          email = _state2.email,
          comments = _state2.comments,
          selectedAddress = _state2.selectedAddress,
          errors = _state2.errors,
          reCaptchaValue = _state2.reCaptchaValue,
          language = _state2.language,
          accepted = _state2.accepted,
          contactedAccepted = _state2.contactedAccepted,
          interestedInAccessories = _state2.interestedInAccessories,
          interestedInFinancialServices = _state2.interestedInFinancialServices,
          californiaConsent = _state2.californiaConsent,
          californiaConsentIsVisible = _state2.californiaConsentIsVisible,
          phone = _state2.phone,
          isAddressInAmerica = _state2.isAddressInAmerica;


      var hasError = function hasError(field) {
        return errors[field] && errors[field][0];
      };

      var _personalDataComplian = personalDataComplianceStatement.find(function (_ref16) {
        var value = _ref16.value,
            selectorProp = _ref16.selectorProp;
        return RegExp(value).test((0, _path2.default)(selectorProp.split('.'), vehicle));
      }),
          privacyConfig = _personalDataComplian.config;

      var _optionalRequiredFiel = optionalRequiredFields.find(function (_ref17) {
        var value = _ref17.value,
            selectorProp = _ref17.selectorProp;
        return RegExp(value).test((0, _path2.default)(selectorProp.split('.'), vehicle));
      }),
          optionalFieldConfig = _optionalRequiredFiel.config;

      var fieldConfig = this.props.params.appPlatform ? (0, _extends4.default)({}, optionalFieldConfig, { state: isAddressInAmerica }) : optionalFieldConfig;

      var onConfirmationClick = void 0;
      switch (this.props.params.appPlatform && this.props.params.appPlatform.toLowerCase()) {
        case 'android':
          // eslint-disable-next-line no-undef
          onConfirmationClick = function onConfirmationClick() {
            return PreOwned.closeWebview(true);
          };
          break;
        case 'ios':
          // eslint-disable-next-line no-undef
          onConfirmationClick = function onConfirmationClick() {
            return window.webkit.messageHandlers.closeWebView.postMessage(true);
          };
          break;

        default:
          onConfirmationClick = function onConfirmationClick() {
            return history.goBack();
          };
          break;
      }

      var titleIndex = null;
      switch (this.state.title.toLowerCase()) {
        case 'zy02':
          titleIndex = 1;
          break;
        case 'zy03':
          titleIndex = 2;
          break;
        default:
          titleIndex = 0;
          break;
      }

      return submissionStatus === 'Successful' ? _react2.default.createElement(
        Container,
        null,
        _react2.default.createElement(_ConfirmationWindow2.default, {
          translations: translations,
          circleTickColour: globalStyling.colours.primaryBrandColour && globalStyling.colours.primaryBrandColour.value,
          globalStyling: globalStyling,
          onButtonClick: onConfirmationClick,
          config: this.props.config
        })
      ) : _react2.default.createElement(
        Container,
        { 'data-cy': 'lamborghini-vdp-enquiry-form-container' },
        _react2.default.createElement(
          EnquiryFormContainer,
          {
            width: formWidth,
            consentDisabled: contactedAccepted
          },
          _react2.default.createElement(
            'div',
            {
              style: {
                fontSize: '13px',
                color: '#444444',
                marginBottom: '30px',
                textAlign: 'center'
              }
            },
            translations.asteriskFormRules
          ),
          !this.props.params.appPlatform && _react2.default.createElement(
            _FormFields.FieldSet,
            null,
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                margin: 12px 0;\n                ' + (0, _Global.fontStyleOverride)(largeHeaderFont) + ';\n              ';
                }
              },
              translations.beContactedConsent
            ),
            _react2.default.createElement(_LabelledCheckBox2.default, {
              onClick: function onClick() {
                _this3.setState(function () {
                  return {
                    contactedAccepted: !contactedAccepted
                  };
                });
              },
              checked: contactedAccepted,
              label: translations.beContactedConsentCheckBox,
              onColour: '#DFB400',
              globalStyling: globalStyling,
              labelFontSize: labelFontSize && labelFontSize.fontSize,
              colour: labelFontSize.colour,
              error: hasError('contactedAccepted'),
              required: true
            })
          ),
          _react2.default.createElement(
            FormWrapper,
            {
              consentDisabled: this.props.params.appPlatform ? !this.props.params.appPlatform : !contactedAccepted
            },
            false && _react2.default.createElement(
              _FormFields.FieldSet,
              null,
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.RadioGroup, {
                  label: translations.currentOwnerLabel,
                  radioOptions: radioOptions.currentOwner,
                  handleRadioChange: this.handleRadioChange,
                  translations: translations,
                  defaultCheckedOverride: this.state.currentOwner ? 0 : 1
                })
              )
            ),
            _react2.default.createElement(
              _FormFields.FieldSet,
              null,
              _react2.default.createElement(_FormFields.RadioGroup, {
                label: translations.titleLabel,
                radioOptions: radioOptions.titles,
                handleRadioChange: this.handleRadioChange,
                translations: translations,
                defaultCheckedOverride: titleIndex
              }),
              _react2.default.createElement(
                NameRow,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.firstNameLabel,
                  value: firstName,
                  onChange: this.onInputChange,
                  keyValue: 'firstName',
                  error: hasError('firstName'),
                  doubleRow: true,
                  required: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.lastNameLabel,
                  value: lastName,
                  onChange: this.onInputChange,
                  keyValue: 'lastName',
                  error: hasError('lastName'),
                  doubleRow: true,
                  required: true
                })
              ),
              _react2.default.createElement(
                AddressContainer,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  ref: this.addressInput,
                  type: 'text',
                  label: translations.addressLabel,
                  onChange: this.onAddressChange,
                  keyValue: 'selectedAddress',
                  error: hasError('selectedAddress'),
                  autoComplete: 'none' // off doesnt work properly so needs to be a random value
                  , required: true
                }),
                addressSuggestions.length > 0 && _react2.default.createElement(
                  Suggestions,
                  null,
                  addressSuggestions.map(function (suggestion) {
                    return _react2.default.createElement(
                      Suggestion,
                      {
                        selectable: true,
                        onClick: function onClick() {
                          return _this3.onSuggestionSelected(suggestion);
                        }
                      },
                      _this3.formatAddress(suggestion)
                    );
                  })
                ),
                addressSuggestions.length === 0 && suggestionRequestStatus === 'loaded' && _react2.default.createElement(
                  Suggestions,
                  null,
                  _react2.default.createElement(
                    Suggestion,
                    { selectable: false },
                    translations.noAddressesFound
                  )
                ),
                addressSuggestions.length === 0 && suggestionRequestStatus === 'failure' && _react2.default.createElement(
                  Suggestions,
                  null,
                  _react2.default.createElement(
                    Suggestion,
                    { selectable: false },
                    translations.addressSuggestionsFailure
                  )
                )
              ),
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  keyValue: 'city',
                  label: translations.cityLabel,
                  value: selectedAddress && selectedAddress.city || '',
                  readOnly: true,
                  required: fieldConfig && fieldConfig.country,
                  error: fieldConfig && fieldConfig.country && hasError('selectedAddress')
                  // doubleRow
                })
              ),
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  label: translations.countryLabel,
                  keyValue: 'country',
                  value: selectedAddress && selectedAddress.country || '',
                  readOnly: true,
                  required: fieldConfig && fieldConfig.country,
                  error: fieldConfig && fieldConfig.country && hasError('selectedAddress'),
                  doubleRow: true
                }),
                _react2.default.createElement(_FormFields.InputField, {
                  type: 'text',
                  keyValue: 'state',
                  label: translations.countyLabel,
                  value: selectedAddress && selectedAddress.state || '',
                  readOnly: !(this.props.params.appPlatform && isAddressInAmerica),
                  onChange: this.onStateChange,
                  required: fieldConfig && fieldConfig.state,
                  error: fieldConfig && fieldConfig.state && hasError('state'),
                  doubleRow: true
                })
              ),
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(
                  _Global.HeadingThree,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                margin: 12px 0;\n                ' + (0, _Global.fontStyleOverride)(largeHeaderFont) + '\n              ';
                    }
                  },
                  translations.preferredContactMethod
                )
              ),
              _react2.default.createElement(
                Row,
                { flexRow: true },
                _react2.default.createElement(
                  Column,
                  { doubleColumn: true },
                  _react2.default.createElement(
                    Row,
                    null,
                    _react2.default.createElement(_FormFields.InputField, {
                      type: 'text',
                      label: translations.emailLabel,
                      value: email,
                      onChange: this.onInputChange,
                      keyValue: 'email',
                      error: hasError('email'),
                      required: true
                    })
                  ),
                  _react2.default.createElement(
                    Row,
                    null,
                    _react2.default.createElement(
                      MobileCodeLabel,
                      { 'for': 'phone' },
                      translations.phoneLabel,
                      _validation.required && '*'
                    ),
                    _react2.default.createElement(_FormFields.PhoneNumberInput, {
                      localization: _helpers.localisations[this.props.shared.sessionPreferences.language.substring(0, 2)] || {},
                      value: phone || '',
                      preferredCountries: phoneInputPreferredCountries,
                      onChange: function onChange(value, data) {
                        var _data$countryCode = data.countryCode,
                            countryCode = _data$countryCode === undefined ? undefined : _data$countryCode;

                        _this3.onPhoneInputChange(value, countryCode);
                      },
                      error: hasError('phone')
                    })
                  )
                ),
                _react2.default.createElement(
                  Column,
                  null,
                  _react2.default.createElement(_FormFields.RadioGroup, {
                    radioOptions: radioOptions.preferredContact,
                    handleRadioChange: this.handleRadioChange,
                    translations: translations,
                    radioVertical: true,
                    defaultCheckedOverride: params && params.favoriteContactMethod && params.favoriteContactMethod.toLowerCase() === 'phone' ? 1 : 0
                  })
                )
              ),
              _react2.default.createElement(_FormFields.SelectField, {
                type: 'text',
                label: translations.languages,
                keyValue: 'language',
                onChange: this.onSelectChange,
                options: [language].concat((0, _toConsumableArray3.default)(languageOptions)),
                error: hasError('language'),
                value: this.state.language.value || this.props.params && this.props.params.language && this.props.params.language.toUpperCase(),
                globalStyling: globalStyling,
                doubleRow: true,
                required: true,
                dataCy: 'lamborghini-equiry-form-languague-field'
              }),
              _react2.default.createElement(_FormFields.InputField, {
                type: 'text',
                label: translations.vehicleOfInterestLabel,
                value: vehicleOfInterest,
                onChange: this.onInputChange,
                keyValue: 'vehicleOfInterest',
                disabled: !!vehicle,
                truncateChars: true
              }),
              vehicle && financeData && _react2.default.createElement(
                _FinanceDrawerStyles.FinanceWrapper,
                null,
                vehicle.retailerInformation.countryCode === 'US' && _react2.default.createElement(
                  _react2.default.Fragment,
                  null,
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.effectiveRate
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      financeData.Rate,
                      ' %'
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.term
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _translateFromTemplate.translateFromTemplate)('months', {
                        NUMBER: financeData.Term
                      }, translations)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.downPayment
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.DownPayment, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.totalAmountFinanced
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.TotalAmountFinanced, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.listPrice
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.monthlyPayment
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.BaseMonthlyPayment, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  )
                ),
                vehicle.retailerInformation.countryCode === 'CA' && _react2.default.createElement(
                  _react2.default.Fragment,
                  null,
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.totalRetailPrice
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(vehicle.price.value, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.ppsa
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.PPSAFee, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.downPayment
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.DownPayment, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.totalAmountFinanced
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.TotalAmountFinanced, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.dueAtSigning
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.AmountDueAtSigning, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.term
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _translateFromTemplate.translateFromTemplate)('months', {
                        NUMBER: financeData.Term
                      }, translations)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.annualPercentageRate
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      financeData.Rate,
                      ' %'
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.totalRepaid
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.TotalAmountRepaid, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  ),
                  _react2.default.createElement(
                    _FinanceDrawerStyles.DataRow,
                    null,
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataLabel,
                      null,
                      translations.monthlyPayment
                    ),
                    _react2.default.createElement(
                      _FinanceDrawerStyles.DataValue,
                      null,
                      (0, _numbers.localiseCurrency)(financeData.BaseMonthlyPayment, vehicle.locale, vehicle.price.currency, 0.01)
                    )
                  )
                ),
                _react2.default.createElement(
                  _reactMarkdown2.default,
                  null,
                  financeData.Disclaimer
                )
              ),
              _react2.default.createElement(_FormFields.TextAreaField, {
                type: 'text',
                label: translations.commentsLabel,
                value: comments,
                onChange: this.onInputChange,
                keyValue: 'comments',
                error: hasError('comments')
              })
            ),
            _react2.default.createElement(
              _FormFields.FieldSet,
              null,
              _react2.default.createElement(_LabelledCheckBox2.default, {
                onClick: function onClick() {
                  _this3.setState(function () {
                    return {
                      interestedInAccessories: !interestedInAccessories
                    };
                  });
                },
                checked: interestedInAccessories,
                label: translations.interestedAccesories,
                onColour: '#DFB400',
                globalStyling: globalStyling,
                labelFontSize: labelFontSize && labelFontSize.fontSize,
                colour: labelFontSize.colour
              }),
              _react2.default.createElement(_LabelledCheckBox2.default, {
                onClick: function onClick() {
                  _this3.setState(function () {
                    return {
                      interestedInFinancialServices: !interestedInFinancialServices
                    };
                  });
                },
                checked: interestedInFinancialServices,
                label: translations.interestedFinancialServices,
                onColour: '#DFB400',
                globalStyling: globalStyling,
                labelFontSize: labelFontSize && labelFontSize.fontSize,
                colour: labelFontSize.colour
              })
            ),
            !this.props.params.appPlatform && _react2.default.createElement(
              _react.Fragment,
              null,
              _react2.default.createElement(
                _FormFields.FieldSet,
                null,
                _react2.default.createElement(
                  _Global.HeadingThree,
                  {
                    styleOverride: function styleOverride() {
                      return '\n                margin: 12px 0;\n                ' + (0, _Global.fontStyleOverride)(largeHeaderFont) + '\n              ';
                    }
                  },
                  translations.privacyAcknowledgementLabel
                ),
                _react2.default.createElement(_CollapsableMarkdown2.default, {
                  translations: translations,
                  config: privacyConfig
                }),
                _react2.default.createElement(_LabelledCheckBox2.default, {
                  onClick: function onClick() {
                    _this3.setState(function () {
                      return { accepted: !accepted };
                    });
                  },
                  checked: accepted,
                  label: translations.privacyAcknowledgementCheckbox,
                  onColour: '#DFB400',
                  globalStyling: globalStyling,
                  labelFontSize: labelFontSize && labelFontSize.fontSize,
                  colour: labelFontSize.colour,
                  required: true,
                  error: hasError('accepted')
                }),
                californiaConsentIsVisible && _react2.default.createElement(_LabelledCheckBox2.default, {
                  onClick: function onClick() {
                    _this3.setState(function () {
                      return {
                        californiaConsent: !californiaConsent
                      };
                    });
                  },
                  checked: californiaConsent,
                  label: translations.californiaConsent,
                  onColour: '#DFB400',
                  globalStyling: globalStyling,
                  labelFontSize: labelFontSize && labelFontSize.fontSize,
                  colour: labelFontSize.colour,
                  required: true,
                  error: hasError('californiaConsent')
                })
              ),
              _react2.default.createElement(
                _Global.HeadingThree,
                {
                  styleOverride: function styleOverride() {
                    return '\n                margin: 30px 0 12px;\n                ' + (0, _Global.fontStyleOverride)(largeHeaderFont) + '\n              ';
                  }
                },
                translations.marketingConsentLabel
              ),
              _react2.default.createElement(
                _Global.Paragraph,
                {
                  styleOverride: function styleOverride() {
                    return '\n                font-size: 14px;\n                max-width: 370px;\n              ';
                  }
                },
                translations.marketingConsentText
              ),
              _react2.default.createElement(
                Row,
                null,
                _react2.default.createElement(_FormFields.RadioGroup, {
                  radioOptions: radioOptions.marketingConsent,
                  handleRadioChange: this.handleRadioChange,
                  translations: translations
                })
              ),
              _react2.default.createElement(
                _Global.HeadingThree,
                {
                  styleOverride: function styleOverride() {
                    return '\n                margin: 32px 0 12px;\n                ' + (0, _Global.fontStyleOverride)(largeHeaderFont) + '\n              ';
                  }
                },
                translations.recaptchaTitle
              ),
              _react2.default.createElement(
                ReCAPTCHACheckbox,
                null,
                _react2.default.createElement(_reactGoogleRecaptcha2.default, {
                  ref: function ref(e) {
                    _this3.captcha = e;
                  },
                  sitekey: recaptchaKey,
                  onChange: this.onReCaptchaChange
                })
              )
            )
          ),
          _react2.default.createElement(
            ActionsContainer,
            null,
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'secondary',
                buttonStyle: globalStyling.uiElements.secondaryButton && globalStyling.uiElements.secondaryButton.buttonStyle,
                text: translations.cancelActionButton,
                onClick: history.goBack,
                tabIndex: '0'
              })
            ),
            _react2.default.createElement(
              ActionButtonWrapper,
              null,
              _react2.default.createElement(_Global.Button, {
                applyStyle: 'primary',
                buttonStyle: globalStyling.uiElements.primaryButton && globalStyling.uiElements.primaryButton.buttonStyle,
                text: translations.submitMessageActionButton,
                onClick: this.onSubmitMessage,
                disabled: this.props.params.appPlatform ? !this.props.params.appPlatform : !reCaptchaValue,
                tabIndex: '0'
              })
            )
          ),
          submissionStatus === 'Failed' && _react2.default.createElement(
            Error,
            null,
            translations.somethingWentWrong
          ),
          submissionStatus === 'RecaptchaValidationFailed' && _react2.default.createElement(
            Error,
            null,
            translations.recaptchaExpired
          )
        )
      );
    }
  }]);
  return EnquiryForm;
}(_react.Component);

exports.default = EnquiryForm;