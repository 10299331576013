'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends2 = require('babel-runtime/helpers/extends');

var _extends3 = _interopRequireDefault(_extends2);

var _objectWithoutProperties2 = require('babel-runtime/helpers/objectWithoutProperties');

var _objectWithoutProperties3 = _interopRequireDefault(_objectWithoutProperties2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 30px;\n  '], ['\n    padding: 0 30px;\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _useIsWindows = require('../../../hooks/useIsWindows');

var _useIsWindows2 = _interopRequireDefault(_useIsWindows);

var _settings = require('../../../shared/selectors/settings');

var _theme = require('../../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _globalStyling = require('../../../types/globalStyling');

var _styledComponentFactory = require('../../Global/styledComponentFactory');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/** Creating button with createElement to be able to use:
 * styleOverride?: () => string;
 * mobileStyleOverride?: () => { queryPath: string; template: string }[];
 */
var ButtonElement = (0, _styledComponentFactory.createElement)('button');

var variantStyles = function variantStyles(fonts) {
  var variant = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'primary';
  return {
    primary: (0, _styledComponents.css)(['background-color:#6d30a7;font-family:', ';'], fonts.primaryButtonFont && fonts.primaryButtonFont.value),
    secondary: (0, _styledComponents.css)(['font-family:', ';'], fonts.secondaryButtonFont && fonts.secondaryButtonFont.value),
    tertiary: (0, _styledComponents.css)(['background-color:#ffffff;color:#000000;font-family:', ';'], fonts.tertiaryButtonFont && fonts.tertiaryButtonFont.value)
  }[variant];
};

var BaseButton = (0, _styledComponents2.default)(ButtonElement).withConfig({
  displayName: 'Button__BaseButton',
  componentId: 'sc-1kmo5br-0'
})(['transition:background-color 500ms cubic-bezier(0.455,0.03,0.515,0.955);position:relative;overflow:hidden;display:inline-block;padding:0 20px;color:#ffffff;height:40px;background-color:transparent;-webkit-border-radius:99px;-moz-border-radius:99px;border-radius:99px;transition:background-color 0.4s;&:hover,&:active{background-color:#dadada;}span{transition:all 300ms cubic-bezier(0.46,0.03,0.52,0.96);transform:translateX(8px);line-height:15px;}&:hover span,&:active span{transform:translateX(0);}&:hover:after,&:active:after{transform:translateX(4px);opacity:1;}&:after{content:\'\';right:2px;opacity:0;display:inline-block;transition:all 300ms cubic-bezier(0.455,0.03,0.515,0.955);line-height:16px;width:16px;height:16px;background-size:16px 16px;background-image:', ';}', ' &:disabled{border:1px solid #999999;background-color:#cccccc;color:#666666;cursor:not-allowed;}', ';'], function (_ref) {
  var icon = _ref.icon;
  return 'url(\'' + (icon || 'https://res.cloudinary.com/motortrak/image/upload/v1637665531/locator/rolls-royce/global/icons/rr-button-arrow.svg') + '\')';
}, function (_ref2) {
  var fonts = _ref2.fonts,
      variant = _ref2.variant;
  return variantStyles(fonts, variant);
}, _theme2.default.max.small(_templateObject));

// Conditionally apply marginBottom if the user is on Windows because of font face issues
var Label = _styledComponents2.default.span.withConfig({
  displayName: 'Button__Label',
  componentId: 'sc-1kmo5br-1'
})(['font-size:12px;font-family:\'RivieraNights-Medium\';line-height:24px;letter-spacing:2px;text-transform:uppercase;', ';'], function (_ref3) {
  var isWindows = _ref3.isWindows;
  return isWindows && 'margin-bottom: -4px;';
});

var Button = function Button(props) {
  var onClick = props.onClick,
      text = props.text,
      children = props.children,
      styleOverride = props.styleOverride,
      mobileStyleOverride = props.mobileStyleOverride,
      disabled = props.disabled,
      icon = props.icon,
      rest = (0, _objectWithoutProperties3.default)(props, ['onClick', 'text', 'children', 'styleOverride', 'mobileStyleOverride', 'disabled', 'icon']);

  var isWindows = (0, _useIsWindows2.default)();

  var _useSelector = (0, _reactRedux.useSelector)(_settings.getGlobalStyling),
      fonts = _useSelector.fonts,
      colours = _useSelector.colours;

  return _react2.default.createElement(
    BaseButton,
    (0, _extends3.default)({
      onClick: function (_onClick) {
        function onClick(_x2) {
          return _onClick.apply(this, arguments);
        }

        onClick.toString = function () {
          return _onClick.toString();
        };

        return onClick;
      }(function (e) {
        return !disabled && onClick(e);
      }),
      fonts: fonts,
      colours: colours,
      disabled: disabled,
      icon: icon
    }, rest, {
      styleOverride: styleOverride
    }, (0, _styledComponentFactory.mediaProps)(mobileStyleOverride && mobileStyleOverride())),
    _react2.default.createElement(
      Label,
      { isWindows: isWindows },
      text || children
    )
  );
};

exports.default = Button;