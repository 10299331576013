'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _slicedToArray2 = require('babel-runtime/helpers/slicedToArray');

var _slicedToArray3 = _interopRequireDefault(_slicedToArray2);

var _taggedTemplateLiteral2 = require('babel-runtime/helpers/taggedTemplateLiteral');

var _taggedTemplateLiteral3 = _interopRequireDefault(_taggedTemplateLiteral2);

var _templateObject = (0, _taggedTemplateLiteral3.default)(['\n     padding: 0 112px;\n  '], ['\n     padding: 0 112px;\n  ']),
    _templateObject2 = (0, _taggedTemplateLiteral3.default)(['\n    background-size: cover;\n    background-position: center -180px;\n  '], ['\n    background-size: cover;\n    background-position: center -180px;\n  ']),
    _templateObject3 = (0, _taggedTemplateLiteral3.default)(['\n    background-position: center;\n    background-size: cover;\n  '], ['\n    background-position: center;\n    background-size: cover;\n  ']),
    _templateObject4 = (0, _taggedTemplateLiteral3.default)(['\n      height: 240px;\n      left: unset;\n      right: 18rem;\n      top: 5rem;\n      width: 240px;\n  '], ['\n      height: 240px;\n      left: unset;\n      right: 18rem;\n      top: 5rem;\n      width: 240px;\n  ']),
    _templateObject5 = (0, _taggedTemplateLiteral3.default)(['\n      right: 31rem;\n      top: 6rem;\n  '], ['\n      right: 31rem;\n      top: 6rem;\n  ']),
    _templateObject6 = (0, _taggedTemplateLiteral3.default)(['\n    margin: auto 60px;\n    transform: translateY(35px);\n    max-height: 468px;\n    max-width: 430px;\n    padding: 48px;\n  '], ['\n    margin: auto 60px;\n    transform: translateY(35px);\n    max-height: 468px;\n    max-width: 430px;\n    padding: 48px;\n  ']),
    _templateObject7 = (0, _taggedTemplateLiteral3.default)(['\n   max-width: 630px;\n'], ['\n   max-width: 630px;\n']),
    _templateObject8 = (0, _taggedTemplateLiteral3.default)(['\n      margin: 0;\n      max-width: 400px;\n    '], ['\n      margin: 0;\n      max-width: 400px;\n    ']),
    _templateObject9 = (0, _taggedTemplateLiteral3.default)(['\n    font-size: 14px;\n  '], ['\n    font-size: 14px;\n  ']),
    _templateObject10 = (0, _taggedTemplateLiteral3.default)(['\n    max-width: 100%;\n    width: 100%;\n  '], ['\n    max-width: 100%;\n    width: 100%;\n  ']),
    _templateObject11 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 16px 0 40px 0;\n  '], ['\n    margin: 16px 0 40px 0;\n  ']),
    _templateObject12 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0;\n  '], ['\n    padding: 0;\n  ']),
    _templateObject13 = (0, _taggedTemplateLiteral3.default)(['\n    flex-direction: row;\n    margin: 24px 60px 0;\n  '], ['\n    flex-direction: row;\n    margin: 24px 60px 0;\n  ']),
    _templateObject14 = (0, _taggedTemplateLiteral3.default)(['\n    gap: 100px;\n  '], ['\n    gap: 100px;\n  ']),
    _templateObject15 = (0, _taggedTemplateLiteral3.default)(['\n    ', '\n\n    ', '\n  '], ['\n    ', '\n\n    ', '\n  ']),
    _templateObject16 = (0, _taggedTemplateLiteral3.default)(['\n    padding: 0 20px 26px;\n  '], ['\n    padding: 0 20px 26px;\n  ']),
    _templateObject17 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 40px 60px 0 0;\n  '], ['\n    margin: 40px 60px 0 0;\n  ']),
    _templateObject18 = (0, _taggedTemplateLiteral3.default)(['\n     left: calc(50% - 24px);\n\n      &:hover {\n        height: 48px;\n        width: 48px;\n      }\n  '], ['\n     left: calc(50% - 24px);\n\n      &:hover {\n        height: 48px;\n        width: 48px;\n      }\n  ']),
    _templateObject19 = (0, _taggedTemplateLiteral3.default)(['\n    width: 100%;\n  '], ['\n    width: 100%;\n  ']),
    _templateObject20 = (0, _taggedTemplateLiteral3.default)(['\n    margin: 0 20px;\n    width: calc(100% - 48px);\n  '], ['\n    margin: 0 20px;\n    width: calc(100% - 48px);\n  ']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _reactMarkdown = require('react-markdown');

var _reactMarkdown2 = _interopRequireDefault(_reactMarkdown);

var _useCheckIsMobileScreen = require('../../hooks/useCheckIsMobileScreen');

var _useCheckIsMobileScreen2 = _interopRequireDefault(_useCheckIsMobileScreen);

var _theme = require('../../theme');

var _theme2 = _interopRequireDefault(_theme);

var _Button = require('./Form/Button');

var _Button2 = _interopRequireDefault(_Button);

var _VehicleDetails = require('./VehicleDetails');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TransitionWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__TransitionWrapper',
  componentId: 'sc-5xzveh-0'
})(['transition:opacity 0.3s ease-in-out,transform 0.3s ease-in-out;opacity:', ';transform:', ';'], function (_ref) {
  var inTransition = _ref.inTransition;
  return inTransition ? 0 : 1;
}, function (_ref2) {
  var inTransition = _ref2.inTransition;
  return inTransition ? 'scale(0.97)' : 'scale(1)';
});

var PromotionContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__PromotionContainer',
  componentId: 'sc-5xzveh-1'
})(['max-width:1920px;width:100%;background-color:#f9f9f9;padding:0 20px;box-sizing:border-box;margin:60px 0;', ';'], _theme2.default.min.large(_templateObject));

var PromotionInnerWrapper = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__PromotionInnerWrapper',
  componentId: 'sc-5xzveh-2'
})(['background-color:#000;']);

var CardContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__CardContainer',
  componentId: 'sc-5xzveh-3'
})(['align-items:end;background-image:url(', ');background-position:center top;background-repeat:no-repeat;background-size:contain;display:flex;flex-direction:column;height:100%;justify-content:space-between;min-height:657px;position:relative;', ';', ';'], function (_ref3) {
  var backgroundImg = _ref3.backgroundImg;
  return backgroundImg;
}, _theme2.default.min.medium(_templateObject2), _theme2.default.min.large(_templateObject3));

var BlurBox = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__BlurBox',
  componentId: 'sc-5xzveh-4'
})(['backdrop-filter:blur(40px);height:120px;left:-14px;position:absolute;top:292px;width:120px;', ';', ';'], _theme2.default.min.large(_templateObject4), _theme2.default.min.extraLarge(_templateObject5));

var TextCard = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__TextCard',
  componentId: 'sc-5xzveh-5'
})(['backdrop-filter:blur(40px);box-sizing:border-box;display:flex;flex-direction:column;margin:334px 0 0 0;max-height:100%;max-width:100%;padding:44px;width:100%;', ' ', ' p{color:#ffffff;font-family:\'Bentley-Light\';font-size:14px;font-weight:300;letter-spacing:0.18px;line-height:26px;margin:16px 0 0;max-width:100%;', '}'], _theme2.default.min.large(_templateObject6), _theme2.default.min.extraLarge(_templateObject7), _theme2.default.min.large(_templateObject8));

var Eyebrow = _styledComponents2.default.h3.withConfig({
  displayName: 'Promotion__Eyebrow',
  componentId: 'sc-5xzveh-6'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:12px;font-weight:300;letter-spacing:0.14px;margin:0;text-transform:uppercase;', ''], _theme2.default.min.large(_templateObject9));

var CardTitle = _styledComponents2.default.h2.withConfig({
  displayName: 'Promotion__CardTitle',
  componentId: 'sc-5xzveh-7'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:24px;font-weight:300;line-height:auto;margin:16px 0;text-transform:uppercase;']);

var VideoCardTitle = (0, _styledComponents2.default)(CardTitle).withConfig({
  displayName: 'Promotion__VideoCardTitle',
  componentId: 'sc-5xzveh-8'
})(['margin:28px 0 0 0;max-width:560px;', ''], _theme2.default.min.large(_templateObject10));

var CardParagraph = _styledComponents2.default.p.withConfig({
  displayName: 'Promotion__CardParagraph',
  componentId: 'sc-5xzveh-9'
})(['color:#ffffff;font-family:\'Bentley-Light\';font-size:14px;font-weight:300;letter-spacing:0.18px;line-height:26px;margin:16px 0 0 0;', ''], _theme2.default.min.large(_templateObject11));

var VideoCardParagraph = (0, _styledComponents2.default)(CardParagraph).withConfig({
  displayName: 'Promotion__VideoCardParagraph',
  componentId: 'sc-5xzveh-10'
})(['']);

var VideoContainer = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__VideoContainer',
  componentId: 'sc-5xzveh-11'
})(['background-image:url(', ');background-position:center;background-repeat:no-repeat;background-size:cover;height:100%;position:relative;width:100%;'], function (_ref4) {
  var backgroundImg = _ref4.backgroundImg;
  return backgroundImg;
});

var BackgroundOverlay = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__BackgroundOverlay',
  componentId: 'sc-5xzveh-12'
})(['backdrop-filter:blur(40px);box-sizing:border-box;display:flex;flex-direction:column;height:100%;min-height:657px;padding:20px;width:100%;justify-content:space-between;', ''], _theme2.default.min.large(_templateObject12));

var Row = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__Row',
  componentId: 'sc-5xzveh-13'
})(['display:flex;flex-direction:column;gap:40px;margin:0 0 40px;', ' ', ''], _theme2.default.min.large(_templateObject13), _theme2.default.min.xxl(_templateObject14));

var Column = _styledComponents2.default.div.withConfig({
  displayName: 'Promotion__Column',
  componentId: 'sc-5xzveh-14'
})(['align-items:flex-start;display:flex;flex-direction:column;position:relative;width:100%;', ';'], _theme2.default.min.large(_templateObject15, function (_ref5) {
  var first = _ref5.first;
  return first && 'width: 55%;';
}, function (_ref6) {
  var second = _ref6.second;
  return second && '\n      align-items: center;\n      max-width: 900px;\n      width: 45%;\n      justify-content: center;\n    ';
}));

var DisclaimerText = _styledComponents2.default.p.withConfig({
  displayName: 'Promotion__DisclaimerText',
  componentId: 'sc-5xzveh-15'
})(['align-self:center;color:#ffffff;font-family:\'Bentley-Light\';font-size:14px;font-weight:300;letter-spacing:0.18px;line-height:auto;padding:0 0 4px;text-align:center;bottom:0;', ';'], _theme2.default.min.large(_templateObject16));

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__Icon',
  componentId: 'sc-5xzveh-16'
})(['height:16px;margin-right:12px;']);

var CloseButton = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__CloseButton',
  componentId: 'sc-5xzveh-17'
})(['align-self:flex-end;cursor:pointer;height:16px;width:16px;', ''], _theme2.default.min.large(_templateObject17));

var Video = _styledComponents2.default.video.withConfig({
  displayName: 'Promotion__Video',
  componentId: 'sc-5xzveh-18'
})(['height:auto;width:100%;']);

var VideoPlayIcon = _styledComponents2.default.img.withConfig({
  displayName: 'Promotion__VideoPlayIcon',
  componentId: 'sc-5xzveh-19'
})(['cursor:pointer;height:48px;object-fit:cover;position:absolute;top:50%;transform:translateY(-50%);transition:all 0.3s;width:48px;&:hover{height:64px;width:64px;}', ''], _theme2.default.max.large(_templateObject18));

var buttonStyleOverride = function buttonStyleOverride() {
  return (0, _styledComponents.css)(['align-self:flex-start;font-size:13px;height:50px;line-height:16px;margin-top:40px;text-transform:uppercase;width:fit-content;', ''], _theme2.default.max.large(_templateObject19));
};

var videoButtonStyleOverride = function videoButtonStyleOverride() {
  return (0, _styledComponents.css)(['', ' align-self:center;margin-bottom:38px;', ''], buttonStyleOverride(), _theme2.default.max.large(_templateObject20));
};

var PromotionCard = function PromotionCard(_ref7) {
  var handleClick = _ref7.handleClick,
      backgroundImg = _ref7.backgroundImg,
      backgroundImgMobile = _ref7.backgroundImgMobile,
      translations = _ref7.translations,
      learnMoreIcon = _ref7.learnMoreIcon;

  var isMobile = (0, _useCheckIsMobileScreen2.default)('large');
  var isTablet = (0, _useCheckIsMobileScreen2.default)('medium');
  var backgroundImage = isTablet ? backgroundImgMobile : backgroundImg;
  return _react2.default.createElement(
    CardContainer,
    { backgroundImg: backgroundImage },
    _react2.default.createElement(BlurBox, null),
    _react2.default.createElement(
      TextCard,
      null,
      _react2.default.createElement(
        Eyebrow,
        null,
        translations.promoMainEyebrow
      ),
      _react2.default.createElement(
        CardTitle,
        null,
        translations.promoMainTitle
      ),
      _react2.default.createElement(
        _reactMarkdown2.default,
        null,
        translations.promoMainParagraph
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          onClick: handleClick,
          variant: 'primary',
          styleOverride: buttonStyleOverride,
          'data-ds': 'bentley-infotainment-learn-more'
        },
        _react2.default.createElement(Icon, { src: learnMoreIcon, alt: translations.promoLearnMore }),
        translations.promoLearnMore
      ),
      isMobile && _react2.default.createElement(
        DisclaimerText,
        {
          style: {
            marginBottom: '0',
            marginTop: '40px'
          }
        },
        translations.promoCardDisclaimer
      )
    ),
    !isMobile && _react2.default.createElement(
      DisclaimerText,
      null,
      translations.promoCardDisclaimer
    )
  );
};

var PromotionVideoCard = function PromotionVideoCard(_ref8) {
  var backgroundImg = _ref8.backgroundImg,
      backgroundImgMobile = _ref8.backgroundImgMobile,
      handleEnquireClick = _ref8.handleEnquireClick,
      handleCloseClick = _ref8.handleCloseClick,
      translations = _ref8.translations,
      closeIcon = _ref8.closeIcon,
      playIcon = _ref8.playIcon,
      enquireIcon = _ref8.enquireIcon,
      promoVideo = _ref8.promoVideo,
      thumbnail = _ref8.thumbnail,
      thumbnailMobile = _ref8.thumbnailMobile;

  var isMobile = (0, _useCheckIsMobileScreen2.default)();
  var backgroundImage = isMobile ? backgroundImgMobile : backgroundImg;
  var videoPreviewImage = isMobile ? thumbnailMobile : thumbnail;

  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray3.default)(_useState, 2),
      videoPlaying = _useState2[0],
      setVideoPlaying = _useState2[1];

  var videoRef = (0, _react.useRef)();

  var handlePlayVideo = function handlePlayVideo() {
    if (videoRef.current) {
      videoRef.current && videoRef.current.play();
      setVideoPlaying(true);
      videoRef.current.controls = true;
    }
  };

  var handleVideoPaused = function handleVideoPaused() {
    if (videoRef.current && !videoRef.current.seeking) {
      setVideoPlaying(false);
    }
  };

  return _react2.default.createElement(
    VideoContainer,
    { backgroundImg: backgroundImage },
    _react2.default.createElement(
      BackgroundOverlay,
      null,
      _react2.default.createElement(CloseButton, {
        src: closeIcon,
        alt: 'Close',
        'aria-label': 'Close',
        onClick: handleCloseClick
      }),
      _react2.default.createElement(
        Row,
        null,
        _react2.default.createElement(
          Column,
          { first: true },
          _react2.default.createElement(
            VideoCardTitle,
            null,
            translations.promoVideoTitle
          ),
          _react2.default.createElement(
            VideoCardParagraph,
            null,
            translations.promoVideoParagraph
          )
        ),
        _react2.default.createElement(
          Column,
          { second: true },
          _react2.default.createElement(Video, {
            ref: videoRef,
            onPause: handleVideoPaused,
            onPlay: handlePlayVideo,
            src: promoVideo,
            poster: videoPreviewImage
          }),
          !videoPlaying && _react2.default.createElement(VideoPlayIcon, {
            src: playIcon,
            alt: 'Play',
            'aria-label': 'Play',
            onClick: handlePlayVideo,
            'data-ds': 'bentley-infotainment-play-video'
          })
        )
      ),
      _react2.default.createElement(
        _Button2.default,
        {
          variant: 'primary',
          onClick: handleEnquireClick,
          styleOverride: videoButtonStyleOverride,
          'data-ds': 'bentley-infotainment-enquire'
        },
        _react2.default.createElement(Icon, { src: enquireIcon, alt: 'Enquire' }),
        translations.promoVideoButton
      ),
      _react2.default.createElement(
        DisclaimerText,
        null,
        translations.promoCardDisclaimer
      )
    )
  );
};

var Promotion = function Promotion(_ref9) {
  var config = _ref9.config,
      enquire = _ref9.enquire;
  var promotion = config.promotion,
      translations = config.translations;

  var _useState3 = (0, _react.useState)(false),
      _useState4 = (0, _slicedToArray3.default)(_useState3, 2),
      learnMore = _useState4[0],
      setLearnMore = _useState4[1];

  var _useState5 = (0, _react.useState)(false),
      _useState6 = (0, _slicedToArray3.default)(_useState5, 2),
      inTransition = _useState6[0],
      setInTransition = _useState6[1];

  var handleLearnMoreClick = function handleLearnMoreClick() {
    setInTransition(true);
    setTimeout(function () {
      setLearnMore(true);
      setInTransition(false);
    }, 300);
  };

  var handleCloseClick = function handleCloseClick() {
    setInTransition(true);
    setTimeout(function () {
      setLearnMore(false);
      setInTransition(false);
    }, 300);
  };

  return promotion ? _react2.default.createElement(
    PromotionContainer,
    { 'data-ds': 'bentley-infotainment' },
    _react2.default.createElement(
      PromotionInnerWrapper,
      null,
      _react2.default.createElement(
        TransitionWrapper,
        { inTransition: inTransition },
        learnMore ? _react2.default.createElement(PromotionVideoCard, {
          backgroundImg: promotion.videoBackgroundImage,
          backgroundImgMobile: promotion.videoCardBackgroundMobile,
          handleEnquireClick: function handleEnquireClick(e) {
            return enquire(e, ['infotainmentInterest']);
          },
          handleCloseClick: handleCloseClick,
          translations: translations,
          learnMoreIcon: promotion.learnMoreIcon,
          closeIcon: promotion.closeIcon,
          playIcon: promotion.playIcon,
          enquireIcon: promotion.enquireIcon,
          promoVideo: promotion.promoVideo,
          thumbnail: promotion.thumbnail,
          thumbnailMobile: promotion.thumbnailMobile
        }) : _react2.default.createElement(PromotionCard, {
          handleClick: handleLearnMoreClick,
          translations: translations,
          backgroundImg: promotion.mainCardBackground,
          backgroundImgMobile: promotion.mainCardBackgroundMobile,
          learnMoreIcon: promotion.learnMoreIcon
        })
      )
    )
  ) : _react2.default.createElement(_react.Fragment, null);
};

exports.default = Promotion;