'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IconRow = undefined;

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Wrapper = _styledComponents2.default.ul.withConfig({
  displayName: 'IconRow__Wrapper',
  componentId: 'sc-1w3d3rr-0'
})(['align-items:center;display:flex;gap:24px;justify-content:center;list-style-type:none;margin:0;padding-left:0;padding-right:0;']);

var Icon = _styledComponents2.default.img.withConfig({
  displayName: 'IconRow__Icon',
  componentId: 'sc-1w3d3rr-1'
})(['width:', ';height:', ';'], function (_ref) {
  var iconWidth = _ref.iconWidth;
  return iconWidth ? iconWidth + 'px' : '26px';
}, function (_ref2) {
  var iconHeight = _ref2.iconHeight;
  return iconHeight ? iconHeight + 'px' : '26px';
});

var IconRow = exports.IconRow = function IconRow(_ref3) {
  var icons = _ref3.icons;

  return _react2.default.createElement(
    Wrapper,
    null,
    icons.map(function (_ref4, key) {
      var ariaLabel = _ref4.ariaLabel,
          href = _ref4.href,
          src = _ref4.src,
          width = _ref4.width,
          height = _ref4.height,
          testId = _ref4.testId;
      return _react2.default.createElement(
        'li',
        { key: ariaLabel + '-' + key },
        _react2.default.createElement(
          'a',
          {
            'aria-label': ariaLabel,
            href: href,
            target: '_blank',
            'data-cy': testId
          },
          _react2.default.createElement(Icon, {
            src: src,
            alt: ariaLabel,
            iconHeight: height,
            iconWidth: width
          })
        )
      );
    })
  );
};