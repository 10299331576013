'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var TableRow = _styledComponents2.default.tr.withConfig({
  displayName: 'TableRow',
  componentId: 'sc-1x7waoz-0'
})(['font-size:', ';&:last-child{td{border-bottom:', ';}}'], function (_ref) {
  var fontSize = _ref.fontSize;
  return fontSize ? fontSize + 'px' : '20px';
}, function (_ref2) {
  var hideBottomBorder = _ref2.hideBottomBorder;
  return hideBottomBorder ? 'none' : '0.5px solid #151515';
});

exports.default = TableRow;