'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _Global = require('../../../components/Global');

var _ComparedVehicleType = require('./../../../types/ComparedVehicleType');

var _Shared = require('./Shared');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ShortlistRow = function ShortlistRow(_ref) {
  var comparedVehicles = _ref.comparedVehicles,
      shortlistVehicle = _ref.shortlistVehicle,
      isShortListed = _ref.isShortListed,
      translations = _ref.translations,
      font = _ref.font,
      optionalIconColourFont = _ref.optionalIconColourFont,
      optionalHeadingThreeFont = _ref.optionalHeadingThreeFont;
  return _react2.default.createElement(
    _Shared.TableRow,
    { hideBottomBorder: true, fontSize: font && font.fontSize },
    _react2.default.createElement('td', { style: { border: 'none' } }),
    comparedVehicles.map(function (vehicle) {
      return _react2.default.createElement(
        _Shared.TableData,
        { key: '' + vehicle.id, hideBottomBorder: true },
        _react2.default.createElement(
          'div',
          {
            style: {
              display: 'flex',
              justifyContent: 'center',
              height: '40px',
              margin: '4px 0'
            }
          },
          _react2.default.createElement(
            _Shared.IconButton,
            { onClick: function onClick() {
                return shortlistVehicle(vehicle);
              } },
            _react2.default.createElement('img', {
              src: isShortListed(vehicle.id) ? 'https://res.cloudinary.com/motortrak/v1708098508/locator/rolls-royce/global/icons/save-active.svg' : 'https://res.cloudinary.com/motortrak/v1708097986/locator/rolls-royce/global/icons/save-inactive.svg',
              alt: 'add to shortlist icon',
              style: {
                width: '23px',
                height: '23px',
                marginRight: '10px'
              }
            }),
            _react2.default.createElement(
              _Global.HeadingThree,
              {
                styleOverride: function styleOverride() {
                  return '\n                ' + (0, _Global.fontStyleOverride)(optionalHeadingThreeFont) + '\n                padding-left: 0;\n                font-size: ' + (optionalHeadingThreeFont ? optionalHeadingThreeFont.fontSize + 'px;' : '16px') + ';\n                color: #151515;\n                letter-spacing: 0.5px;\n                font-weight: 300;\n                font-family: RivieraNights-Light, Jost-Light;\n                line-height: 20px;\n              ';
                }
              },
              isShortListed(vehicle.id) ? translations.compareVehiclesSavedText : translations.compareVehiclesSaveText
            )
          )
        )
      );
    })
  );
};

exports.default = ShortlistRow;